/** @jsx h */

import {h, Fragment} from "preact";
import {useState, useEffect} from "preact/hooks";
import {
    noop,
    getLocaleMonthName,
    getLocaleDay,
    getLocaleWeekday,
    getLocaleYear,
    addDays,
    getLocaleNextMonthName, getLocaleMonth, getLocalePrevMonthName, removeDays
} from "../../utils/utils.js";
import 'whatwg-fetch';
import 'url-search-params-polyfill'; // Edge Polyfill
import ReactDatepicker from "react-datepicker";
import {dateToISOString, ISOStringToDate, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import {translate} from '@elements/translations';
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';
import {useDateConfigurations} from "../../utils/activity-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import {find, on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
registerLocale('de', de);
registerLocale('fr', fr);
setDefaultLocale('en');


export default function ActivityTicketDatepickerSelection({
  id = "",
  selectedDateId = "",
  selectedDateString = "",
  onChangeDate = noop()
}) {
    const isMobile = matchMedia('(max-width: 767px)').matches;

    const DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS = 62;
    let [preloadingStartDate, setPreloadingStartDate] = useState("");
    let [preloadingEndDate, setPreloadingEndDate] = useState("");
    let [includedDates, setIncludedDates] = useState([]);
    let [allAvailableDates, setAllAvailableDates] = useState({});

    let params = {
        activity_id: id,
        ...(preloadingStartDate && preloadingEndDate && { startDate: preloadingStartDate}),
        ...(preloadingEndDate && preloadingStartDate && { endDate: preloadingEndDate})
    }

    let {dateConfigurations, isLoading, error} = useDateConfigurations(params);

    function prefillDefaultIncludedDates() {
        let tempIncludedDates = [...includedDates] || [];
        Object.keys(dateConfigurations.availabilityByDate).map(function(availableDate) {
            tempIncludedDates.push(UTCDateToLocalDate(new Date(dateConfigurations.availabilityByDate[availableDate].date)));
        })

        setIncludedDates(tempIncludedDates);
    }

    const getSelectedDate = () => {
        return selectedDateString ? UTCDateToLocalDate(new Date(selectedDateString)) : null;
    }

    //preload dates if date is already selected
    useEffect(function() {
        if(selectedDateString && !preloadingStartDate && !preloadingEndDate && includedDates.length === 0) {
            let newStartDate = removeDays(new Date(), new Date().getDay() -1);
            let newEndDate = addDays( new Date(selectedDateString), DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS);

            setPreloadingStartDate(dateToISOString(localDateToUTCDate(newStartDate), false));
            setPreloadingEndDate(dateToISOString(localDateToUTCDate(newEndDate), false));
        }
    }, [selectedDateString])

    useEffect(function() {
        if(dateConfigurations) {
            prefillDefaultIncludedDates();
            setAllAvailableDates({...allAvailableDates, ...dateConfigurations.availabilityByDate});
        }
    }, [dateConfigurations])

    function loadNextDates() {
        let newStartDate = addDays(includedDates[includedDates.length - 1], 1);
        let newEndDate = addDays(newStartDate, DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS);

        setPreloadingStartDate(dateToISOString(localDateToUTCDate(newStartDate), false));
        setPreloadingEndDate(dateToISOString(localDateToUTCDate(newEndDate), false));
    }


    //set arrow disabled if < current Month
    const setDisabled = (monthDate) => {
        let month = monthDate.toLocaleString(_config.localeDate, {
            month: "short",
            year: "numeric",
        })
        let currentMonth = new Date().toLocaleString(_config.localeDate, {
            month: "short",
            year: "numeric",
        })

        return month === currentMonth;
    }

    const renderDayContents = (day, date) => {
        let tooltipInfo = false;

        if(dateConfigurations) {
            Object.keys(allAvailableDates).map(function(availableDate) {
                if (allAvailableDates[availableDate].date === dateToISOString(localDateToUTCDate(date),false) && allAvailableDates[availableDate].available !== 0) {
                    tooltipInfo = <Fragment><span className={`me-1 status status--${allAvailableDates[availableDate].status}`}/> {allAvailableDates[availableDate].available}/{allAvailableDates[availableDate].max} {translate('activity-ticket.available')} </Fragment>;
                }
            });
        }

        return (
            <Fragment>
                {tooltipInfo ? (
                    <OverlayTrigger
                        trigger={isMobile ? 'click' : ['hover', 'focus']}
                        key={"datepicker-" + date.getTime()}
                        rootClose={true}
                        description={"datepicker-" + date.getTime()}
                        overlay={
                            <Tooltip id={`tooltip-discount-${"datepicker-" + date.getTime()}`}>
                                {tooltipInfo}
                            </Tooltip>
                        }>
                        <Button className="btn-no-styling react-datepicker__btn-tooltip btn-tooltip">{day}</Button>
                    </OverlayTrigger>
                ) : (
                    <Fragment>
                        {day}
                    </Fragment>
                )}
            </Fragment>
        );
    };

    const getDateId = (date) => {
        let newDate = dateToISOString(localDateToUTCDate(date), false);
        let id;

        if(dateConfigurations) {
            let availableDates = dateConfigurations.availabilityByDate;
            Object.keys(availableDates).map(function(availableDate) {
                if (availableDates[availableDate].date === newDate) {
                    id = availableDate;
                }
            });
        }

        return id;
    }

    return (
        <Fragment>
            { isLoading ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('activity-ticket.Loading-Error')}
                </div>
            ) : ""}

            { dateConfigurations ? (
            <div className="microanimations--fade microanimations--first-fold" style="--microanimations-stagger-factor: 1">
                { selectedDateString ? (
                    <Fragment>
                        {translate('activity-ticket.Date-picked')}
                        <div className="datepicker-selection row gx-gutter--10 microanimations--change-blur" key={selectedDateString && getLocaleDay(UTCDateToLocalDate(new Date(selectedDateString)))}>
                            <div className="col-auto datepicker-selection__day">
                                {getLocaleDay(UTCDateToLocalDate(new Date(selectedDateString)))}
                            </div>
                            <div className="col">
                        <span className="datepicker-selection__top">
                            {getLocaleMonthName(UTCDateToLocalDate(new Date(selectedDateString)))}
                            <span className="ms-1">
                                {getLocaleYear(UTCDateToLocalDate(new Date(selectedDateString)))}
                            </span>
                        </span>
                                <span className="datepicker-selection__bottom">
                            {getLocaleWeekday(UTCDateToLocalDate(new Date(selectedDateString)))}
                        </span>
                            </div>
                        </div>
                    </Fragment>
                ) : "" }
                <ReactDatepicker
                    locale={_config.localeDate}
                    inline
                    selected={getSelectedDate()}
                    onChange={(date) => {
                        onChangeDate(getDateId(date),dateToISOString(localDateToUTCDate(date), false));
                    }}
                    renderCustomHeader={({
                             monthDate,
                             customHeaderCount,
                             decreaseMonth,
                             increaseMonth,
                         }) => (
                        <div className={customHeaderCount === 0 ? 'text-start' : 'text-end'}>
                            { customHeaderCount === 0 ? (
                                <button className="btn-no-styling react-datepicker__custom-header-arrow"
                                        disabled={setDisabled(monthDate)}
                                        onClick={() => {
                                    decreaseMonth();
                                    if(!isMobile) {
                                        decreaseMonth();
                                    }
                                }} >
                                    <span className="icon icon-arrow-dropdown icon-rotate-90"/>
                                </button>
                            ) : ""}

                            {monthDate.toLocaleString(_config.localeDate, {
                                month: "long",
                                year: "numeric",
                            })}

                            { customHeaderCount === 1 || isMobile ? (
                                <button className="btn-no-styling react-datepicker__custom-header-arrow" onClick={() => {
                                    loadNextDates();
                                    increaseMonth();
                                    if(!isMobile) {
                                        increaseMonth();
                                    }
                                }} >
                                    <span className="icon icon-arrow-dropdown icon-rotate-270"/>
                                </button>
                            ) : "" }
                        </div>
                    )}
                    openToDate={!selectedDateString ? includedDates[0] : null}
                    shouldCloseOnSelect={false}
                    includeDates={includedDates}
                    monthsShown={isMobile ? 1 : 2}
                    className="configuration-datepicker"
                    renderDayContents={renderDayContents}
                />
            </div>
            ) : "" }
        </Fragment>
    )
}


