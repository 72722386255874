import {trigger, on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {forceRecalculation} from './affix';

export function init() {
    onFind('.js-telemixte-accordion-area__affix', (affixContainer) => {
        on('transitionstart', () => {
            forceRecalculation()

            // trigger the window.resize event
            trigger('resize', window);
        }, affixContainer);

        on('transitionend', () => {
            forceRecalculation()

            // trigger the window.resize event
            trigger('resize', window);
        }, affixContainer);
    });
}