/** @jsx h */

import {h} from "preact";

export default function SkiTicketHeader({
    title = "",
    toptitle = "",
    currentStep = 0,
    firstViewInfo = {},
    isFirstView = false,
    subtitle = "",
    isInEdit = false
}) {
    return (
        <div className="ticket-configuration__title-block microanimations--fade microanimations--first-fold" key={'header-wrapper-' + currentStep}>
            <div className="h5 ticket-configuration__toptitle ticket-configuration__toptitle">

                {title}
            </div>
            <div className="h2 ticket-configuration__title">
                <span className="me-1">
                    {currentStep + 1}.
                </span> {toptitle}

                {isInEdit ? (
                    <span className="badge bg-primary ticket-configuration__edit-badge">
                        In Edit
                    </span>
                ) : ""}
            </div>

            {subtitle ? (
                <div className="h3 ticket-configuration__subtitle">
                    {subtitle}
                </div>
            ) : ""}

            { isFirstView && Object.keys(firstViewInfo).length > 0 ? (
                <div
                    className="attention-card attention-card--small ab-mobile-configurator__card mt-4 mb-3 microanimations--fade microanimations--first-fold" style="--microanimations-stagger-factor: 3;">


                    <svg className="checkmark checkmark__red circle-icon circle-icon--medium-large attention-card__circle-icon"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 52 52"
                         style={`--microanimations-stagger-factor: 3;`}>

                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>

                    <div className="attention-card__content">
                        <h3 className="attention-card__title">
                            {firstViewInfo.title}
                        </h3>
                        <div className="attention-card__text">
                            {firstViewInfo.text}
                        </div>
                    </div>
                </div>
            ) : "" }
        </div>
    )
}