/** @jsx h */

import {h, Fragment} from "preact";
import {noop, throwAlertMessage, getPriceGroupTitle, formatPrice} from "../utils/utils.js";
import {translate} from '@elements/translations';
import Radio from "./radio";
import Checkbox from "./checkbox";
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';
import {assert} from 'superstruct';
import {useState, useEffect} from "preact/hooks";

export default function ServiceList({
    data = {},
    ticketCounter = {},
    selectedOptions = {},
    onChangeOptions = noop(),
    priceGroups = [],
    ServiceItemType = {},
    ServicePriceConfigurationType = {},
    ServiceOptionType = {},
    hideTotal= false,
    showApplyServiceForAllTickets = false,
    onChangeShowApplyServiceForAllTickets = noop()
}) {
    

    function getServicesForPriceGroup(priceGroup) {
        if(!data['servicesByPriceGroup'][priceGroup] || data['servicesByPriceGroup'][priceGroup] === undefined) {
            let errorText = `<h4>Error</h4><p><strong>getServicesForPriceGroup()</strong>: There is no matching priceGroup key <strong>${priceGroup}</strong> in received data <strong>servicesByPriceGroup</strong>`;
            throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
        }
        return data['servicesByPriceGroup'][priceGroup] || [];
    }

    function getOption(item) {
        let option;
        data.options.map(function(optionItem) {
            if(optionItem.id === item) {
                option = optionItem;
            }
        })
        return option;
    }

    function getServiceName(id, priceGroup, index) {
        return `${id}['${priceGroup}'][${index}]`;
    }

    function getPriceConfiguration(priceGroup, priceGroupIndex) {
        let configurations = data.priceConfigurationsByPriceGroup[priceGroup];
        let optionsArray = getSelectedOptionsForPriceGroup(priceGroup, priceGroupIndex);
        let configuration;

        configurations.map(function(item) {
            if(validateItem(item, ServicePriceConfigurationType)) {
                if(item.options.every((e) => optionsArray.includes(e)) && optionsArray.every((e) => item.options.includes(e))) {
                    configuration = item;

                }
            }
        })

        if(!configuration){
            let errorText = `<h4>error getPriceConfiguration()</h4><p><strong>service-list - getPriceConfiguration()</strong>:</p><p>no matching configuration found for priceGroup <strong>${priceGroup}</strong> with selectedOptions <strong>${JSON.stringify(optionsArray)}</strong></p>`;
            throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
        }

        return configuration;
    }

    function getPriceDifference(service, option, isRadio, priceGroup, index) {
        let priceDifference = 0;
        let currentPrice = getPriceConfiguration(priceGroup, index).price;

        let tempPriceArray = getSelectedOptionsForPriceGroup(priceGroup, index);

        if(isRadio) {
            tempPriceArray = tempPriceArray.filter(x => !service.options.includes(x));
            if (!tempPriceArray.includes(option)) {
                tempPriceArray.push(option);
            }
        } else {
            if (tempPriceArray.includes(option)) {
                tempPriceArray = tempPriceArray.filter(item => item !== option);
            } else {
                tempPriceArray.push(option);
            }
            tempPriceArray = [...new Set(tempPriceArray)];
        }


        let configurations = data.priceConfigurationsByPriceGroup[priceGroup];
        let configuration;

        configurations.map(function(item) {
            if(validateItem(item, ServicePriceConfigurationType)) {
                if(item.options.every((e) => tempPriceArray.includes(e)) && tempPriceArray.every((e) => item.options.includes(e))) {
                    configuration = item;
                }
            }
        })

        if(!configuration){
            let errorText = `<h4>error getPriceDifference()</h4><p><strong>service-list - getPriceDifference()</strong>:</p><p>no matching configuration found for priceGroup <strong>${priceGroup}</strong> with selectedOptions <strong>${JSON.stringify(tempPriceArray)}</strong></p>`;
            throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
        } else {
            priceDifference = configuration.price - currentPrice;
            priceDifference = priceDifference >= 0 ? '+' + formatPrice(priceDifference, _config.lang, 'currency', 'CHF', 'code') : formatPrice(priceDifference, _config.lang, 'currency', 'CHF', 'code');
        }

        return priceDifference;
    }
    
    function getSelectedOptionsForPriceGroup(priceGroup, index) {
        let tempSelectedArray = [];
        if(Object.keys(selectedOptions).length > 0 && selectedOptions[priceGroup + "-" + index] !== undefined) {
            tempSelectedArray = [...selectedOptions[priceGroup + "-" + index]];
        }
        
        return tempSelectedArray;
    }

    function getTotalPrice() {
        let total = 0;

        Object.keys(ticketCounter).map(function(priceGroup,key) {
            [...Array(ticketCounter[priceGroup])].map(function(x, i) {
                total += +getPriceConfiguration(priceGroup, i).price;
            });
        })
        return total;
    }

    function getTotalBasePrice() {
        let total = 0;

        Object.keys(ticketCounter).map(function(priceGroup,key) {
            [...Array(ticketCounter[priceGroup])].map(function(x, i) {
                if(getPriceConfiguration(priceGroup, i).basePrice) {
                    total += +getPriceConfiguration(priceGroup, i).basePrice;
                } else {
                    total += +getPriceConfiguration(priceGroup, i).price;
                }
            });
        })


        return total;
    }

    function validateItem(service, validator) {
        if (service) {
            try {
                //validating data
                assert(service, validator);
                return true;
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>service-list - validateItem() - ${validator.toString()}</strong>:</p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }

    function getSelectedOptionCounter(option) {
        let selectedOptionCounter = 0;

        Object.keys(selectedOptions).map(function(priceGroup,key) {
            let tempArray = [...selectedOptions[priceGroup]];
            if(tempArray.includes(option)) {
                selectedOptionCounter++;
            }
        });

        return selectedOptionCounter;
    }

    function getAvailabilityOfOption(option) {
        let selectedOptionCounter = getSelectedOptionCounter(option);
        let maxAvailability = getOption(option).available;

        return maxAvailability - selectedOptionCounter;
    }

    function getAvailableError(option, priceGroup, i) {
        if(getOption(option).available && (getOption(option).available <= 0 || (getAvailabilityOfOption(option) < 0))) {
            return (
                <span className="text-primary">
                    {translate('activity-ticket.only')} {getOption(option).available} {translate('activity-ticket.available')}
                </span>
            )
        }
    }
    
    function onApplyServicesForAllOptions(options) {
        //only for radios!
        let tempObj = {};
        let optionToApply = options[0];

        if (selectedOptions && selectedOptions.constructor === Object) {

            //set first option by default
            Object.keys(ticketCounter).map(function (priceGroup, key) {
                [...Array(ticketCounter[priceGroup])].map(function (x, i) {
                    data['servicesByPriceGroup'][priceGroup].map(function (service) {
                        service.options.map(function (option, optionIndex) {
                            if (option === optionToApply) {
                                let tempOption = getOption(option);
                                if (tempOption && (tempOption.available && tempOption.available > 0 && getAvailabilityOfOption(option, tempObj) > 0) || !tempOption.available) {
                                    let tempArray = tempObj[priceGroup + "-" + i] || [];
                                    tempArray.push(option);
                                    tempObj = {...tempObj, [priceGroup + "-" + i]: tempArray};
                                }
                            }
                        });
                    });
                });
            });

            onChangeOptions({...selectedOptions, ...tempObj});
        }
    }

    return (
        <div className="d-flex flex-column">
            <div className="gy-gutter--grid">
            {Object.keys(ticketCounter).map((priceGroup,key) => (
                <Fragment>
                    {[...Array(ticketCounter[priceGroup])].map((x, i) =>
                        <div className="gy-gutter__item microanimations--fade microanimations--first-fold" style={`--microanimations-stagger-factor: ${3}`} key={priceGroup + "-" + i}>
                            <div className="ticket-configuration__headline mb-2">
                                {getPriceGroupTitle(priceGroups, priceGroup)} {i+1}
                            </div>
                            <div className="ticket-configuration__box " role="table">
                                <div className="row" role="row">
                                    <div className="col-md-3 strong fz-16" role="cell">
                                        {translate('ticket.configure-ticket')}
                                    </div>
                                    <div className="col-md  gy-gutter--10" role="cell">
                                        {getServicesForPriceGroup(priceGroup).map((service, serviceIndex) => (
                                            <Fragment>
                                                {validateItem(service, ServiceItemType) ? (
                                                    <Fragment>
                                                        {service.type === 'radio' ? (
                                                            <fieldset className="form-group gy-gutter__item" key={service.id}>
                                                                <legend className="legend--no-styling">
                                                                    {translate('ticket.choose')} {service.label}
                                                                </legend>
                                                                {service.options.map((option, optionIndex) => (
                                                                    <Radio
                                                                        key={optionIndex}
                                                                        onChangeValue={(value) => {
                                                                            let tempArray = getSelectedOptionsForPriceGroup(priceGroup, i);
                                                                            tempArray = tempArray.filter(x => !service.options.includes(x));
                                                                            tempArray.push(value);
                                                                            onChangeOptions({...selectedOptions, [priceGroup + "-" + i]: tempArray});
                                                                        }}
                                                                        checked={getSelectedOptionsForPriceGroup(priceGroup, i).includes(option)}
                                                                        value={option}
                                                                        name={getServiceName(service.id, priceGroup, i)}
                                                                        id={getServiceName(service.id, priceGroup, i)}
                                                                        info={getOption(option).info}
                                                                        label={getOption(option).label}
                                                                        disabled={false}
                                                                        availableError={getAvailableError(option, priceGroup, i)}
                                                                        available={getOption(option).available}
                                                                        status={getOption(option).status}
                                                                        labelAffix={" (" + getPriceDifference(service, option, true, priceGroup, i) + ")"}
                                                                    />
                                                                ))}
                                                                {service.description ? (
                                                                    <div className="mb-3 mt-1 ticket-configuration__alert--grey">
                                                                        {service.description}
                                                                    </div>
                                                                ) : null }
                                                            </fieldset>
                                                        ) : null}
                                                    </Fragment>
                                                ) : "" }
                                            </Fragment>
                                        ))}
                                        { key === 0 && i === 0 && Object.keys(ticketCounter).length > 1 && showApplyServiceForAllTickets ? (
                                            <div className="gy-gutter--10 fz-16 mt-4">
                                                <div className="strong gy-gutter__item">
                                                    {translate('ticket.apply-service-for-all-tickets')}
                                                </div>
                                                <div className="gy-gutter__item">
                                                    {translate('ticket.apply-service-for-all-tickets-description')}
                                                </div>
                                                <div className="row gy-gutter__item gy-gutter--10">
                                                    <div className="col-md-6">
                                                        <button className="btn btn-outline-dark w-100"
                                                                onClick={() => {
                                                                    onChangeShowApplyServiceForAllTickets(false)
                                                                }}>
                                                            {translate('ticket.apply-service-for-all-tickets-decline')}
                                                        </button>
                                                    </div>
                                                    <div className="col-md-6"
                                                         onClick={() => {
                                                             onApplyServicesForAllOptions(getSelectedOptionsForPriceGroup(priceGroup, i));
                                                             onChangeShowApplyServiceForAllTickets(false);
                                                         }}>
                                                        <button className="btn btn-dark w-100">
                                                            {translate('ticket.apply-service-for-all-tickets-accept')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ""}
                                    </div>
                                    <div className="col-md-auto" role="cell">
                                        {getServicesForPriceGroup(priceGroup).map((service) => (
                                            <Fragment>
                                                {service.type === 'checkbox' && service.options.length > 0 ? (
                                                    <fieldset className="form-group" key={service.id}>
                                                        <legend className="legend--no-styling">
                                                            {service.label}
                                                        </legend>
                                                        {service.options.map((option, optionIndex) => (
                                                            <Checkbox
                                                                key={optionIndex}
                                                                onChangeValue={(value) => {
                                                                    let tempArray = [...getSelectedOptionsForPriceGroup(priceGroup, i)];
                                                                    if(tempArray.length > 0 && tempArray.includes(value)) {
                                                                        tempArray = tempArray.filter(item => item !== value);
                                                                    } else {
                                                                        tempArray.push(value);
                                                                    }
                                                                    tempArray = [...new Set(tempArray)];
                                                                    onChangeOptions({...selectedOptions, [priceGroup + "-" + i]: tempArray});
                                                                }}
                                                                checked={getSelectedOptionsForPriceGroup(priceGroup, i).includes(option)}
                                                                value={option}
                                                                name={getServiceName(service.id, priceGroup, i)}
                                                                id={getServiceName(service.id, priceGroup, i)}
                                                                info={getOption(option).info}
                                                                labelAffix={" (" + getPriceDifference(service, option, false, priceGroup, i) + ")"}
                                                                label={getOption(option).label}
                                                            />
                                                        ))}
                                                    </fieldset>
                                                ) : null}
                                            </Fragment>
                                        ))}
                                    </div>
                                    <div className="col-md-auto text-end" role="cell">
                                        {translate('ticket.total-price')}

                                        <div className="mt-md-3">
                                        <span className="strong me-2 microanimations--change-blur" key={formatPrice(getPriceConfiguration(priceGroup, i).price, _config.lang, 'currency', 'CHF', 'code')}>
                                            {formatPrice(getPriceConfiguration(priceGroup, i).price, _config.lang, 'currency', 'CHF', 'code')}
                                        </span>
                                            {getPriceConfiguration(priceGroup, i).basePrice ? (
                                                <span className="ticket-configuration__red-base-price">
                                                    {formatPrice(getPriceConfiguration(priceGroup, i).basePrice, _config.lang, 'currency', 'CHF', 'code')}
                                                </span>
                                            ): ""}

                                            {getPriceConfiguration(priceGroup, i).discountText ? (
                                                <OverlayTrigger
                                                    trigger="click"
                                                    key={"discount"+ i}
                                                    rootClose={true}
                                                    description={getPriceConfiguration(priceGroup, i).discountText}
                                                    overlay={
                                                        <Tooltip id={`tooltip-discount-${"discount"+ i}`}>
                                                            <div className="wysiwyg"
                                                                 dangerouslySetInnerHTML={{__html: getPriceConfiguration(priceGroup, i).discountText}}/>
                                                        </Tooltip>
                                                    }>
                                                    <Button className="ms-2 btn-no-styling ms-2 btn-tooltip "><span
                                                        className="icon icon-sale ticket-configuration__discount-badge"/></Button>
                                                </OverlayTrigger>
                                            ): ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Fragment>
            ))}
            </div>

            {!hideTotal ? (
                <div className="border-top border-white mt-2 pt-3">
                    <div className="row">
                        <div className="col-6 strong">
                            {translate('ticket.total')}
                        </div>
                        <div className="col-6 text-end ticket-configuration__box-sum strong microanimations--change-blur" key={formatPrice(getTotalPrice(), _config.lang, 'currency', 'CHF', 'code')}>
                            <span>
                                {formatPrice(getTotalPrice(), _config.lang, 'currency', 'CHF', 'code')}
                            </span>

                            {getTotalPrice() !== getTotalBasePrice() ? (
                                <span className="ticket-configuration__red-base-price">
                                    {formatPrice(getTotalBasePrice(), _config.lang, 'currency', 'CHF', 'code')}
                                </span>
                            ) : ""}
                        </div>
                    </div>
                </div>
            ) : ""}

        </div>
    )
}