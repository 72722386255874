/** @jsx h */

import {h, Fragment} from "preact";
import {getLocaleDateString, getTimeString, getUTCLocalDateByOptions, throwAlertMessage} from "../utils/utils.js";
import 'whatwg-fetch';
import 'url-search-params-polyfill'; // Edge Polyfill
import {translate} from '@elements/translations';
import {assert} from 'superstruct';

export default function DateSelectionList({
    availableItems,
    onChangeSelected,
    selectedItemId,
    isTimeView = false,
    isDateTimeView = false,
    DateValidationType = {}
}) {

    function getAvailability(item) {
        let availabilityString = "";

        if (item.available && item.max) {
            availabilityString += item.available + " / " + item.max + " " + translate('ticket.available');
        }
        if (item.available === 0) {
            availabilityString += translate('ticket.booked-out');
        }

        return availabilityString;
    }

    function getTimeObj(timeItem) {
        let timeObj = {};

        if (timeItem.start) {
            timeObj.start = timeItem.start;
        }
        if (timeItem.end) {
            timeObj.end = timeItem.end;
        }

        return timeObj;
    }


    //for time-list setTime
    //for date-list setDate
    function getItemString(item) {
        if(isTimeView) {
            return getTimeObj(item);
        } else {
            return item.date;
        }
    }

    function validateItem(item) {
        if (item) {
            try {
                //validating data
                assert(item, DateValidationType);
                return true;
            } catch (error) {
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>date-selection-list - validateItem()</strong>:</p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }

    return (
        <Fragment>
            <ul className="selection__list list-unstyled microanimations--fade microanimations--first-fold" style="--microanimations-stagger-factor: 1">
                {Object.keys(availableItems).map((item,key) => (
                    <Fragment>
                        {validateItem(availableItems[item]) ? (
                            <li className="selection__item" key={item}>
                                <label>
                                    <input type="radio"
                                           id={item}
                                           name="dateSelection"
                                           checked={item === selectedItemId}
                                           disabled={availableItems[item].available === 0}
                                           onClick={(evt) => {
                                               isDateTimeView ? (
                                                   //for date-time-list setDate + setTime
                                                   onChangeSelected(item, availableItems[item].date, getTimeObj(availableItems[item]))
                                               ) : (
                                                   onChangeSelected(item, getItemString(availableItems[item]))
                                               )
                                           }}
                                           className="selection__input"
                                    />
                                    <div className="selection__input-text">
                                        <div className="row gy-gutter--2">
                                            { !isTimeView ? (
                                                <div className="col-md-4 selection__item-title gy-gutter__item">
                                                    {getUTCLocalDateByOptions(availableItems[item].date)}
                                                </div>
                                            ) : ""}
                                            <div className={isTimeView ? "col-6 gy-gutter__item" : "col-md-4 gy-gutter__item"}>
                                                {getTimeString(availableItems[item])}
                                            </div>
                                            <div className={isTimeView ? "col-6 ticket-configuration__availability gy-gutter__item" : "col-md-4 ticket-configuration__availability gy-gutter__item"}>
                                                {availableItems[item].status !== 'danger' ? (
                                                    <span className={`me-2 status status--${availableItems[item].status}`}/>
                                                ) : "" }
                                                {getAvailability(availableItems[item])}
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </li>
                        ) : ""}
                    </Fragment>
                ))}
            </ul>
        </Fragment>
    )
}

