import { onEnterViewPort, isElementInViewport } from '@elements/viewport-utils';
import { onFind } from '@elements/init-modules-in-scope';
import { addClass } from '@elements/dom-utils';

const selectors = {
    base: '.js-microanimations--in-viewport',
}

const classes ={
    viewportTriggeredIsActive: 'microanimations--fade-active'
}

export function init() {
    onFind(selectors.base, (element) => {
        onEnterViewPort(element, () => addClass(classes.viewportTriggeredIsActive, element))
    })
}