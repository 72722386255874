"use strict";

import {findIn,find,on,addClass, trigger} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {forceRecalculation} from "./affix";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    onFind('.js-message', function (message) {
        let messageHeight = message.offsetHeight;
        if (isMobile){
            find('.nav-overlay').style.height = 'calc(100% - ' + messageHeight + 'px)';
        }
        let affixNavbarPlaceholder = find('.js-affix-navbar__placeholder');

        /* change affix offset if message is displayed */
        let affixElement = find('.js-affix');
        let affixOffset = 0;

        if(affixElement) {
            affixOffset = +affixElement.getAttribute('data-affix-offset') || 0;
            affixElement.setAttribute('data-affix-offset', affixOffset + messageHeight);
            forceRecalculation();
        }

        let cartOverlay = find('.js-nav__overlay--cart');
        let cartOverlayTop = 0;

        if(cartOverlay) {
            cartOverlayTop = +cartOverlay.offsetTop;
            cartOverlay.style.top = cartOverlayTop + messageHeight + "px";
        }

        let profileOverlay = find('.js-nav__overlay--profile');
        let profileOverlayTop = 0;

        if(profileOverlay) {
            profileOverlayTop = +profileOverlay.offsetTop;
            profileOverlay.style.top = profileOverlayTop + messageHeight + "px";
        }

        let messageClose = findIn('.js-message__close', message);

        const messageCloseHandler = (evt) => {
            document.cookie = "message-closed=true; expires='';";
            addClass('d-none', message);
            affixNavbarPlaceholder.style.minHeight = "0px";

            /* reset affix offset if message closed */
            if(affixElement) {
                affixElement.setAttribute('data-affix-offset', affixOffset);
                trigger('resize', window);
            }

            if(cartOverlay) {
                cartOverlay.style.top = cartOverlayTop + "px";
            }

            if(profileOverlay) {
                profileOverlay.style.top = profileOverlayTop + "px";
            }

            if (isMobile){
                find('.nav-overlay').style.height = '100%';
            }
        };

        on('click', (evt) => messageCloseHandler(evt), messageClose)

    });

}