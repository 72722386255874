/** @jsx h */

import {h, Fragment} from "preact";
import {noop} from "../../utils/utils";
import {useState} from "preact/hooks";
import LoadingSpinner from "../loading-spinner";
import {translate} from '@elements/translations';
import AddVipPass from "../../components/add-vip-pass";

export default function SkiTicketVipPassSelection({
    id = "",
    vipPasses = [],
    loadedVipPasses = [],
    onChangeVipPasses = noop(),
    onLoadedVipPasses = noop(),
    onChangeAllVipPassesValid = noop(),
    allVipPassesValid = false
}) {

    let [isLoading, setIsLoading] = useState(false);

    return (
        <Fragment>
            <div className="position-relative p-1">
                {isLoading ? (
                    <LoadingSpinner fullsizeOverlay={false}/>
                ) : null}
                <div className="vip-pass__info">{translate('ski-ticket.vip-pass.introduction')}</div>
                <AddVipPass className="position-relative"
                            id={id}
                            vipPasses={vipPasses}
                            loadedVipPasses={loadedVipPasses}
                            onChangeVipPasses={onChangeVipPasses}
                            onLoadedVipPasses={onLoadedVipPasses}
                            allVipPassesValid={allVipPassesValid}
                            onChangeLoading={(isLoading) => {setIsLoading(isLoading)}}
                            onChangeAllVipPassesValid={onChangeAllVipPassesValid}
                />
            </div>
        </Fragment>
    )
}