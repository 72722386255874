"use strict";

import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

const defaultSelectors = {
    base: '.js-ecommerce-tracking'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createEcommerceTracking(
            baseElement,
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createEcommerceTracking(baseElement) {
    on('click', function(evt) {
        let event = baseElement.getAttribute('data-ecommerce-tracking-event') || '';
        let ecommerceObj = baseElement.getAttribute('data-ecommerce-tracking-ecommerce') || "{}";

        window.dataLayer = window['dataLayer'] || [];

        let dataLayerData = {
            'event': event,
            'ecommerce': JSON.parse(ecommerceObj)
        };

        window.dataLayer.push(dataLayerData);
        console.log('GTM Tracking - dataLayer push:', dataLayerData);
    }, baseElement)
}
