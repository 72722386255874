/** @jsx h */

import "preact/debug";
import {h, render, Fragment} from "preact";
import {assert, object, number, string, array, optional, enums, func, boolean, defaulted} from 'superstruct';
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on} from '@elements/dom-utils';
import {getPrefixedDataSet} from '@elements/data-set-utils';
import SkiTicketModal from "./components/ski-ticket-config/ski-ticket-modal";
import {throwAlertMessage} from "./utils/utils";
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import {getSkiTicketBaseConfig} from "./utils/config-helper";
import SkiLockerModal from "./components/ski-locker-config/ski-locker-modal";

const selectors = {
    base: '.js-ski-ticket-config',
    open: '.js-ski-ticket-config__open',
    overlay: '.js-ski-ticket-config__overlay'
};

export function init() {

    /*
        these options will be read out of data-attribute
        firstView: sector-list datepicker or pricing-list
        firstViewInfo: info message for first view
        hasServiceView: if service step is needed
        hasSkilockerTeaser: if skilocker-teaser should be displayed on last step
        hasVipPassView: if vipPass
        hasDatepickerView: if datepicker
        hasPricingSlider: if pricing slider should be displayed
    */

    const TicketConfig = object({
        isLoading: boolean(),
        isInEdit: optional(boolean),
        editId: optional(string()),
        id: string(),
        isModalOpen: boolean(),
        title: string(),
        onModalHide: func(),
        onShowSkiLocker: optional(func()),
        firstView: enums(['sector-list','datepicker','pricing-list']),
        firstViewInfo: optional(object()),
        hasServiceView: boolean(),
        hasVipPassView: optional(boolean()),
        hasDatepickerView: boolean(),
        hasPricingSlider: boolean(),
        hasSkilockerTeaser: optional(boolean()),
        sectorDefault: optional(string()),
        sectorDefaultTitle: optional(string()),
        currentStepDefault: optional(number())
    })

    const EditTicketConfig = object({
        isLoading: boolean(),
        isInEdit: optional(boolean()),
        editId: optional(string()),
        id: string(),
        isModalOpen: boolean(),
        title: string(),
        onModalHide: func(),
        onShowSkiLocker: optional(func()),
        firstView: enums(['sector-list','datepicker','pricing-list']),
        firstViewInfo: optional(object()),
        hasServiceView: boolean(),
        hasVipPassView: optional(boolean()),
        hasDatepickerView: boolean(),
        hasPricingSlider: boolean(),
        hasSkilockerTeaser: optional(boolean()),
        sectorDefault: optional(string()),
        sectorDefaultTitle: optional(string()),
        currentStepDefault: optional(number()),
        dateDefault: optional(string()),
        dateStringDefault: optional(string()),
        dateToDefault: optional(string()),
        dateToStringDefault: optional(string()),
        ticketCounterDefault: optional(object()),
        productChoiceDefault: optional(string()),
        selectedOptionsDefault: optional(object()),
        selectedRangeInDaysDefault: optional(number())
    })

    onFind(selectors.base, function (Configurator) {
        let ConfiguratorOpen = findIn(selectors.open, Configurator);
        let ConfiguratorOverlay = findIn(selectors.overlay, Configurator);

        const renderWithData = data => render(<SkiTicketModal {...data}/>, ConfiguratorOverlay);

        /* if on last step of ski-ticket ski-locker should be display */
        const renderSkiLockerWithData = data => render(<SkiLockerModal {...data}/>, ConfiguratorOverlay);

        let data = {
            isModalOpen: true,
            onModalHide: (function () {
                data.isModalOpen = false;
                renderWithData(data);
            }),
            onShowSkiLocker:  (function (data) {
                data = {
                    ...data,
                    isModalOpen: true,
                    onModalHide: (function () {
                        data.isModalOpen = false;
                        renderSkiLockerWithData(data);
                    }),
                }
                renderSkiLockerWithData(data);
            }),
            isLoading: true
        };

        on('click', function() {
            let dataset = getPrefixedDataSet('ski-ticket-config', Configurator);
            dataset = transformDataset(dataset);
            let editData;

            if(dataset.isInEdit) {
                let params = {
                    ticket_id: dataset.id
                };

                let url = getSkiTicketBaseConfig().edit;
                let request = fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                request.then(result => result.json()).then(result => {
                    editData = result;
                    data = {
                        ...data,
                        ...dataset,
                        ...editData,
                        isLoading: false,
                        isModalOpen:true
                    };

                    try {
                        //validating data
                        assert(data, EditTicketConfig);
                        renderWithData(data);
                    } catch(error) {
                        console.error(error);
                        for (const failure of error.failures()) {
                            let errorText = `<p><strong>activity-ticket-config</strong>:edit</p><p>${failure.message}</p>`;
                            throwAlertMessage('danger', 'TYPE ERROR - ' + failure.key, errorText, true);
                        }
                    }
                }).catch(e => {
                    let errorText = `<p><strong>activity-ticket-config</strong>:edit failed!</p><p>${dataset.id}</p>`;
                    throwAlertMessage('danger', 'edit', errorText, true);
                });

            } else {
                data = {
                    ...data,
                    ...dataset,
                    isLoading: false,
                    isModalOpen:true
                };

                try {
                    //validating data
                    assert(data, TicketConfig);
                    renderWithData(data);
                } catch(error) {
                    console.error(error);
                    for (const failure of error.failures()) {
                        let errorText = `<p><strong>ski-ticket-config</strong>:Typo might be in dataset-values of div with class <strong>js-ski-ticket-config</strong></p><p>${failure.message}</p>`;
                        throwAlertMessage('danger', 'TYPE ERROR - ' + failure.key, errorText, true);

                    }
                }
            }

        }, ConfiguratorOpen);
    });

}

//transform dataset 'cause every data-set-property will be read out as a string
export function transformDataset(dataset) {

    let transformedDataset = {...dataset};

    if(dataset.hasSkilockerTeaser) {
        transformedDataset.hasSkilockerTeaser = (transformedDataset.hasSkilockerTeaser === "true");
    }

    if(dataset.hasDatepickerView) {
        transformedDataset.hasDatepickerView = (transformedDataset.hasDatepickerView === "true");
    }

    if(dataset.hasPricingSlider) {
        transformedDataset.hasPricingSlider = (transformedDataset.hasPricingSlider === "true");
    }

    if(dataset.hasServiceView) {
        transformedDataset.hasServiceView = (transformedDataset.hasServiceView === "true");
    }

    if(dataset.hasVipPassView) {
        transformedDataset.hasVipPassView = (transformedDataset.hasVipPassView === "true");
    }

    if(dataset.isInEdit) {
        transformedDataset.isInEdit = (transformedDataset.isInEdit === "true");
    }

    if(dataset.currentStepDefault) {
        transformedDataset.currentStepDefault = +transformedDataset.currentStepDefault;
    }

    if(dataset.firstViewInfo) {
        transformedDataset.firstViewInfo = JSON.parse(transformedDataset.firstViewInfo);
    }

    return transformedDataset;
}