import { onFind } from '@elements/init-modules-in-scope';
import { addClass, removeClass, hasClass, find, findAll, findIn, on, closest } from '@elements/dom-utils';

const selectors = {
    modal: '.js-configurator-modal--morphing',
    modalMask: '.js-ski-ticket-modal__global-mask',
    modalMaskCircle: '.js-ski-ticket-modal__global-mask-circle',
    buttonWrapper: '.js-microinteractions__morphing-button-wrapper',
    buttonBackground: '.js-microinteractions__morphing-button-background',
    buttonText: '.js-microinteractions__morphing-button-text',
    buttonIcon: '.js-microinteractions__morphing-button-icon',

    openModalWithoutButtonAnimation: '.js-microinteractions__morphing-button-wrapper--open-immediately'
}

const classes ={
    buttonIsAnimating: 'is-animating',
    modalIsVisible: 'is-active',
    circleIsAnimating: 'is-animating',
    circleIsAnimatingOut: 'is-animating-out'
}

const circleDiameter = 100;

export function init() {
    console.log('microanimations-morphingmodal.js init')

    onFind(selectors.buttonWrapper, (trigger) => {
        let viewportUpdatesRegistered = false;
        console.log("found trigger: ", trigger)

        on('click', () => {
            console.log("clicked morphingmodal button")
            let height = findHeightOfElement(trigger);
            setCssVariable('--buttonActualHeight', height + 'px');
            console.log("--buttonActualHeight", height + 'px');

            // update button
            const buttonBackground = findIn(selectors.buttonBackground, trigger);
            const buttonText = findIn(selectors.buttonText, trigger);
            const buttonIcon = findIn(selectors.buttonIcon, trigger);
            const buttonWrapper = findIn(selectors.buttonWrapper, trigger) || closest(selectors.buttonWrapper, trigger) || trigger;

            if(buttonWrapper) {
                buttonWrapper.style.maxHeight = height + 'px';
            }

            if(buttonBackground) {
                buttonBackground.style.maxHeight = height + 'px';
                addClass(classes.buttonIsAnimating, buttonBackground);
            }


            buttonIcon && addClass(classes.buttonIsAnimating, buttonIcon);
            buttonText && addClass(classes.buttonIsAnimating, buttonText);
            buttonWrapper && addClass(classes.buttonIsAnimating, buttonWrapper);

            // circle transformations
            setCirclePosition(trigger);

            const circleSmallScale = height / circleDiameter;
            setCssVariable('--circleSmallScale', circleSmallScale * 0.95);

            const modal = find(selectors.modal);
            const heightOfModal = modal ? findHeightOfElement(findIn('.modal-content__inner', modal)) : 0;
            const maxDimension = Math.max(window.innerWidth, window.innerHeight, heightOfModal);
            const circleLargeScale = (maxDimension / height) * 5;
            setCssVariable('--circleLargeScale', circleLargeScale);

            // doing this just once per trigger is enough
            if(!viewportUpdatesRegistered) {
                viewportUpdatesRegistered = true;
                on('scroll', () => {
                    if(isModalOpen()) {
                        console.log("modal is open!")
                        return;
                    }

                    setCirclePosition(trigger)
                }, window)

                on('resize', () => {
                    setCirclePosition(trigger)
                    height = findHeightOfElement(trigger)
                    setCssVariable('--buttonActualHeight', height + 'px');

                    const heightOfModal = modal ? findHeightOfElement(findIn('.modal-content__inner', modal)) : 0;
                    const maxDimension = Math.max(window.innerWidth, window.innerHeight, heightOfModal);
                    const circleLargeScale = (maxDimension / height) * 5;
                    setCssVariable('--circleLargeScale', circleLargeScale);
                }, window)
            }

            if(hasClass(selectors.openModalWithoutButtonAnimation, trigger)) {
                expandModalMask()
            } else {
                executeAfterButtonShrink(expandModalMask)
            }

            function expandModalMask() {
                find(selectors.modal) && addClass(classes.modalIsVisible, find(selectors.modal));
                find(selectors.modalMaskCircle) && removeClass(classes.circleIsAnimatingOut, find(selectors.modalMaskCircle));
                find(selectors.modalMaskCircle) && addClass(classes.circleIsAnimating, find(selectors.modalMaskCircle));
            }

            executeAfterMaskExpand(() => {
                find(selectors.modal) && addClass('microanimations--no-mask', find(selectors.modal))
            })
        }, trigger)
    })
}

function setCssVariable(name, value) {
    document.documentElement.style.setProperty(name, value);
}

function getCssVariable(name) {
    document.documentElement.style.getPropertyValue(name);
}

function removeUnit(value) {
    if(!value) return

    const unitsToRemove = ['px', 'rem', 'ms'];
    let result = value;
    unitsToRemove.forEach(unit => {
        result = result.replace(unit, '');
    });

    return result
}

function findCenterOfElement(element) {
    const rect = element.getBoundingClientRect();
    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2
    };
}

function findHeightOfElement(element) {
    const rect = element.getBoundingClientRect();
    return rect.height;
}

function setCirclePosition(trigger) {
    const center = findCenterOfElement(trigger);
    setCssVariable('--centerShiftValueX', center.x + 'px');
    setCssVariable('--centerShiftValueY', center.y + 'px');
}

export function resetMaskModal() {
    findAll('.js-configurator-modal--morphing').forEach((element) => removeClass('is-visible', element));
    findAll('.js-ski-ticket-modal__global-mask-circle').forEach((element) => removeClass('is-animating', element));
    findAll('.js-ski-ticket-modal__global-mask-circle').forEach((element) => addClass('is-animating-out', element));
    findAll('.js-microinteractions__morphing-button-background').forEach((element) => removeClass('is-animating', element));
    findAll('.js-microinteractions__morphing-button-text').forEach((element) => removeClass('is-animating', element));
    findAll('.js-microinteractions__morphing-button-icon').forEach((element) => removeClass('is-animating', element));
    findAll('.js-microinteractions__morphing-button-wrapper').forEach((element) => removeClass('is-animating', element));

    executeAfterButtonShrink(() => {
        findAll('.js-microinteractions__morphing-button-wrapper').forEach((element) => element.style.maxHeight = '');
        findAll('.js-microinteractions__morphing-button-background').forEach((element) => element.style.maxHeight = '');
    })
}

function isModalOpen() {
    return find(selectors.modal)?.classList.contains(classes.modalIsVisible);
}

function executeAfterButtonShrink(callback) {
    setTimeout(() => {
        callback()
    }, removeUnit(getCssVariable('--morphing-duration')) || 500);
}

function executeAfterMaskExpand(callback) {
    setTimeout(() => {
        callback()
    }, removeUnit(getCssVariable('--morphing-duration')) * 2 || 1000);
}