/** @jsx h */

import {h, Fragment} from "preact";
import {noop, fetcher, addParamsToUrl, throwAlertMessage} from "../../utils/utils.js";
import {assert, object, number, optional, string, define, array, enums, func, boolean, defaulted} from 'superstruct';
import LoadingSpinner from "../loading-spinner";
import DateSelectionList from "../date-selection-list";
import {useTimeConfigurations} from "../../utils/activity-configuration-hooks";
import {translate} from '@elements/translations';

export default function ActivityTicketTimeSelection({
    id = "",
    selectedDate = "",
    selectedTimeId = "",
    onChangeTime = noop()
}) {
    const TimeType = object({
        start: string(),
        end: optional(string()),
        available: optional(number()),
        max: optional(number()),
        status: enums(['success','warning','danger']),
    });


    let params = {
        activity_id: id,
        date: selectedDate
    }

    let {timeConfigurations, isLoading, error} = useTimeConfigurations(params);

    return (
        <Fragment>
            { isLoading ? (
                <LoadingSpinner/>
            ) : ""}

            { error ? (
                <div className="alert alert-danger">
                    {translate('activity-ticket.Loading-Error')}
                </div>
            ) : ""}

            {timeConfigurations ? (
                <DateSelectionList
                    availableItems={timeConfigurations.availabilityByTime}
                    onChangeSelected={onChangeTime}
                    selectedItemId={selectedTimeId}
                    isTimeView={true}
                    DateValidationType={TimeType}
                />
            ) : ""}
        </Fragment>
    )
}

