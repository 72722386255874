/** @jsx h */

import {h, Fragment} from "preact";
import {noop, fetcher, addParamsToUrl, throwAlertMessage} from "../../utils/utils.js";
import {assert, object, number, optional, string, date, map, define, array, enums, func, boolean, defaulted} from 'superstruct';
import LoadingSpinner from "../loading-spinner";
import SelectionList from "../selection-list";
import {useLocationConfigurations} from "../../utils/ski-locker-configuration-hooks";
import {translate} from '@elements/translations';

export default function SkiLockerLocationSelection({
    id = "",
    selectedLocationId = "",
    onChangeLocation = noop()
}) {

    const LocationType = object({
        title: string(),
        subtitle: optional(string()),
        info: optional(string())
    });

    let params = {
        locker_id: id
    }

    let {locationConfigurations, isLoading, error} = useLocationConfigurations(params);

    return (
        <Fragment>
            { isLoading ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('ski-locker.Loading-Error')}
                </div>
            ) : ""}
            {locationConfigurations ? (
                <SelectionList
                    availableItems={locationConfigurations.availabilityByLocation}
                    onChangeSelected={onChangeLocation}
                    selectedItemId={selectedLocationId}
                    ValidationType={LocationType}
                />
            ) : ""}
        </Fragment>
    )
}

