import {showNotification} from '@elements/alert-notification';
import {translate} from '@elements/translations';
import {dateToISOString, UTCDateToLocalDate, localDateToUTCDate} from "@elements/date-utils";

// No operation function; usually used as default fallback function to prevent errors
export function noop() {}

// export const fetcher = url => fetch(url).then(res => res.json());

export const fetcher = async url => {
    const res = await fetch(url)
    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
        console.error('Fetching Data Error', res.status);
        throwAlertMessage('danger', 'Fetching Data Error', "ERROR - " + res.status, true);
    }
    return res.json()
}

export function addParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);
    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));
    return url;
}


export function throwAlertMessage(type, title, errorMessage, closable) {
    if (process.env.NODE_ENV === 'development') {
        showNotification({
            type: type,
            title: title,
            content: errorMessage,
            closable: closable
        });
    }
}

export function getTimeString(timeItem) {
    let timeString = "";

    if (timeItem.start) {
        timeString += timeItem.start;
    }
    if (timeItem.end) {
        timeString += " - " + timeItem.end;
    }

    return timeString;
}

export function getLocaleDateString(date) {
    return getLocalDateByOptions(date, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
}
export function getLocaleDateStringShort(date) {
    return getLocalDateByOptions(date, { year: 'numeric', month: 'short', day: 'numeric' });
}
export function getLocaleMonthName(date) {
    return getLocalDateByOptions(date,{ month: 'long' });
}
export function getLocaleMonth(date) {
    return getLocalDateByOptions(date,{ month: 'numeric' });
}
export function getLocaleDay(date) {
    return getLocalDateByOptions(date,{ day: 'numeric' });
}
export function getLocaleYear(date) {
    return getLocalDateByOptions(date,{ year: 'numeric' });
}
export function getLocaleWeekday(date) {
    return getLocalDateByOptions(date,{ weekday: 'long' });
}
export function getLocaleNextMonthName(date) {
    let addMonthToDate = date.getMonth() == 11 ? new Date(date.getFullYear()+1, 0 , 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1);
    return getLocalDateByOptions(addMonthToDate,{ month: 'long' });
}
export function getLocalePrevMonthName(date) {
    let addMonthToDate = date.getMonth() == 1 ? new Date(date.getFullYear()-1, 11 , 1) : new Date(date.getFullYear(), date.getMonth() -1, 1);
    return getLocalDateByOptions(addMonthToDate,{ month: 'long' });
}
export function getLocalePrevMonth(date) {
    let addMonthToDate = date.getMonth() == 1 ? new Date(date.getFullYear()-1, 11 , 1) : new Date(date.getFullYear(), date.getMonth() -1, 1);
    return getLocalDateByOptions(addMonthToDate,{ month: 'numeric' });
}

//needed for datepicker
export function getLocalDateByOptions(date, options) {
    let newDate = new Date(date.toString());
    let localeDate = _config.localeDate;

    if(!localeDate) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.localeDate". Value of "_config.localeDate" was not set in _config', true);
    }

    return newDate.toLocaleDateString(localeDate, options);
}

export function getUTCLocalDateByOptions(date, options) {
    let newDate = UTCDateToLocalDate(new Date(date.toString()));
    let localeDate = _config.localeDate;

    if(!localeDate) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.localeDate". Value of "_config.localeDate" was not set in _config', true);
    }

    return newDate.toLocaleDateString(localeDate, options);
}

export function getQueryStringOfObject(obj) {
    return new URLSearchParams(obj).toString();
}
export function getSumOfObjectKeys(objectItem) {
    let sum = 0;

    try {
        Object.keys(objectItem).map(function(item) {
            if(objectItem[item]) {
                sum += objectItem[item];
            }
        })
    } catch (e) {
        throwAlertMessage('warning', 'getSumOfObjectKeys Calculation failed - getSumOfObjectKeys()', e, true);
    }

    return sum;
}

export function getPriceGroupTitle(priceGroups, priceGroup) {
    let title = translate('activity-ticket.ticket');
    priceGroups.map(function(item) {
        if(item.id === priceGroup) {
            if(item.title) {
                title =  item.title;
            }
        }
    });

    return title;
}

export function getPriceGroup(priceGroups, priceGroup) {
    let title = translate('activity-ticket.ticket');
    priceGroups.map(function(item) {
        if(item.id === priceGroup) {
            if(item.title) {
                title =  item.title;
            }
        }
    });

    return title;
}


export function getRangeDays(startDate, endDate) {
    if(startDate && !endDate) {
        return 1;
    } else {
        let difference = new Date(endDate) - new Date(startDate);
        return  Math.round(difference / (1000 * 60 * 60 * 24) + 1);
    }
}

export function addDays(date, days) {
    let result = new Date(Number(date));
    result.setHours(0,0,0,0);
    result.setDate(date.getDate() + +days);
    return result;
}

export function removeDays(date, days) {
    let result = new Date(Number(date));
    result.setHours(0,0,0,0);
    result.setDate(date.getDate() - +days);
    return result;
}


export function formatDate(date){
    let d = UTCDateToLocalDate(new Date(date)),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2){
        month = '0' + month;
    }
    if (day.length < 2){
        day = '0' + day;
    }
    return [year, month, day].join('-');
}

export function jsUcFirst(string){
    return string.charAt(0).toUpperCase()+string.slice(1);
}

export function formatPrice(amount, locale, style, currency, currencyDisplay, currencyWrapperTagName){
    let string = amount.toLocaleString(locale, {
        style:style || 'decimal',
        currencyDisplay:currencyDisplay || 'symbol',
        currency:currency
    });
    if (currencyWrapperTagName){
        const stringCurrency = string.replace(/(\,|\.|\d)+/g, '').trim();
        string = string.replace(stringCurrency, '<'+currencyWrapperTagName+'>'+stringCurrency+'</'+currencyWrapperTagName+'>');
    }
    return string;
}