/** @jsx h */

import {h, Fragment} from "preact";
import {formatPrice, getLocalDateByOptions, getLocaleDateStringShort, noop, throwAlertMessage} from "../utils/utils";
import {assert} from 'superstruct';
import {translate} from '@elements/translations';
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';

export default function SectorList({
  selectedSector = "",
  sectors = {},
  SectorValidationType = {},
  onChangeSelected = noop()
}) {

    function validateItem(item) {
        if (item) {
            try {
                //validating data
                assert(item, SectorValidationType);
                return true;
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>sector-list - validateItem()</strong>:</p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }

    //render
    return (
        <div className="position-relative js-scrolling-area scrolling-area--sector-teaser">
            <div className="row row--same-height  scrolling-area scrolling-area--xs scrolling-area--slider js-scrolling-area__area">
                {sectors.map((item, index) => (
                    <div className="col-md-3 scrolling-area__item js-scrolling-area__item" key={item.id}>
                        {validateItem(item) ? (
                            <div className="position-relative row--same-height__item">
                                <section className={`sector-teaser d-flex flex-column stretch-link h-100 microanimations--fade microanimations--first-fold ${item.id === selectedSector ? 'sector-teaser--is-active' : ''} ${item.isHighlighted && !item.isDisabled ? 'sector-teaser--is-highlighted' : ''} 
                            ${item.isDisabled ? 'sector-teaser--is-disabled' : ''}`} style={`--microanimations-stagger-factor: ${index + 3};`}>
                                    { item.tooltipInfo ? (
                                        <OverlayTrigger
                                            trigger="click"
                                            key={item.id}
                                            rootClose={true}
                                            description={item.info}
                                            overlay={
                                                <Tooltip className="tooltip--large text-start tooltip--left" id={`tooltip-sector-${item.id}`}>
                                                    <div className="tooltip__title">{ item.tooltipInfo.title }</div>
                                                    <div className="tooltip__description wysiwyg"
                                                         dangerouslySetInnerHTML={{__html: item.tooltipInfo.description}}/>
                                                    { item.tooltipInfo.validityDate && item.tooltipInfo.validityDate.start ? (
                                                        <div className="tooltip__date">
                                                            <strong>
                                                                { getLocalDateByOptions(item.tooltipInfo.validityDate.start, {year: 'numeric', month: '2-digit', day: '2-digit' }) }
                                                                { item.tooltipInfo.validityDate.end ? ' - ' + getLocalDateByOptions(item.tooltipInfo.validityDate.end, {year: 'numeric', month: '2-digit', day: '2-digit' }) : '' }
                                                            </strong>
                                                        </div>
                                                    ) : ""}
                                                </Tooltip>
                                            }>
                                            <Button className="btn-no-styling btn-outline-dark btn-info sector-teaser__tooltip-teaser-btn"><span
                                                className="icon icon-info btn-tooltip__icon"/></Button>
                                        </OverlayTrigger>
                                    ) : "" }

                                    <div className="row mb-3">
                                        <div className="col-9">
                                            <div className="sector-teaser__title">
                                                {translate(`ski-ticket.sector`)} {item.title}
                                            </div>
                                        </div>

                                        <div className="col-3 gy-gutter--10">
                                            <div className="sector-teaser__feature gy-gutter__item">
                                                <span className="icon icon-lift sector-teaser__feature-icon" title={translate(`ski-ticket.lifts`)}/>
                                                {item.numberOfLifts}
                                            </div>
                                            <div className="sector-teaser__feature gy-gutter__item">
                                                <span className="icon icon-slope sector-teaser__feature-icon" title={translate(`ski-ticket.slope-kilometres`)}/>
                                                {item.numberOfSlopeKilometers}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {translate(`ski-ticket.price from`)}
                                        <span className="sector-teaser__price mx-1" dangerouslySetInnerHTML={{__html: formatPrice(item.fromPrice, _config.lang, 'currency', 'CHF', 'code', "span class='sector-teaser__currency text-black'")}}>
                                    </span>
                                    </div>

                                    <div className="sector-teaser__small-print">
                                        {item.smallPrint}
                                    </div>

                                    <div className="sector-teaser__description">
                                        {item.shortDescription}
                                    </div>

                                    <div className="sector-teaser__description">
                                        {translate('ticket-calculator.sector-region-list-title')}
                                    </div>

                                    <ul className="list-unstyled sector-teaser__list gy-gutter--10 mb-4">
                                        {item.regionList.map((region, key) => (
                                            <li className="gy-gutter__item d-flex" key={key}>
                                                {region.isChecked ? (
                                                    <span className="icon icon-check text-success me-2" title={translate(`ski-ticket.included`)} />
                                                ) : (
                                                    <span className="icon icon-close text-primary me-2" title={translate(`ski-ticket.not-included`)} />
                                                )}
                                                {region.title}
                                            </li>
                                        ))}
                                    </ul>

                                    <button className={`btn mt-auto stretch-link__link position-static sector-teaser__btn ${item.isHighlighted ? 'btn-primary' : 'btn-outline-primary'}`}
                                            onClick={(evt) => {
                                                onChangeSelected(item.id, item.title);
                                            }}>
                                        {translate(`ski-ticket.book-now`)}
                                    </button>
                                </section>
                                { item.validityDate ? (
                                    <div className="text-end sector-teaser__validity-date mt-2">
                                        {translate(`ski-ticket.valid-from`)} <strong>{ getLocalDateByOptions(item.validityDate.start, {year: 'numeric', month: '2-digit', day: '2-digit' }) }
                                        { item.validityDate.end ? ' - ' + getLocalDateByOptions(item.validityDate.end, {year: 'numeric', month: '2-digit', day: '2-digit' }) : ''}</strong>
                                    </div>
                                ) : ""}
                            </div>
                        ) : ""}
                    </div>
                ))}
            </div>

        </div>
    );
}