/** @jsx h */

import {h, Fragment} from "preact";
import {throwAlertMessage} from "../utils/utils.js";
import {translate} from '@elements/translations';
import {assert} from 'superstruct';
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';

export default function SelectionList({
    availableItems = {},
    onChangeSelected,
    selectedItemId,
    ValidationType = {}
}) {

    function getAvailability(item) {
        let availabilityString = "";

        if (item.available && item.max) {
            availabilityString += item.available + " / " + item.max + " " + translate('ticket.available');
        }
        if (item.available === 0) {
            availabilityString += translate('ticket.booked-out');
        }

        return availabilityString;
    }


    function validateItem(item) {
        if (item) {
            try {
                //validating data
                assert(item, ValidationType);
                return true;
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>selection-list - validateItem()</strong>:</p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }


    return (
        <Fragment>
            <ul className="selection__list list-unstyled">
                {Object.keys(availableItems).map((item,key) => (
                    <Fragment>
                        {validateItem(availableItems[item]) ? (
                            <li className="selection__item" key={item}>
                                <label>
                                    <input type="radio"
                                           id={item}
                                           name="dateSelection"
                                           checked={item === selectedItemId}
                                           disabled={availableItems[item].available === 0}
                                           onClick={(evt) => {
                                               onChangeSelected(item, {"title": availableItems[item].title, "subtitle": availableItems[item].subtitle});
                                           }}
                                           className="selection__input"
                                    />
                                    <div className="selection__input-text">
                                        <div className="row gy-gutter--2">
                                            <div className="col-md-8">
                                                <strong className="me-1">
                                                    {availableItems[item].title}
                                                </strong>

                                                {availableItems[item].subtitle}

                                                {availableItems[item].info ? (
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        key={item+"-info"}
                                                        rootClose={true}
                                                        description={availableItems[item].info}
                                                        overlay={
                                                            <Tooltip id={`tooltip-${item}`}>
                                                                <div className="wysiwyg"
                                                                     dangerouslySetInnerHTML={{__html: availableItems[item].info}}/>
                                                            </Tooltip>
                                                        }>
                                                        <Button className="btn-no-styling btn-info ms-2 btn-tooltip "><span
                                                            className="icon icon-info"/></Button>
                                                    </OverlayTrigger>
                                                ): ""}

                                            </div>
                                            {availableItems[item].available ? (
                                                <div className="col-md-4 ticket-configuration__availability gy-gutter__item">
                                                    {availableItems[item].status !== 'danger' ? (
                                                        <span className={`me-2 status status--${availableItems[item].status}`}/>
                                                    ) : "" }
                                                    {getAvailability(availableItems[item])}
                                                </div>
                                            ) : ""}
                                        </div>
                                    </div>
                                </label>
                            </li>
                        ) : ""}
                    </Fragment>
                ))}
            </ul>
        </Fragment>
    )
}

