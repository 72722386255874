/** @jsx h */

import {h, Fragment} from "preact";
import {translate} from '@elements/translations';
import {getSkiTicketBaseConfig} from "../utils/config-helper";
import {useEffect, useState} from "preact/hooks";
import {addDays, noop, removeDays, throwAlertMessage} from "../utils/utils";
import {
    assert,
    object,
    optional,
    string,
    array,
    boolean,
} from 'superstruct';
import VipPassItem from "./vip-pass-item";

export default function AddVipPass({
        id = "",
        vipPasses = [],
        onChangeVipPasses = noop(),
        loadedVipPasses = [],
        onLoadedVipPasses = noop(),
        allVipPassesValid = false,
        onChangeAllVipPassesValid = noop(),
        onChangeLoading = noop()
    }) {

    let [inputValue, setInputValue] = useState("");
    let [floatingLabel, setFloatingLabel] = useState(false);
    let [isValidTimeRange, setIsValidTimeRange] = useState(true);

    function validateVipPass(remove, inputValue) {

        onChangeLoading(true);

        //add
        let tempVipPasses = vipPasses;

        if (remove) {
            const index = tempVipPasses.indexOf(inputValue);
            if (index > -1) {
                tempVipPasses.splice(index, 1);
            }
        } else {
            tempVipPasses.push(inputValue)
        }

        onChangeVipPasses(tempVipPasses);

        setInputValue("");

        fetchVipPasses();

    }

    function fetchVipPasses(){

        let params = {
            ticket_id: id,
            vip_passes: vipPasses
        }
        let url = getSkiTicketBaseConfig().vipPass;

        let vipPassType = object({
            success: boolean(),
            isValidTimeRange: boolean(),
            __comment: optional(string()),
            vipPasses: array(object({
                id: string(),
                isValid: boolean(),
                vipPassNumber: string(),
                startDate: string(),
                endDate: string(),
                infoText: optional(string())
            }))
        });

        let request = fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        request.then(result => result.json()).then(result => {
            if (result.success) {
                const data = {
                    ...result
                }
                try {
                    assert(data, vipPassType);
                    setIsValidTimeRange(result.isValidTimeRange);
                    onLoadedVipPasses(result.vipPasses);
                    let vipPassesValid = true;

                    result.vipPasses.map(function(vipPass){
                        if(!vipPass.isValid){
                            vipPassesValid = false;
                        }
                    });
                    result.isValidTimeRange && vipPassesValid ? onChangeAllVipPassesValid(true) : onChangeAllVipPassesValid(false);
                    onChangeLoading(false);
                } catch (error) {
                    for (const failure of error.failures()) {
                        let errorText = `<p><strong>vip-pass-add: </strong></p><p>${failure.message}</p>`;
                        throwAlertMessage('danger', 'TYPE ERROR - ' + failure.key, errorText, true);
                    }
                    onChangeLoading(false);
                }
            }
        }).catch(e => {
            console.log("CATCH", e);
            let errorText = `<p><strong>vip-pass</strong>:vip-pass-validation failed!</p><p>${id}</p><p>${e}</p>`;
            throwAlertMessage('danger', 'vip-pass', errorText, true);
        });
    }

    function removeVipPass(inputValue) {
        validateVipPass(true, inputValue);
    }

    return (
        <Fragment>
            <ul className="list-unstyled vip-pass__list">
                {loadedVipPasses?.map((vipPass, key) => (
                    <li>
                        <VipPassItem
                            id={vipPass.id}
                            onRemoveVipPass={removeVipPass}
                            isValid={vipPass.isValid}
                            counter={key + 1}
                            startDate={vipPass.startDate}
                            endDate={vipPass.endDate}
                            infoText={vipPass.infoText}
                            inputValue={vipPass.vipPassNumber}
                        />
                    </li>
                ))}
            </ul>
            { isValidTimeRange && (allVipPassesValid || vipPasses.length === 0 ) ? (
                <div className="vip-pass__item vip-pass--add">
                    <div className="vip-pass__headline mb-3">{translate('ski-ticket.vip-pass.add vip pass')}</div>
                    <div className="vip-pass__input-group">
                        <div className={`vip-pass__input form-group ${floatingLabel ? 'has-value' : ''}`}>
                            <label htmlFor="vip-pass" className="form-control-label floating-label">
                                {translate('ski-ticket.vip-pass.enter vip pass number')}
                            </label>
                            <input id="vip-pass"
                                   className={`form-control js-floating-label ${floatingLabel ? 'has-value' : ''}`}
                                   type="text" value={inputValue} onChange={(event) => {
                                setFloatingLabel(event.target.value.length > 0);
                                setInputValue(event.target.value);
                            }}/>
                        </div>
                        <button className="btn btn-wide btn-dark vip-pass__btn" onClick={(event) => {
                            validateVipPass(false, inputValue);
                        }}>
                            {translate('ski-ticket.vip-pass.check vip pass')}
                        </button>
                    </div>
                </div>
            ) : ""}
            {!isValidTimeRange ? (
                <Fragment>
                    <div className="vip-pass__time-info-hl">
                        <span className="icon icon-close me-2" aria-hidden="true"/>
                        The added passes are not valid in the same time period
                    </div>
                    <div className="vip-pass__time-info-text">
                        {translate('ski-ticket.vip-pass.no overlapping timespans')}
                    </div>
                </Fragment>
            ) : ""}

        </Fragment>
    )
}