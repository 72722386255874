/** @jsx h */

import {h} from "preact";
import {noop} from "../utils/utils";

export default function NumberSpinner({
    value = 0,
    maxValue = 20,
    onChange = noop()
}) {

    //render
    return (
        <div className="number-spinner">
            <div className="d-flex justify-content-end justify-content-md-start">
                <button className="btn number-spinner__button" type="button"
                        disabled={value <= 0}
                        onClick={() => {
                            onChange(value -1);
                        }}>
                    -
                </button>

                <input type="number" pattern="[0-9]*" min="0" value={value} onChange={(evt) => onChange(evt.target.value)} readOnly={true} className="number-spinner__input" />

                <button className="btn number-spinner__button" type="button"
                        disabled={maxValue <= value}
                        onClick={() => {
                            onChange(value +1);
                        }}>
                    +
                </button>
            </div>
        </div>
    );
}