"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findAll,findIn,closest,removeClass, findAllIn, on, off, addClass, hasClass, setAttribute} from '@elements/dom-utils';

const selectors = {
    base: '.js-nav',
    toggleOverlay: '.js-nav__toggle-overlay',
    dropdown: '.js-nav__dropdown',
    overlay: '.js-nav__overlay',
    list: '.js-nav__list',
    listItem: '.js-nav__list-item',
    listToggle: '.js-nav__list-toggle'
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init () {
    onFind(selectors.toggleOverlay, function (toggleOverlay) {
        let baseElement = closest(selectors.base,toggleOverlay);
        let dropdowns = findAllIn(selectors.dropdown, baseElement);

        if(!isMobile()){
            dropdowns.map(function(dropdown){
                on('shown.bs.dropdown', function(){
                    console.log('dropdown shown')
                    let navToggles = findAllIn(selectors.toggleOverlay, baseElement);
                    let navOverlays = findAllIn(selectors.overlay, baseElement);
                    navToggles.map(function (toggle){
                        removeClass('is-open', toggle)
                    });
                    navOverlays.map(function (overlay){
                        removeClass('show-overlay', overlay)
                    });
                }, dropdown)
            })
        }

        on('click', function(evt) {
            let targetId = toggleOverlay.getAttribute('data-nav-overlay-target');
            let overlayItem = findIn(targetId, baseElement);

            if(hasClass('show-overlay', overlayItem)) {
                hideItem(toggleOverlay);
                hideOverlay(overlayItem);
                removeClass('nav-open', document.body);
                let overlayToggles = findAll(selectors.toggleOverlay);
                overlayToggles.forEach((element) => {
                    removeClass('is-open', element)
                });
            } else {
                showItem(toggleOverlay);
                showOverlay(overlayItem);
                addClass('nav-open', document.body);
                let overlayToggles = findAll(selectors.toggleOverlay);

                overlayToggles.forEach((element) => {
                    removeClass('is-open', element)
                });

                if (hasClass(selectors.toggleOverlay, evt.target)){
                    addClass('is-open',evt.target);
                }else{
                    let closestToggleOverlay = closest(selectors.toggleOverlay,evt.target);
                    addClass('is-open',closestToggleOverlay);
                }
            }

            function bodyClickHandler(evt) {
                if (!closest('.main-navbar', evt.target) && !closest('.modal', evt.target)) {
                    evt.preventDefault();
                    hideItem(toggleOverlay);
                    hideOverlay(overlayItem);
                    removeClass('nav-open', document.body);
                }
            }

            function showOverlay(overlay) {
                findAll('.show-overlay').map(element => hideOverlay(element));
                addClass('show-overlay', overlay);
                setAttribute('aria-expanded', true, overlay);

                // wait becaus of render-template
                setTimeout(function() {
                    let inputInOverlay = findIn('input',overlay);
                    if(inputInOverlay){
                        // iOS needs focusing directly in the click event
                        inputInOverlay.focus();

                        let timeout = setTimeout(function () {
                            /* timeout is needed because some browsers don't allow focusing a element
                            if it is not visible */
                            inputInOverlay.focus();
                            clearTimeout(timeout);
                        }, 500);
                    }
                }, 500);


                if (!isMobile()) {
                    setTimeout(function() {
                        addBodyClickHandler(bodyClickHandler);
                    }, 300);
                }
            }
            function hideOverlay(overlay) {
                removeClass('show-overlay', overlay);
                setAttribute('aria-expanded', false, overlay);
                if (!isMobile()) {
                    removeBodyClickHandler(bodyClickHandler);
                }
            }

        }, toggleOverlay);

    });


    onFind(selectors.list, function (list) {
        let listItems = findAllIn(selectors.listItem, list);
        let listToggle = findAllIn(selectors.listToggle, list);

        listToggle.forEach(function(item) {
            on('click', function (evt) {
                let element = evt.target;
                evt.preventDefault();

                let parentItem = closest(selectors.listItem, element);

                if (hasClass('is-open', parentItem)) {
                    if (isMobile()) {
                        hideItem(parentItem);
                    } else {
                        hideItem(parentItem);
                    }
                } else {
                    if (isMobile()) {
                        showItem(parentItem);
                    } else {
                        hideItems(listItems);
                        showItem(parentItem);
                    }
                }
            }, item);
        });
    });
}

function addBodyClickHandler(bodyClickHandler) {
    on('click', bodyClickHandler, document.body);
}
function removeBodyClickHandler(bodyClickHandler) {
    off('click', bodyClickHandler, document.body);
}
function hideItem(item) {
    removeClass('is-open', item);
    if (hasClass('js-nav__list-item', item)){
        removeClass('nav-list-open', closest(selectors.overlay, item));
    }
    removeClass('is-open--only-desktop', item);
    setAttribute('aria-expanded', false, item);
}
function hideItems(items) {
    items.forEach(function(item) {
        removeClass('is-open', item);
        removeClass('is-open--only-desktop', item);
        setAttribute('aria-expanded', false, item);
    });

}
function showItem(item) {
    // findAll(selectors.toggleOverlay).map(element => hideItem(element));
    addClass('is-open', item);
    if (hasClass('js-nav__list-item', item)){
        addClass('nav-list-open', closest(selectors.overlay, item));
    }
    setAttribute('aria-expanded', true, item);
}
