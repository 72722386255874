"use strict";
import {on, find, closest, findAllIn, findIn, empty} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import fetch from '@elements/fetch';
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    onFind('.js-ajax-modal-toggle', function (element) {
        let modalSelector = element.getAttribute('data-ajax-modal-target');
        let appendData = element.getAttribute('data-ajax-modal-append-data') || false;
        let hideOnFormSuccess = element.getAttribute('data-ajax-modal-hide-on-form-success') || false;
        let modal = find(modalSelector);

        if (modal) {
            let modalInit = new Modal(modal);

            on('click', function () {
                let data = "";
                if(appendData) {
                    let wrapper = closest('.js-ajax-modal__wrapper', element);
                    let inputs = findAllIn('.js-ajax-modal__input', wrapper);
                    let itemFormData = new FormData();
                    inputs.forEach(function(item) {
                        if(item.checked) {
                            itemFormData.append(item.getAttribute('name'), item.value);
                        }
                    })
                    data = new URLSearchParams(itemFormData).toString();
                }

                let url = element.getAttribute('data-ajax-modal-content-url');
                url = url + (data.length > 0 ? "&" + data : data);

                modalInit.show();

                asyncAppend({
                    target: findAllIn('.js-ajax-modal__content', modal),
                    loading: findAllIn('.js-ajax-modal__loading', modal)
                }, fetch(url));
            }, element);

            if(hideOnFormSuccess){
                let ajaxForm = closest('.js-ajax-form', modal);
                on('success.ajax-form', (evt) => {
                    modalInit.hide();
                }, ajaxForm);
            }

            on('hidden.bs.modal', function () {
                empty(findIn('.js-ajax-modal__content', modal));
            }, modal);
        } else {
            console.warn(`.js-ajax-modal-toggle: "${modalSelector}" not found`)
        }


    });
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}
