/** @jsx h */

import {h, Fragment} from "preact";
import {useEffect} from "preact/hooks";
import {getSumOfObjectKeys, noop, throwAlertMessage} from "../../utils/utils.js";
import {assert, object, number, optional, string, define, array, enums, func, boolean, defaulted} from 'superstruct';
import PricingList from "../pricing-list";
import {usePricingConfigurations, usePricingSliderConfigurations} from "../../utils/ski-ticket-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import {translate} from '@elements/translations';
import Select from "../select";

export default function SkiTicketPricingSelection({
    title = "",
    id = "",
    selectedDate = "",
    selectedToDate = "",
    selectedProductChoice = "",
    ticketCounter = {},
    selectedSector = "",
    onChangeTicketCounter = noop(),
    onChangeProductChoice = noop(),
    vipPasses = []
}) {

    const PricingItemType = object({
        id: string(),
        price: number(),
        info: optional(string()),
        title: optional(string()),
        subtitle: optional(string()),
        basePrice: optional(number()),
        discountText: optional(string()),
        availability: optional(number())
    });

    let params = {
        ticket_id: id,
        ...(selectedDate && {startDate: selectedDate}),
        ...(vipPasses && {vipPasses: vipPasses}),
        ...(selectedToDate && {endDate: selectedToDate}),
        ...(selectedSector && { sector: selectedSector })
    }

    let {pricingConfigurations, isLoading, error} = usePricingConfigurations(params);

    function getProductChoiceOptions() {
        let options = [];
        
        pricingConfigurations.productChoices.map(function (productChoice) {
            options.push(
                {
                    "label": productChoice.title,
                    "value": productChoice.id,
                    "selected": productChoice.id === selectedProductChoice
                }
            );
        });

        return options;
    }

    function getPriceGroupsOfSelectedProductChoice() {
        let priceGroups = [];
        pricingConfigurations.productChoices.map(function (productChoice) {
            if(productChoice.id === selectedProductChoice) {
                priceGroups =  productChoice.priceGroups;
            }
        });

        return priceGroups;
    }

    function getDescriptionOfSelectedProductChoice() {
        let description = "";

        pricingConfigurations.productChoices.map(function (productChoice) {
            if(productChoice.id === selectedProductChoice) {
                description = productChoice.description;
            }
        });

        return description;
    }
    
    function prefillDefaultSelectedProductChoice() {
        if(!selectedProductChoice && pricingConfigurations.productChoices) {
            try {
                onChangeProductChoice(pricingConfigurations.productChoices[0].id);
            } catch(error) {
                console.warn(error);
                let errorText = `<p><strong>prefillDefaultSelectedProductChoice()</strong>:</p><p>Id of first productChoices[0] not defined or not valid!</p>`;
                throwAlertMessage('danger', 'ERROR', errorText, true);
            }
        }
    }

    useEffect(function() {
        if(pricingConfigurations) {
            prefillDefaultSelectedProductChoice();
        }
    }, [pricingConfigurations])

    return (
        <Fragment>
            {isLoading ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('ski-ticket.Loading-Error')}
                </div>
            ) : ""}

            {pricingConfigurations && pricingConfigurations.productChoices ? (
                <Fragment>
                    {pricingConfigurations.productChoices.length > 1 ? (
                        <div className="row mb-3">
                            <div className="col-md-3">
                                <Select
                                    labelModifierClass="ticket-configuration__headline"
                                    name="productChoice"
                                    id="productChoice"
                                    label={translate('ski-ticket.product-choice-for-ticket')}
                                    options={getProductChoiceOptions()}
                                    onChangeSelect={onChangeProductChoice}
                                />
                            </div>
                        </div>
                    ) : ""}
                    {selectedProductChoice ? (
                        <div className="row microanimations--fade microanimations--first-fold" style="--microanimations-stagger-factor: 3">
                            <div className="col-md-8">
                                <PricingList
                                    data={pricingConfigurations}
                                    ticketCounter={ticketCounter}
                                    title={title}
                                    description={getDescriptionOfSelectedProductChoice()}
                                    priceGroups={getPriceGroupsOfSelectedProductChoice()}
                                    onChangeTicketCounter={onChangeTicketCounter}
                                    PricingItemType={PricingItemType}
                                />
                            </div>

                            <div className="col-md-3 ms-auto">
                                <div className="row mt-3 mt-md-0">
                                    {getPriceGroupsOfSelectedProductChoice().map((item) => (
                                        <div className="col-3 col-md-5 mb-md-4 mb-3 " key={item.id}>
                                            {item.title}

                                            <span className="h2 text-primary d-block mt-2 microanimations--change-blur" key={item.id + ticketCounter[item.id] ? ticketCounter[item.id] : 0}>
                                                {ticketCounter[item.id] ? ticketCounter[item.id] : 0}
                                            </span>
                                        </div>
                                    ))}
                                </div>

                                <div className="">
                                    <div>
                                        {translate('ski-ticket.total')}
                                    </div>
                                    <span className="h2 text-primary mt-2 d-inline-block microanimations--change-blur" key={getSumOfObjectKeys(ticketCounter)}>
                                         {getSumOfObjectKeys(ticketCounter)}
                                    </span>
                                    <span className="strong microanimations--change-blur" key={'total' - getSumOfObjectKeys(ticketCounter)}>
                                        {translate('ski-ticket.persons')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ) : ""}
                </Fragment>
            ) : ""}

            {pricingConfigurations && pricingConfigurations.priceGroups ? (
                <div className="row">
                    <div className="col-md-8">
                        <PricingList
                            data={pricingConfigurations}
                            ticketCounter={ticketCounter}
                            title={title}
                            priceGroups={pricingConfigurations.priceGroups}
                            onChangeTicketCounter={onChangeTicketCounter}
                            PricingItemType={PricingItemType}
                        />
                    </div>

                    <div className="col-md-3 ms-auto">
                        <div className="row">
                            {pricingConfigurations.priceGroups.map((item) => (
                                <div className="col-5 mb-4 microanimations--change-blur" key={item.id}>
                                    {item.title}

                                    <span className="h2 text-primary d-block mt-2 microanimations--change-blur" key={item.id + [item.id] ? ticketCounter[item.id] : 0}>
                                        {ticketCounter[item.id] ? ticketCounter[item.id] : 0}
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="microanimations--change-blur" key={'total' - getSumOfObjectKeys(ticketCounter)}>
                            <div>
                                {translate('ski-ticket.total')}
                            </div>
                            <span className="h2 text-primary mt-2 d-inline-block">
                                 {getSumOfObjectKeys(ticketCounter)}
                            </span>
                            <span className="strong">
                                 {translate('ski-ticket.persons')}
                            </span>
                        </div>
                    </div>
                </div>
            ) : ""}
        </Fragment>
    )
}