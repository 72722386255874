/** @jsx h */

import {h, Fragment} from "preact";
import {noop} from "../utils/utils.js";
import {translate} from '@elements/translations';
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';

export default function Radio({
    value = "",
    name = "",
    id = "",
    label= "",
    onChangeValue = noop(),
    checked = false,
    available = 0,
    availableError = "",
    status = "",
    info = "",
    labelAffix = "",
    disabled= false
}) {

    return (
        <div className="custom-radio form-check">
            <label>
                <input className="custom-radio__input form-check-input" checked={checked} type="radio" value={value} name={name} id={id}
                   disabled={disabled}
                   onClick={(evt) => {
                       onChangeValue(evt.target.value);
                    }}/>
                <span className="custom-radio__box"/>
                <span className="custom-radio__text form-check-label">{label}
                    {info ? (
                        <OverlayTrigger
                            trigger="click"
                            key={`overlay-${id}`}
                            rootClose={true}
                            description={info}
                            overlay={
                                <Tooltip id={`tooltip-${id}`}>
                                    <div className="wysiwyg"
                                         dangerouslySetInnerHTML={{__html: info}}/>
                                </Tooltip>
                            }>
                            <Button className="btn-no-styling btn-info mx-1 btn-tooltip "><span
                                className="icon icon-info"/></Button>
                        </OverlayTrigger>
                    ): ""}

                    {labelAffix}

                    {available ? (
                        <Fragment>
                            {available > 0 ? (
                                <span className="d-block mt-1">
                            <span className={`me-2 status status--${status}`}/>
                                    {available} {translate('ticket.available')}
                            </span>
                                ) : (
                                    <span className="d-block mt-1">
                                 {translate('ticket.not-available')}
                            </span>
                                )}

                            {availableError}
                        </Fragment>
                    ) : "" }

                </span>
            </label>
        </div>
    );
}
