/** @jsx h */

import {h} from "preact";
import {noop} from "../utils/utils";

export default function ({
   fullsizeOverlay = true
}) {
    return (
        <div className={`loading-overlay ${fullsizeOverlay ? 'loading-overlay--shop-modal' : ''}`}>
            <div className="mt-4">
                <div className="loading-spinner" aria-label="Loading Data">
                    <div className="loading-spinner__item loading-spinner__item--1"/>
                    <div className="loading-spinner__item loading-spinner__item--2"/>
                    <div className="loading-spinner__item loading-spinner__item--3"/>
                </div>

                <div className="mt-3">
                    loading
                </div>
            </div>
        </div>
    );
}
