/** @jsx h */

import {h, Fragment} from "preact";
import {formatPrice, noop, throwAlertMessage} from "../utils/utils.js";
import {translate} from '@elements/translations';
import NumberSpinner from "./number-spinner";
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';
import {assert} from 'superstruct';

export default function PricingList({
    data = {},
    ticketCounter = {},
    title = "",
    hideBoxClass = false,
    description = "",
    priceGroups = [],
    onChangeTicketCounter = noop(),
    PricingItemType = {}
}) {

    const isMobile = matchMedia('(max-width: 767px)').matches;
    const DEFAULT_MAX_TICKET_AMOUNT = 500;

    function getPriceOfItem(item) {
        let price = 0;

        try {
            if(ticketCounter[item.id]) {
                price = +item.price * +ticketCounter[item.id];
            }
        } catch (e) {
            throwAlertMessage('warning', 'PRICE Calculation failed - getPriceOfItem()', e, true);
        }

        return +price.toFixed(2);
    }

    function getPriceSum(items) {
        let sum = 0;

        try {
            items.map(function(item) {
                sum += getPriceOfItem(item);
            })
        } catch (e) {
            throwAlertMessage('warning', 'PRICE Calculation failed - getPriceSum()', e, true);
        }

        return +sum.toFixed(2);
    }

    function getTicketCount(items, currentItemId) {
        let counter = 0;

        try {
            items.map(function(item) {
                if(ticketCounter[item.id] && item.id !== currentItemId) {
                    counter += ticketCounter[item.id];
                }
            })
        } catch (e) {
            throwAlertMessage('warning', 'Ticket Count Calculation failed - getTicketCount()', e, true);
        }

        return +counter;
    }

    function getAgeGroupSum(items) {
        let sum = 0;

        try {
            items.map(function(item) {
                if(ticketCounter[item.id]) {
                    sum += ticketCounter[item.id];
                }
            })
        } catch (e) {
            throwAlertMessage('warning', 'AgeGroupSum Calculation failed - getAgeGroupSum()', e, true);
        }

        return sum;
    }

    function validateItem(item) {
        if (item) {
            try {
                //validating data
                if(PricingItemType) {
                    assert(item, PricingItemType);
                }
                return true;
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>pricing-list - validateItem()</strong>:</p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }

    function hasAvailabilityColumn (items) {
        let hasAvailability = false;

        items.map(item => {
           if((item.available && !hasAvailability) || (item.availableBoolean && !hasAvailability)) {
               hasAvailability = true;
           }
        });

        return hasAvailability;
    }

    function getNumberSpinnerMax (item) {
        if(data.available) {
            return data.available - getTicketCount(priceGroups, item.id);
        } else if(item.available) {
            return item.available;
        } else if(item.max) {
            return item.max;
        } else {
            return DEFAULT_MAX_TICKET_AMOUNT;
        }
    }

    return (
        <Fragment>
            { data ? (
                <div className={`microanimations--fade microanimations--first-fold ${hideBoxClass ? '' : 'ticket-configuration__box'}`} style="--microanimations-stagger-factor: 1">
                    { title || description || data.available ? (
                        <div className="ticket-configuration__box-header">
                            <div className="row">
                                <div className="col-md-8 ticket-configuration__box-title">
                                    {translate('ticket.choose-quantity-for-ticket')} {title}
                                    {description ? (
                                        <span> | {description}</span>
                                    ) : ""}
                                </div>
                                {data.available ? (
                                    <div className="col-md-4 ticket-configuration__availability">
                                        <span className={`me-2 status status--${data.status}`}/> {data.available} / {data.max} {translate('ticket.available')}
                                    </div>
                                ) : "" }
                            </div>
                        </div>
                    ) : ""}
                    <div className="ticket-configuration__box-body gy-gutter--10" role="table">
                        {!isMobile ? (
                            <div className="row gy-gutter__item" role="row">
                                {priceGroups.length > 0 ? (
                                    <div className={`${hasAvailabilityColumn(priceGroups) ? 'col-md-2' : 'col-md-3'}`} role="columnheader">
                                        {priceGroups.isAgeGroup ? (
                                            <div className="ticket-configuration__box-label">
                                                {translate('ticket.Category')}
                                            </div>
                                        ) : (
                                            <div className="ticket-configuration__box-label">
                                                {translate('ticket.Age-Group')}
                                            </div>
                                        )}
                                    </div>
                                ) : "" }

                                {hasAvailabilityColumn(priceGroups) ? (
                                    <div className="col-md-2" role="columnheader">
                                        <div className="ticket-configuration__box-label">
                                            {translate('ticket.availability')}
                                        </div>
                                    </div>
                                ) : ""}

                                <div className={priceGroups.length > 0 ? "col-md-3" : "col-md-6"} role="columnheader">
                                    <div className="ticket-configuration__box-label">
                                        {translate('ticket.select-quantity')}
                                    </div>
                                </div>
                                <div className="col-md-3" role="columnheader">
                                    <div className="ticket-configuration__box-label">
                                        {translate('ticket.price-per-unit')}
                                    </div>
                                </div>
                                <div className={`${hasAvailabilityColumn(priceGroups) ? 'col-md-2' : 'col-md-3'} text-end`} role="columnheader">
                                    <div className="ticket-configuration__box-label">
                                        {translate('ticket.total-price')}
                                    </div>
                                </div>
                            </div>

                        ) : "" }

                        {priceGroups.map((item) => (
                            <div className="ticket-configuration__box-item d-flex flex-column gy-gutter__item " key={item.id}>
                                {validateItem(item) ? (
                                    <div className="row  gy-gutter--10" role="row">
                                        {priceGroups.length > 0 ? (
                                            <div className={`${hasAvailabilityColumn(priceGroups) ? 'col-md-2 col-7' : 'col-md-3 col-7'}`} role="cell">
                                                {isMobile ? (
                                                    <Fragment>
                                                        {priceGroups.length > 0 ? (
                                                            <Fragment>
                                                                {priceGroups.isAgeGroup ? (
                                                                    <div className="ticket-configuration__box-label">
                                                                        {translate('ticket.Category')}
                                                                    </div>
                                                                ) : (
                                                                    <div className="ticket-configuration__box-label">
                                                                        {translate('ticket.Age-Group')}
                                                                    </div>
                                                                )}
                                                            </Fragment>
                                                        ) : "" }
                                                    </Fragment>
                                                ) : ""}
                                                {item.title} {item.subtitle}

                                                {item.info ? (
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        key={item.id}
                                                        rootClose={true}
                                                        description={item.info}
                                                        overlay={
                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                <div className="wysiwyg"
                                                                     dangerouslySetInnerHTML={{__html: item.info}}/>
                                                            </Tooltip>
                                                        }>
                                                        <Button className="btn-no-styling btn-info ms-2 btn-tooltip "><span
                                                            className="icon icon-info"/></Button>
                                                    </OverlayTrigger>
                                                ): ""}

                                                {priceGroups.length > 0 && item.available && isMobile ? (
                                                    <span className="d-block mt-1">
                                                        <span className={`me-2 status status--${item.status}`}/>
                                                      {item.available} {translate('ticket.available')}
                                                    </span>
                                                ) : "" }

                                                {priceGroups.length > 0 && typeof(item.availableBoolean) !== 'undefined' && isMobile ? (
                                                    <span className="d-block mt-1">
                                                        <span className={`me-2 status status--${item.status}`}/>
                                                        {item.availableBoolean ? translate('ticket.available') : translate('ticket.not-available') }
                                                    </span>
                                                ) : "" }
                                            </div>
                                        ) : ""}

                                        {priceGroups.length > 0 && item.available && !isMobile ? (
                                            <div role="cell" className="col-md-2">
                                                  <span className="d-block mt-1">
                                                        <span className={`me-2 status status--${item.status}`}/>
                                                        {item.available} {translate('ticket.available')}
                                                    </span>
                                            </div>
                                        ) : "" }

                                        {priceGroups.length > 0 && typeof(item.availableBoolean) !== 'undefined' && !isMobile ? (
                                            <div role="cell" className="col-md-2">
                                              <span className="d-block mt-1">
                                                    <span className={`me-2 status status--${item.status}`}/>
                                                  {item.availableBoolean ? translate('ticket.available') : translate('ticket.not-available') }
                                                </span>
                                            </div>
                                        ) : "" }

                                        <div role="cell" className={priceGroups.length > 0 ? "col-md-3 col-5" : "col-12 col-md-6"}>
                                            {isMobile ? (
                                                <div className="ticket-configuration__box-label text-end">
                                                    {translate('ticket.select-quantity')}
                                                </div>
                                            ) : ""}

                                            { item.availability !== 0 && (typeof(item.availableBoolean) === 'undefined' || item.availableBoolean) ? (
                                                <NumberSpinner
                                                    value={ticketCounter[item.id]}
                                                    maxValue={getNumberSpinnerMax(item)}
                                                    onChange={(value) => {
                                                        onChangeTicketCounter({...ticketCounter, [item.id]: value});
                                                    }}
                                                />
                                            ) : item.availability === 0 ? translate('ticket.variant-no-quota') : null}

                                        </div>

                                        <div role="cell" className="col-md-3 col-7">
                                            {isMobile ? (
                                                <div className="ticket-configuration__box-label">
                                                    {translate('ticket.price-per-unit')}
                                                </div>
                                            ) : ""}
                                            <div className="ticket-configuration__box-text">
                                                {formatPrice(item.price, _config.lang, 'currency', 'CHF', 'code')}

                                                {item.basePrice ? (
                                                    <span className="ms-2 ticket-configuration__red-base-price">
                                                        {formatPrice(item.basePrice, _config.lang, 'currency', 'CHF', 'code')}
                                                    </span>
                                                ): ""}

                                                {item.discountText ? (
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        key={item.id}
                                                        rootClose={true}
                                                        description={item.info}
                                                        overlay={
                                                            <Tooltip id={`tooltip-discount-${item.id}`}>
                                                                <div className="wysiwyg"
                                                                     dangerouslySetInnerHTML={{__html: item.discountText}}/>
                                                            </Tooltip>
                                                        }>
                                                        <Button className="ms-2 btn-no-styling ms-2 btn-tooltip "><span
                                                            className="icon icon-sale ticket-configuration__discount-badge"/></Button>
                                                    </OverlayTrigger>
                                                ): ""}
                                            </div>
                                        </div>
                                        <div role="cell" className={`${hasAvailabilityColumn(priceGroups) ? 'col-md-2 col-5' : 'col-md-3 col-5'} text-end`}>
                                            {isMobile ? (
                                                <div className="ticket-configuration__box-label text-end">
                                                    {translate('ticket.total-price')}
                                                </div>
                                            ) : ""}
                                            <div className="ticket-configuration__box-text microanimations--change-blur" key={formatPrice(getPriceOfItem(item), _config.lang, 'currency', 'CHF', 'code')}>
                                                {formatPrice(getPriceOfItem(item), _config.lang, 'currency', 'CHF', 'code')}
                                            </div>
                                        </div>
                                    </div>
                                ) : "" }
                            </div>

                        ))}
                    </div>
                    <div className="ticket-configuration__box-footer">
                        <div className="row gy-gutter--10">
                            <div className="col-md-3 col-5 order-2 order-md-0">
                                {translate('ticket.total')}
                            </div>
                            { data.isAgeGroup > 0 ? (
                                <div className="col-md-3 order-md-1 order-0">
                                    { getAgeGroupSum(priceGroups) > 1 ? (
                                        <Fragment>
                                            {getAgeGroupSum(priceGroups)} {translate('ticket.persons')}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {getAgeGroupSum(priceGroups)} {translate('ticket.persons')}
                                        </Fragment>
                                    )}
                                </div>
                            ) : ""}
                            <div className="col text-end ticket-configuration__box-sum order-md-2 order-2 microanimations--change-blur" key={formatPrice(getPriceSum(priceGroups), _config.lang, 'currency', 'CHF', 'code')}>
                                {formatPrice(getPriceSum(priceGroups), _config.lang, 'currency', 'CHF', 'code')}
                            </div>
                        </div>
                    </div>
                </div>
            ) : "" }
        </Fragment>
    )
}