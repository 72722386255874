"use strict";

import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('[data-track-pdf="true"]', function(baseElement) {
        on('click', function(){
            let range = document.createRange();
            range.selectNode(document.body);
            let documentFragment = range.createContextualFragment('<script src="https://secure.adnxs.com/px?id=1635034&seg=31896425&t=1" type="text/javascript"></script>');
            document.body.appendChild(documentFragment);
        }, baseElement)
    });
}
