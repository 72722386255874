/** @jsx h */

import {h, Fragment} from "preact";
import {useState, useEffect} from "preact/hooks";
import {
    noop,
    getLocaleMonthName,
    getLocaleNextMonthName,
    getLocaleDay,
    getLocaleWeekday,
    getLocaleYear,
    getRangeDays,
    addDays,
    getLocalePrevMonthName, getLocaleMonth, formatPrice, removeDays
} from "../../utils/utils.js";
import ReactDatepicker from "react-datepicker";
import {dateToISOString, localDateToUTCDate} from "@elements/date-utils";
import {translate} from '@elements/translations';
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';
import {useDateConfigurations} from "../../utils/ski-locker-configuration-hooks";
import LoadingSpinner from "../loading-spinner";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
registerLocale('de', de);
registerLocale('fr', fr);
setDefaultLocale('en');


export default function SkiLockerDatepickerSelection({
      id = "",
      selectedDate = "",
      selectedDateString =  new Date(),
      selectedToDate = null,
      selectedLocation = "",
      selectedToDateString = "",
      onChangeDates = noop(),
      selectedSeason= "",
      onSelectSeason = noop()
  }) {

    const isMobile = matchMedia('(max-width: 767px)').matches;

    const DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS = 62;
    let [preloadingStartDate, setPreloadingStartDate] = useState("");
    let [preloadingEndDate, setPreloadingEndDate] = useState("");
    let [includedDates, setIncludedDates] = useState([]);
    let [allAvailableDates, setAllAvailableDates] = useState({});

    let params = {
        locker_id: id,
        ...(selectedLocation && { location: selectedLocation }),
        ...(preloadingStartDate && preloadingEndDate && { startDate: preloadingStartDate}),
        ...(preloadingEndDate && preloadingStartDate && { endDate: preloadingEndDate}),
    }

    let {dateConfigurations, isLoading, error} = useDateConfigurations(params);
    let maxSelectableDays = dateConfigurations ? dateConfigurations.datepickerConfiguration.maxSelectableDays : 1;
    let minSelectableDays = dateConfigurations ? dateConfigurations.datepickerConfiguration.minSelectableDays : 1;

    function prefillDefaultIncludedDates() {
        let tempIncludedDates = [...includedDates] || [];
        Object.keys(dateConfigurations.availabilityByDate).map(function(availableDate) {
            if(dateConfigurations.datepickerConfiguration.maxSelectableDays && dateConfigurations.datepickerConfiguration.maxSelectableDays !== 1 && selectedDate && !selectedToDate) {
                let maxDate = dateToISOString(addDays(selectedDateString, dateConfigurations.datepickerConfiguration.maxSelectableDays), false);
                let availableDateString = dateConfigurations.availabilityByDate[availableDate].date;

                if(availableDateString >= selectedDate && availableDateString <= maxDate) {
                    tempIncludedDates.push(new Date(dateConfigurations.availabilityByDate[availableDate].date));
                }
            } else {
                tempIncludedDates.push(new Date(dateConfigurations.availabilityByDate[availableDate].date));
            }
        });

        setIncludedDates(tempIncludedDates);
    }

    useEffect(function() {
        if(dateConfigurations) {
            prefillDefaultIncludedDates();
            setAllAvailableDates({...allAvailableDates, ...dateConfigurations.availabilityByDate});
        }
    }, [dateConfigurations])

    //preload dates if date is already selected
    useEffect(function() {
        if(selectedDate && !preloadingStartDate && !preloadingEndDate && includedDates.length === 0) {
            let newStartDate = removeDays(new Date(), new Date().getDay() -1);
            let newEndDate = addDays(selectedDateString, DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS);

            setPreloadingStartDate(dateToISOString(localDateToUTCDate(newStartDate), false));
            setPreloadingEndDate(dateToISOString(localDateToUTCDate(newEndDate), false));
        }
    }, [selectedDate])

    function loadNextDates() {
        let newStartDate = addDays(includedDates[includedDates.length - 1], 1);
        let newEndDate = addDays(newStartDate, DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS);

        setPreloadingStartDate(dateToISOString(localDateToUTCDate(newStartDate), false));
        setPreloadingEndDate(dateToISOString(localDateToUTCDate(newEndDate), false));
    }

    //set arrow disabled if < current Month
    const setDisabled = (monthDate) => {
        let month = monthDate.toLocaleString(_config.localeDate, {
            month: "short",
            year: "numeric",
        })
        let currentMonth = new Date().toLocaleString(_config.localeDate, {
            month: "short",
            year: "numeric",
        })

        return month === currentMonth;
    }

    const renderDayContents = (day, date) => {
        let tooltipInfo = false;

        // if(dateConfigurations) {
        //     Object.keys(allAvailableDates).map(function(availableDate) {
        //         if (allAvailableDates[availableDate].date === dateToISOString(localDateToUTCDate(date),false) && allAvailableDates[availableDate].available !== 0) {
        //             tooltipInfo = <Fragment><span className={`me-1 status status--${allAvailableDates[availableDate].status}`}/> {allAvailableDates[availableDate].available}/{allAvailableDates[availableDate].max} {translate('ski-locker.available')} </Fragment>;
        //         }
        //     });
        // }

        return (
            <Fragment>
                {tooltipInfo ? (
                    <OverlayTrigger
                        trigger={isMobile ? 'click' : ['hover', 'focus']}
                        key={"datepicker-" + date.getTime()}
                        rootClose={true}
                        description={"datepicker-" + date.getTime()}
                        overlay={
                            <Tooltip id={`tooltip-price-tendency-${"datepicker-" + date.getTime()}`}>
                                {tooltipInfo}
                            </Tooltip>
                        }>
                        <Button className="btn-no-styling react-datepicker__btn-tooltip btn-tooltip">{day}</Button>
                    </OverlayTrigger>
                ) : (
                    <Fragment>
                        {day}
                    </Fragment>
                )}
            </Fragment>
        );
    };

    return (
        <Fragment>
            { isLoading ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('ski-locker.Loading-Error')}
                </div>
            ) : ""}
            { dateConfigurations ? (
                <div className="row microanimations--fade microanimations--first-fold" style="--microanimations-stagger-factor: 1">
                   <div className="col-xl-8 col-md-9">
                       { dateConfigurations.seasonTicketConfiguration ? (
                           <Fragment>
                               <label className="switch mb-3">
                                   <input type="checkbox" className="switch__input"
                                          checked={selectedSeason}
                                          onInput={evt => {
                                              onSelectSeason(evt.target.checked === true ? [dateConfigurations.seasonTicketConfiguration.id, dateConfigurations.seasonTicketConfiguration.title] : "");
                                              onChangeDates(["", ""]);
                                          }}
                                          id="switch"/>
                                   <span className="switch__switch-box" aria-hidden={true}/>
                                   <span className="switch__label">
                                       <span className="switch__label-title">
                                           {dateConfigurations.seasonTicketConfiguration.title}
                                       </span>

                                       {dateConfigurations.seasonTicketConfiguration.description ? (
                                           <OverlayTrigger
                                               trigger="click"
                                               key={`overlay-${id}`}
                                               rootClose={true}
                                               description={dateConfigurations.seasonTicketConfiguration.description}
                                               overlay={
                                                   <Tooltip id={`tooltip-${id}`}>
                                                       <div className="wysiwyg"
                                                            dangerouslySetInnerHTML={{__html: dateConfigurations.seasonTicketConfiguration.description}}/>
                                                   </Tooltip>
                                               }>
                                               <Button className="btn-no-styling ms-2 btn-info btn-tooltip mt-1"><span
                                                   className="icon icon-info"/></Button>
                                           </OverlayTrigger>
                                       ): ""}
                                   </span>
                               </label>
                           </Fragment>
                       ) : ""}

                       <div className={` ${selectedSeason ? 'react-datepicker-wrapper--disabled' : '' } react-datepicker--small`}>
                           <ReactDatepicker
                               locale={_config.localeDate}
                               calendarClassName="react-datepicker--small"
                               selected={selectedDateString ? new Date(selectedDateString) : includedDates[0]}
                               renderCustomHeader={({
                                                        monthDate,
                                                        customHeaderCount,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                    }) => (
                                   <div className={customHeaderCount === 0 ? 'text-start' : 'text-end'}>
                                       { customHeaderCount === 0 ? (
                                           <button  disabled={setDisabled(monthDate)} className="btn-no-styling react-datepicker__custom-header-arrow" onClick={() => {
                                               decreaseMonth();
                                               if(!isMobile) {
                                                   decreaseMonth();
                                               }
                                           }} >
                                               <span className="icon icon-arrow-dropdown icon-rotate-90"/>
                                           </button>
                                       ) : ""}

                                       {monthDate.toLocaleString(_config.localeDate, {
                                           month: "long",
                                           year: "numeric",
                                       })}

                                       { customHeaderCount === 1 || isMobile ? (
                                           <button className="btn-no-styling react-datepicker__custom-header-arrow" onClick={() => {
                                               loadNextDates();
                                               increaseMonth();
                                               if(!isMobile) {
                                                   increaseMonth();
                                               }
                                           }} >
                                               <span className="icon icon-arrow-dropdown icon-rotate-270"/>
                                           </button>
                                       ) : "" }
                                   </div>
                               )}
                               onChange={(dates) => {
                                   onChangeDates(dates);
                               }}
                               startDate={selectedDateString ? new Date(selectedDateString) :selectedDateString}
                               endDate={selectedToDateString ? new Date(selectedToDateString) : selectedToDateString}
                               selectsRange={maxSelectableDays > 1}
                               inline
                               includeDates={includedDates}
                               monthsShown={isMobile ? 1 : 2}
                               renderDayContents={renderDayContents}
                               shouldCloseOnSelect={false}
                               minDate={selectedDateString && !selectedToDateString ? addDays(selectedDateString, minSelectableDays - 1) : includedDates[0] }
                               maxDate={selectedDateString && !selectedToDateString ? addDays(selectedDateString, maxSelectableDays -1) : includedDates[includedDates.length] }
                           />
                       </div>

                   </div>
                    <div className="col-md-3 ms-auto">
                        { !selectedSeason ? (
                            <Fragment>
                                <div className="row mt-md-5 mt-3">
                                    <div className="col-6">
                                        {translate('ski-locker.From')}
                                        <div className="datepicker-selection row gx-gutter--10 microanimations--change-blur" key={selectedDateString && (getLocaleDay(selectedDateString) + "-" + getLocaleMonthName(selectedDateString) + "-" + getLocaleYear(selectedDateString))}>
                                            <div className="col-auto datepicker-selection__day">
                                                {getLocaleDay(selectedDateString) !== 'Invalid Date' ? (
                                                    getLocaleDay(selectedDateString)
                                                ) : "-"}
                                            </div>
                                            <div className="col">
                                            <span className="datepicker-selection__top">
                                                 {getLocaleMonthName(selectedDateString) !== 'Invalid Date' ? (
                                                     getLocaleMonthName(selectedDateString)
                                                 ) : "-"}
                                                <span className="ms-1">
                                                     {getLocaleYear(selectedDateString) !== 'Invalid Date' ? (
                                                         getLocaleYear(selectedDateString)
                                                     ) : ""}
                                                </span>
                                            </span>
                                                <span className="datepicker-selection__bottom">
                                                    {getLocaleWeekday(selectedDateString) !== 'Invalid Date' ? (
                                                        getLocaleWeekday(selectedDateString)
                                                    ) : "-"}
                                            </span>
                                            </div>
                                        </div>
                                    </div>


                                    {maxSelectableDays && maxSelectableDays > 1 ? (
                                        <div className="col-6">
                                            {translate('ski-locker.To')}
                                            <div className="datepicker-selection row gx-gutter--10 microanimations--change-blur" key={selectedToDateString && (getLocaleDay(selectedToDateString) + "-" + getLocaleMonthName(selectedToDateString) + "-" + getLocaleYear(selectedToDateString))}>
                                                <div className="col-auto datepicker-selection__day">
                                                    {selectedToDateString && getLocaleDay(selectedToDateString) !== 'Invalid Date' ? (
                                                        getLocaleDay(selectedToDateString)
                                                    ) : "-"}
                                                </div>
                                                <div className="col">
                                        <span className="datepicker-selection__top">
                                             {selectedToDateString && getLocaleMonthName(selectedToDateString) !== 'Invalid Date' ? (
                                                 getLocaleMonthName(selectedToDateString)
                                             ) : "-"}
                                            <span className="ms-1">
                                                 { selectedToDateString && getLocaleYear(selectedToDateString) !== 'Invalid Date' ? (
                                                     getLocaleYear(selectedToDateString)
                                                 ) : ""}
                                            </span>
                                        </span>
                                                    <span className="datepicker-selection__bottom">
                                                {selectedToDateString && getLocaleWeekday(selectedToDateString) !== 'Invalid Date' ? (
                                                    getLocaleWeekday(selectedToDateString)
                                                ) : "-"}
                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ""}
                                </div>

                                {maxSelectableDays && maxSelectableDays > 1 ? (
                                    <div className="mt-md-5">
                                        {translate('ski-locker.Days')}
                                        <div>
                                        <span className="datepicker-selection__day microanimations--change-blur" key={getRangeDays(selectedDateString, selectedToDateString)}>
                                             {/*    should be displayed in nights */}
                                             {getRangeDays(selectedDateString, selectedToDateString) > 0 && !isNaN(getRangeDays(selectedDateString, selectedToDateString)) ? (
                                                 getRangeDays(selectedDateString, selectedToDateString) - 1
                                             ) : "-"}
                                        </span>

                                            <span className="datepicker-selection__top d-inline-block ms-2">
                                            {/*    should be displayed in nights */}
                                            { +getRangeDays(selectedDateString, selectedToDateString) - 1 > 1 ? translate('ski-locker.Nights-selected') : translate('ski-locker.Night-selected')}
                                        </span>
                                        </div>
                                    </div>
                                ) : "" }
                            </Fragment>
                        ) : ""}

                        {dateConfigurations.seasonTicketConfiguration?.fromPrice && selectedSeason ? (
                            <div className="mt-md-5 mt-3">
                                {translate('ski-locker.Adult price')}
                                <div>
                                    {translate('ski-locker.from')}
                                    <span className="datepicker-selection__price px-2"
                                          dangerouslySetInnerHTML={{__html:formatPrice(dateConfigurations.seasonTicketConfiguration?.fromPrice, _config.lang, 'currency', 'CHF', 'code', "span class='fz-16'")}}>
                                    </span>

                                    {translate('ski-locker.per ticket')}
                                </div>
                            </div>
                        ) : ""}
                    </div>
                </div>
            ) : "" }
        </Fragment>
    )
}


