import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Site Stuff
 *
 * ********************************/

import * as lightbox from '@elements/lightbox';
lightbox.init({
    thumbnail:false
});

import * as lightboxDynamic from './scripts/lightbox-dynamic';
lightboxDynamic.init();

import * as floatingLabels from '@elements/floating-labels';
floatingLabels.init();

import * as reloadForm from '@elements/reload-form';
reloadForm.init();

import * as scrollingArea from './scripts/scrolling-area';
scrollingArea.init();

import * as playVideo from './scripts/play-video';
playVideo.init();

import * as fadeIn from './scripts/fade-in';
fadeIn.init();

import * as animationMoveUp from './scripts/animation-move-up';
animationMoveUp.init();

import * as affix from './scripts/affix';
affix.init();

import * as shariff from './scripts/shariff';
shariff.init();

import * as trackDownload from './scripts/track-download';
trackDownload.init();