/** @jsx h */

import {h, Fragment} from "preact";
import {useEffect} from "preact/hooks";
import {getSumOfObjectKeys, noop, throwAlertMessage} from "../../utils/utils.js";
import {assert, object, number, optional, string, define, array, enums, func, boolean, defaulted} from 'superstruct';
import PricingList from "../pricing-list";
import {usePricingConfigurations} from "../../utils/ski-locker-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import {translate} from '@elements/translations';
import Select from "../select";

export default function SkiLockerPricingSelection({
      title = "",
      id = "",selectedLocation = "",
      selectedDate = "",
      selectedToDate = "",
      selectedProductChoice = "",
      ticketCounter = {},
      selectedSeason = "",
      onChangeTicketCounter = noop(),
      onChangeProductChoice = noop()
  }) {

    const PricingItemType = object({
        id: string(),
        price: number(),
        info: optional(string()),
        title: optional(string()),
        subtitle: optional(string()),
        basePrice: optional(number()),
        discountText: optional(string()),
        max: number(),
        availableBoolean: boolean(),
        status: enums(['success','warning','danger']),
        availability: optional(number())
    });

    let params = {
        locker_id: id,
        location: selectedLocation,
        ...(selectedDate && {startDate: selectedDate}),
        ...(selectedToDate && {endDate: selectedToDate}),
        ...(selectedSeason && { season: selectedSeason })
    }

    let {pricingConfigurations, isLoading, error} = usePricingConfigurations(params);

    function getProductChoiceOptions() {
        let options = [];

        pricingConfigurations.productChoices.map(function (productChoice) {
            options.push(
                {
                    "label": productChoice.title,
                    "value": productChoice.id,
                    "selected": productChoice.id === selectedProductChoice
                }
            );
        });

        return options;
    }

    function getPriceGroupsOfSelectedProductChoice() {
        let priceGroups = [];
        pricingConfigurations.productChoices.map(function (productChoice) {
            if(productChoice.id === selectedProductChoice) {
                priceGroups =  productChoice.priceGroups;
            }
        });

        return priceGroups;
    }

    function getDescriptionOfSelectedProductChoice() {
        let description = "";

        pricingConfigurations.productChoices.map(function (productChoice) {
            if(productChoice.id === selectedProductChoice) {
                description = productChoice.description;
            }
        });

        return description;
    }

    function prefillDefaultSelectedProductChoice() {
        if(!selectedProductChoice && pricingConfigurations.productChoices) {
            try {
                onChangeProductChoice(pricingConfigurations.productChoices[0].id);
            } catch(error) {
                console.warn(error);
                let errorText = `<p><strong>prefillDefaultSelectedProductChoice()</strong>:</p><p>Id of first productChoices[0] not defined or not valid!</p>`;
                throwAlertMessage('danger', 'ERROR', errorText, true);
            }
        }
    }

    useEffect(function() {
        if(pricingConfigurations) {
            prefillDefaultSelectedProductChoice();
        }
    }, [pricingConfigurations])

    return (
        <Fragment>
            {isLoading ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('ski-locker.Loading-Error')}
                </div>
            ) : ""}


            {pricingConfigurations && pricingConfigurations.productChoices ? (
                <Fragment>
                    <div className="row mb-3">
                        <div className="col-md-3">
                            <Select
                                labelModifierClass="ticket-configuration__headline"
                                name="productChoice"
                                id="productChoice"
                                label={translate('ski-locker.product-choice-for-ticket')}
                                options={getProductChoiceOptions()}
                                onChangeSelect={onChangeProductChoice}
                            />
                        </div>
                    </div>
                    {selectedProductChoice ? (
                        <PricingList
                            data={pricingConfigurations}
                            ticketCounter={ticketCounter}
                            title={title}
                            description={getDescriptionOfSelectedProductChoice()}
                            priceGroups={getPriceGroupsOfSelectedProductChoice()}
                            onChangeTicketCounter={onChangeTicketCounter}
                            PricingItemType={PricingItemType}
                        />
                    ) : ""}
                </Fragment>
            ) : ""}

            {pricingConfigurations && pricingConfigurations.priceGroups ? (
                <PricingList
                    data={pricingConfigurations}
                    ticketCounter={ticketCounter}
                    title={title}
                    priceGroups={pricingConfigurations.priceGroups}
                    onChangeTicketCounter={onChangeTicketCounter}
                    PricingItemType={PricingItemType}
                />
            ) : ""}
        </Fragment>
    )
}