/** @jsx h */

import {h, Fragment} from "preact";
import {useEffect, useState} from "preact/hooks";
import {noop} from "../../utils/utils.js";
import 'whatwg-fetch';
import 'url-search-params-polyfill'; // Edge Polyfill
import {assert, object, number, optional, string, define, array, enums, func, boolean, defaulted} from 'superstruct';
import ServiceList from "../service-list";
import {usePricingConfigurations, useServiceConfigurations} from "../../utils/activity-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import {translate} from '@elements/translations';

export default function ActivityTicketServiceSelection({
   id = "",
   selectedDate = "",
   selectedTime = "",
   ticketCounter = {},
   selectedOptions = {},
   onChangeOptions = noop(),
}) {

    const ServiceItemType = object({
        id: string(),
        label: string(),
        info: optional(string()),
        type: enums(['radio', 'checkbox']),
        options: array()
    });

    const ServicePriceConfigurationType = object({
        options: array(),
        price: number(),
        info: optional(string()),
        basePrice: optional(number()),
        discountText: optional(string())
    });

    const ServiceOptionType = object({
        id: string(),
        label: string(),
        info: optional(string()),
        available: optional(number),
        status: enums(['success','warning','danger'])
    })

    let params = {
        activity_id: id,
        date: selectedDate,
        ...(selectedTime.start && { startTime: selectedTime.start }),
        ...(selectedTime.end && { endTime: selectedTime.end })
    }

    function getOption(item) {
        let option;
        serviceConfigurations.options.map(function(optionItem) {
            if(optionItem.id === item) {
                option = optionItem;
            }
        })
        return option;
    }

    function getAvailabilityOfOption(option, tempObj) {
        let selectedOptionCounter = 0;

        let maxAvailability = getOption(option).available;
        if(maxAvailability && maxAvailability > 0) {
            Object.keys(tempObj).map(function(priceGroup,key) {
                let tempArray = [...tempObj[priceGroup]];
                if(tempArray.includes(option)) {
                    selectedOptionCounter++;
                }
            });
        }

        return maxAvailability - selectedOptionCounter;
    }

    //prefill selected options -> first radio of radio-group(service) is selected
    function fillDefaultSelectedOptions() {
        let tempObj = {};

        if(selectedOptions && selectedOptions.constructor === Object) {

            //set first option by default
            Object.keys(ticketCounter).map(function(priceGroup,key) {
                [...Array(ticketCounter[priceGroup])].map(function(x, i) {
                    if(selectedOptions[priceGroup + "-" + i] === undefined || selectedOptions[priceGroup + "-" + i].length === 0) {
                        serviceConfigurations['servicesByPriceGroup'][priceGroup].map(function(service) {
                            let checkOptionIndex = 0;
                            service.options.map(function(option, optionIndex) {
                                if(optionIndex === checkOptionIndex && service.type === 'radio') {
                                    let tempOption = getOption(option);
                                    if(tempOption && tempOption.available) {
                                        if(tempOption.available > 0 && getAvailabilityOfOption(option, tempObj) > 0) {
                                            let tempArray = tempObj[priceGroup + "-" + i] || [];
                                            tempArray.push(option);
                                            tempObj = {...tempObj, [priceGroup + "-" + i]: tempArray};
                                        } else {
                                            checkOptionIndex++;
                                        }
                                    } else {
                                        let tempArray = tempObj[priceGroup + "-" + i] || [];
                                        tempArray.push(option);
                                        tempObj = {...tempObj,[priceGroup + "-" + i]: tempArray};
                                    }
                                }
                            });
                        });
                    }
                });
            });

            onChangeOptions({...selectedOptions, ...tempObj});
        }
    }

    let {serviceConfigurations, isLoading, errorService} = useServiceConfigurations(params);
    let {pricingConfigurations, isLoadingPricing, errorPricing} = usePricingConfigurations(params);

    useEffect(function() {
        if(serviceConfigurations) {
            fillDefaultSelectedOptions(serviceConfigurations);
        }
    }, [serviceConfigurations, ticketCounter])

    return (
        <Fragment>
            { isLoading || isLoadingPricing? (
                <LoadingSpinner/>
            ) : ""}
            { errorService || errorPricing ? (
                <div className="alert alert-danger">
                    {translate('activity-ticket.Loading-Error')}
                </div>
            ) : ""}
            { serviceConfigurations ? (
                <ServiceList
                    data={serviceConfigurations}
                    priceGroups={pricingConfigurations.priceGroups}
                    ticketCounter={ticketCounter}
                    selectedOptions={selectedOptions}
                    onChangeOptions={onChangeOptions}
                    ServiceItemType={ServiceItemType}
                    ServicePriceConfigurationType={ServicePriceConfigurationType}
                    ServiceOptionType={ServiceOptionType}
                />
            ) : "" }
        </Fragment>
    )
}

