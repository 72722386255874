/** @jsx h */

import {h, render, Fragment} from "preact";
import {useState, useEffect} from 'preact/hooks';
import {Modal} from 'react-bootstrap';
import {translate} from '@elements/translations';
import {
    getLocaleDateString,
    getTimeString,
    noop,
    getSumOfObjectKeys,
    addParamsToUrl,
    fetcher, throwAlertMessage, getPriceGroupTitle, getRangeDays, addDays, getLocaleDateStringShort
} from "../../utils/utils.js";
import StepNav from "../step-nav";
import LoadingSpinner from "../loading-spinner";
import SkiLockerHeader from "../ski-locker-config/ski-locker-header";
import SkiLockerLocationSelection from "../ski-locker-config/ski-locker-location-selection";
import SkiLockerFooterBar from "../ski-locker-config/ski-locker-footer-bar";
import {setCartCount} from "../../../load-cart";
import SkiLockerDatepickerSelection from "../ski-locker-config/ski-locker-datepicker-selection";
import SkiLockerPricingSelection from "../ski-locker-config/ski-locker-pricing-selection";
import SkiLockerSummary from "../ski-locker-config/ski-locker-summary";
import {useDateConfigurations, useLocationConfigurations} from "../../utils/ski-locker-configuration-hooks";
import {getSkiLockerBaseConfig} from "../../utils/config-helper";
import {dateToISOString, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import {usePricingConfigurations} from "../../utils/ski-ticket-configuration-hooks";


export default function SkiLockerModal({
    isLoading = false,
    isInEdit = false,
    id,
    currentStepDefault = 0,
    isModalOpen = false,
    title = "",
    onModalHide = noop(),
    firstView = "",
    dateDefault = "",
    dateStringDefault = "",
    dateToDefault = "",
    dateToStringDefault = "",
    locationDefault = "",
    locationStringObjectDefault = {},
    selectedRangeInDaysDefault = 0,
    ticketCounterDefault = {},
    selectedSeasonDefault = "",
    selectedSeasonStringDefault = "",
    productChoiceDefault = ""
}) {
    // States
    const [currentStep, setCurrentStep] = useState(currentStepDefault); // 0 is initial state
    const [selectedDate, setSelectedDate] = useState(dateDefault);
    const [selectedDateString, setSelectedDateString] = useState(dateStringDefault);
    const [selectedToDate, setSelectedToDate] = useState(dateToDefault);
    const [selectedToDateString, setSelectedToDateString] = useState(dateToStringDefault);
    const [selectedLocation, setSelectedLocation] = useState(locationDefault);
    const [selectedLocationString, setSelectedLocationString] = useState(locationStringObjectDefault);
    const [ticketCounter, setTicketCounter] = useState(ticketCounterDefault);
    const [configurationSuccess, setConfigurationSuccess] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState(selectedSeasonDefault);
    const [selectedSeasonString, setSelectedSeasonString] = useState(selectedSeasonStringDefault);
    const [selectedProductChoice, setSelectedProductChoice] = useState(productChoiceDefault);
    const [addToCartLoading, setAddToCartLoading] = useState(false);

    useEffect(function() {
        if(dateStringDefault) {
            setSelectedDateString(UTCDateToLocalDate(new Date(dateStringDefault)));
        }
    }, [dateStringDefault])

    useEffect(function() {
        if(locationDefault) {
            setSelectedLocation(locationDefault);
        }
    }, [dateStringDefault])

    useEffect(function() {
        if(dateDefault) {
            setSelectedDate(dateDefault);
        }
    }, [dateStringDefault])

    useEffect(function() {
        if(dateToStringDefault) {setSelectedToDateString(UTCDateToLocalDate(new Date(dateToStringDefault)));}
    }, [dateToStringDefault])

    useEffect(function() {
        if(dateToDefault) {setSelectedToDate(dateToDefault);}
    }, [dateToDefault])

    // Render
    if (isLoading) {
        return (
            <LoadingSpinner/>
        );
    }


    const resetValues = () => {
        setCurrentStep(currentStepDefault);
        setSelectedDate(dateDefault);
        setSelectedDateString(dateStringDefault);
        setSelectedToDateString(dateToStringDefault);
        setSelectedToDate(dateToDefault)
        setTicketCounter(ticketCounterDefault);
        setConfigurationSuccess(false);
        setSelectedLocation(locationDefault);
        setSelectedLocationString(locationStringObjectDefault);
        setSelectedSeason(selectedSeasonDefault);
        setSelectedSeasonString(selectedSeasonStringDefault);
        setSelectedProductChoice(productChoiceDefault);
        setAddToCartLoading(false);
    }

    //calculate how many steps should be displayed
    function calculateSteps() {
        let steps = 4;
        if(firstView === 'datepicker') {steps-=2;}
        return steps;
    }


    function getBreadcrumbButton(text, changeToStep, hasLightFont = true, title = false) {
        return (
            <button
                onClick={() => {
                    setCurrentStep(+changeToStep);
                }}
                className={`btn btn-no-styling ticket-configuration__edit ${hasLightFont ? 'ticket-configuration__edit--light' : ''}`}>
                { title ? (
                    <strong className="me-1">{title}</strong>
                ) : ""}
                {text}
                <span aria-label="edit" className="icon icon-pen-outline ticket-configuration__edit-icon"/>
            </button>
        )
    }

    function getStepView() {
        let views = [];

        views.push(firstView);
        if(firstView !== 'datepicker') {
            views.push('datepicker');
        }
        if(firstView !== 'pricing-list') {
            views.push('pricing-list');
        }

        return views;
    }

    function getBreadcrumb() {
        let views = getStepView();
        let breadcrumb = [];


        // TODO setSelecteddLoaction + setSelectedSeasonString WEG! in function auslagern
        if(Object.keys(selectedLocationString).length === 0 && selectedLocationString.constructor === Object && selectedLocation) {
            let params = {
                locker_id: id
            }
            let {locationConfigurations} = useLocationConfigurations(params);
            try {
                if(locationConfigurations) {
                    setSelectedLocationString({"title": locationConfigurations.availabilityByLocation[selectedLocation].title, "subtitle": locationConfigurations.availabilityByLocation[selectedLocation].subtitle});
                }
            } catch {
                console.warn("breadcrumb, setSelectedLocationString could not be set");
            }
        }

        if(!selectedSeasonString && selectedSeason) {
            let params = {
                locker_id: id,
                ...(selectedLocation && { location: selectedLocation })
            }

            let {dateConfigurations} = useDateConfigurations(params);
            try {
                if(dateConfigurations) {
                    setSelectedSeasonString(dateConfigurations.seasonTicketConfiguration.title);
                }
            } catch {
                console.warn("breadcrumb, setSelectedSeasonString could not be set");
            }
        }

        if(currentStep > 0) {
            for (let i = 0; i < currentStep; i++) {
                let view = views[i];

                if(view === 'location-list') {
                    breadcrumb.push(getBreadcrumbButton(selectedLocationString.subtitle, i, true, selectedLocationString.title));
                } else if(view === 'datepicker') {
                    if(!selectedSeason) {
                        let dateString = getLocaleDateStringShort(selectedDateString);
                        if(selectedToDateString) {
                            dateString += (' - ' + getLocaleDateStringShort(selectedToDateString));
                        }
                        breadcrumb.push(getBreadcrumbButton(dateString, i));
                    } else {
                        breadcrumb.push(getBreadcrumbButton(selectedSeasonString, i));
                    }
                } else if(view === 'pricing-list') {
                    breadcrumb.push(getBreadcrumbButton(getSumOfObjectKeys(ticketCounter) + " " + translate('ski-locker.tickets'), i));
                }
            }
        }
        return breadcrumb;
    }

    const submitConfiguration = () => {
        setAddToCartLoading(true);
        let configurationData = {
            locker_id: id,
            location: selectedLocation,
            startDate: selectedDate ? selectedDate : '0000-00-00',
            endDate: selectedToDate ? selectedToDate : '0000-00-00',
            season: selectedSeason,
            ticketCounter: ticketCounter
        };

        let url = getSkiLockerBaseConfig().addToCart;

        let request = fetch(url, {
            method: 'POST',
            body: JSON.stringify(configurationData),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        request.then(result => result.json()).then(result => {
            setCurrentStep(currentStep +1);
            if(result.success) {
                setAddToCartLoading(false);
                setConfigurationSuccess(true);
                if(result.itemsInCart) {
                    setCartCount(result.itemsInCart);
                }
                if (result['__trackingScript']) {
                    try {
                        eval(result['__trackingScript']);
                    } catch(e) {console.error(e)}
                }
            } else {
                setAddToCartLoading(false);
                showNotification(request);
            }
        }).catch(e => {
            setAddToCartLoading(false);
            let errorText = `<p><strong>ski-locker-modal</strong>:add-to-cart failed!</p><p>${id}</p>`;
            throwAlertMessage('danger', 'add-to-cart', errorText, true);
        });


    }

    return (
        <Modal
            dialogClassName="modal-dialog modal-fullscreen modal-light-grey"
            show={isModalOpen} onHide={onModalHide}>

            <div className="ticket-configuration__header">
                <a href="/" className="pointer-events-auto ticket-configuration__brand">
                    <img width="238" height="100" alt="" src="/static/img/logos/verbier-4-vallees.svg"/>
                </a>

                <button type="button" className="close modal__close"
                        onClick={() => {
                            onModalHide();
                            resetValues();
                        }}>
                    <span aria-hidden="true" className="icon icon-close"/>
                    <span className="modal__close-text">close</span>
                </button>

                <StepNav max={calculateSteps()} current={currentStep} onStepClick={setCurrentStep} stepViews={getStepView()}/>
            </div>


            <div className="modal-content__inner">

                <div className={`ticket-configuration ${getStepView()[currentStep] !== 'datepicker' ? '' : 'ticket-configuration--wide'}`}>

                    { getStepView()[currentStep] === 'location-list' ? (
                        <Fragment>
                            <SkiLockerHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('ski-locker.datepicker.choose-your-location')}
                                subtitle={getBreadcrumb()}
                            />

                            <SkiLockerLocationSelection
                                selectedLocationId={selectedLocation}
                                onChangeLocation={(locationId, locationString) => {
                                    setSelectedLocation(locationId);
                                    setSelectedLocationString(locationString);
                                }}
                                id={id}
                            />
                        </Fragment>
                    ) : ""}


                    { getStepView()[currentStep] === 'datepicker' ? (
                        <Fragment>
                            <SkiLockerHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('ski-locker.datepicker.choose-date')}
                                subtitle={getBreadcrumb()}
                            />

                            <SkiLockerDatepickerSelection
                                id={id}
                                selectedDate={selectedDate}
                                selectedDateString={selectedDateString}
                                selectedToDate={selectedToDate}
                                selectedLocation={selectedLocation}
                                selectedToDateString={selectedToDateString}
                                selectedSeason={selectedSeason}
                                onSelectSeason={(season) => {
                                    setSelectedSeason(season[0]);
                                    setSelectedSeasonString(season[1])
                                }}
                                onChangeDates={(dates) => {
                                    if(Array.isArray(dates)) {
                                        const [start, end] = dates;
                                        setSelectedDate(dateToISOString(localDateToUTCDate(start), false));
                                        setSelectedDateString(start);
                                        setSelectedToDate(dateToISOString(localDateToUTCDate(end), false));
                                        setSelectedToDateString(end);
                                    } else {
                                        setSelectedDateString(dates);
                                        setSelectedDate(dateToISOString(localDateToUTCDate(dates), false))
                                    }
                                }}
                            />
                        </Fragment>
                    ) : ""}


                    { getStepView()[currentStep] === 'pricing-list' ? (
                        <Fragment>
                            <SkiLockerHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('ski-locker.date-time-list.choose-quantity')}
                                subtitle={getBreadcrumb()}
                            />


                            <SkiLockerPricingSelection
                                title={title}
                                selectedDate={selectedDate}
                                selectedToDate={selectedToDate}
                                ticketCounter={ticketCounter}
                                selectedSeason={selectedSeason}
                                selectedProductChoice={selectedProductChoice}
                                onChangeProductChoice={(item) => {
                                    setSelectedProductChoice(item)
                                }}
                                onChangeTicketCounter={(item) => {
                                    setTicketCounter(item)
                                }}
                                id={id}
                                selectedLocation={selectedLocation}

                            />

                        </Fragment>
                    ) : ""}

                    { currentStep === calculateSteps() -1 ? (

                        <Fragment>
                            { addToCartLoading ?  <LoadingSpinner/> : "" }

                            <SkiLockerSummary
                                id={id}
                                isInEdit={isInEdit}
                                title={title}
                                selectedDate={selectedDateString}
                                selectedToDate={selectedToDateString}
                                selectedSeasonString={selectedSeasonString}
                                selectedLocationString={selectedLocationString}
                                ticketCounter={ticketCounter}
                                configurationSuccess={configurationSuccess}
                                selectedLocation={selectedLocation}
                                selectedSeason={selectedSeason}
                                selectedProductChoice={selectedProductChoice}
                            />
                        </Fragment>
                    ) : ""}
                </div>
            </div>

            {calculateSteps() -1 !== currentStep ? (
                <SkiLockerFooterBar
                    isInEdit={isInEdit}
                    currentStep={currentStep}
                    onChangeStep={setCurrentStep}
                    onSubmit={submitConfiguration}
                    stepViews={getStepView()}
                    maxSteps={calculateSteps()}
                    selectedSeason={selectedSeason}
                    selectedLocation={selectedLocation}
                    selectedDate={selectedDateString}
                    selectedToDate={selectedToDateString}
                    ticketCounter={ticketCounter}
                />
            ) : ""}
        </Modal>
    )
}