/** @jsx h */

import {h} from "preact";
import {getSumOfObjectKeys, noop} from "../../utils/utils";
import {translate} from '@elements/translations';

export default function ActivityTicketFooterBar({
     isInEdit = false,
     currentStep = 0,
     onChangeStep = noop(),
     onSubmit = noop(),
     ticketCounter = {},
     selectedOptions = {},
     selectedDateId = "",
     selectedTimeId = "",
     stepViews = [],
     maxSteps = 0

 }) {
    return (
        <div className="ticket-configuration__footer">
            <div className="container-xx-large h-100">
                <div className="row h-100">
                    {currentStep > 0 ? (
                        <div className="col-4 d-flex">
                            <button className="btn btn-no-styling"
                                    onClick={() => {
                                        onChangeStep(currentStep - 1);
                                    }}>
                                <span className="icon icon-arrow-long me-1" aria-hidden={true}/>
                                {translate(`activity-ticket.back`)}
                            </button>
                        </div>
                    ) : ""}
                    <div className="col-8 ms-auto d-flex justify-content-end align-items-center">
                        {currentStep < maxSteps - 2 ? (
                            <button className="btn btn-primary ticket-configuration__footer-btn"
                                    disabled={
                                        (stepViews[currentStep] === 'date-list' && !selectedDateId) ||
                                        (stepViews[currentStep] === 'datepicker' && !selectedDateId) ||
                                        (stepViews[currentStep] === 'time-list' && !selectedTimeId) ||
                                        (stepViews[currentStep] === 'date-time-list' && !selectedTimeId && !selectedDateId) ||
                                        (stepViews[currentStep] === 'pricing-list' && getSumOfObjectKeys(ticketCounter) === 0)
                                    }
                                    onClick={() => {
                                        onChangeStep(currentStep + 1);
                                    }}>
                                {translate(`activity-ticket.continue`)}
                            </button>
                        ) : (
                            <button className="btn btn-primary ticket-configuration__footer-btn"
                                    disabled={
                                        (stepViews[currentStep] === 'pricing-list' && getSumOfObjectKeys(ticketCounter) === 0) ||
                                        (stepViews[currentStep] === 'service-list' && selectedOptions.length === 0)
                                    }
                                    onClick={() => {
                                        onSubmit()
                                    }}>
                                {isInEdit ? translate(`activity-ticket.update-ticket`) : translate(`activity-ticket.add-to-cart`) }
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}