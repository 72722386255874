import {getSkiTicketBaseConfig} from "./config-helper";
import {addParamsToUrl, fetcher, throwAlertMessage} from "./utils";
import {useEffect, useState} from "preact/hooks";
import {assert, object, optional, integer, string, array, boolean, number, enums} from 'superstruct';
import useSWR from 'preact-swr';
import {setCartCount} from "../../load-cart";

export function useServiceConfigurations(params){
    const ServiceConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        options: array(object(
            {
                id: string(),
                label: string(),
                labelSubText: optional(string()),
                info: optional(string())
            }
        )),
        servicesByPriceGroup: object(),
        priceConfigurationsByPriceGroup: object()
    });

    let url = getSkiTicketBaseConfig().services;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [serviceConfigurations, setServiceConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, ServiceConfigurationType);
                setServiceConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useServiceConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        serviceConfigurations: serviceConfigurations,
        isLoading: !serviceConfigurations && !error,
        error: error
    }
}

export function useSectorConfigurations(params){
    const SectorConfigurationType = object({
        success: boolean(),
        sectors: object()
    });

    let url = getSkiTicketBaseConfig().sectorList;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [sectorConfigurations, setSectorConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, SectorConfigurationType);
                setSectorConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useSectorConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        sectorConfigurations: sectorConfigurations,
        isLoading: !sectorConfigurations && !error,
        error: error
    }
}


export function useDateConfigurations(params){
    const DateConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        fromPrice: optional(number()),
        datepickerConfiguration:object({
            minSelectableDays:integer(),
            maxSelectableDays:integer(),
            seasons: optional(object()),
            specials: optional(object())
        }),
        availabilityByDate: object(),
        vipPassInfo: optional(string())
    });

    let url = getSkiTicketBaseConfig().datepicker;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [dateConfigurations, setDateConfigurations] = useState(null);

    let {pricingConfigurations, isLoadingPricing, errorPricing} = usePricingConfigurations(params);

    useEffect(function() {
        if (data) {
            console.log(data);
            try {
                //validating data
                assert(data, DateConfigurationType);
                setDateConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useDateConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        dateConfigurations: dateConfigurations,
        isLoading: !dateConfigurations && !error,
        error: error
    }
}

export function usePricingSliderConfigurations(params){
    const PricingSliderType = object({
        comment: optional(string()),
        success: boolean(),
        priceByDate: object()
    });

    let url = getSkiTicketBaseConfig().pricingSlider;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [pricingSliderConfigurations, setPricingSliderConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, PricingSliderType);
                setPricingSliderConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>usePricingSliderConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        pricingSliderConfigurations: pricingSliderConfigurations,
        isLoading: !pricingSliderConfigurations && !error,
        error: error
    }
}

export function usePricingConfigurations(params){
    const PricingConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        available: optional(number()),
        max: optional(number()),
        status: optional(number()),
        isAgeGroup: boolean(),
        priceGroups: optional(array()),
        productChoices: optional(object())
    });

    let url = getSkiTicketBaseConfig().pricing;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [pricingConfigurations, setPricingConfigurations] = useState(null);


    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, PricingConfigurationType);
                setPricingConfigurations(data);

            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>usePricingConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        pricingConfigurations: pricingConfigurations,
        isLoading: !pricingConfigurations && !error,
        error: error
    }
}