/** @jsx h */

import {h} from "preact";
import {noop} from "../utils/utils.js";
import {translate} from '@elements/translations';

export default function StepNav({
    hasPreviousError = false,
    current = 0,
    max,
    onStepClick = noop(),
    stepViews = []
}) {
    let steps = Array(max).fill(0);
    let mobile = false;

    // Helper functions
    const toggleIsPreviousText = index => {
        if (current > index && hasPreviousError) {
            return <span className="icon icon-close"></span>
        } else if (current > index || index === max-1) {
            return <span className="icon icon-check"></span>
        } else {
            return "";
        }
    };

    const generateStepNavTitle = index => {
        if (matchMedia('(max-width: 767px)').matches) {
            return index+1
        } else if ( index === max-1) {
            return translate("tickets.step-nav.summary")
        } else {
            return translate("tickets.step-nav." + stepViews[index]);
        }
    };

    return (
        <ol className={`step-nav list-unstyled  ${hasPreviousError ? 'has-error' : ''}`}>
            {steps.map((x, index) => (
                <li key={index} className={`step-nav__item ${index === current ? 'active' : ''} ${current > index ? 'is-previous' : ''}`}>
                    <button className={`step-nav__counter btn-no-styling`}
                            disabled={index > current || current === max -1}
                            type="button"
                            onClick={() => onStepClick(index)}>

                        { toggleIsPreviousText(index) }
                    </button>
                    <span className={`step-nav__btn-title-wrapper h5`}> <div class={'text-center step-nav__btn-title'}>{`${generateStepNavTitle(index) }`}</div></span>
                </li>
            ))}
        </ol>
    )
}