"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAll, find} from "@elements/dom-utils";
import asyncAppend from "@elements/async-append";

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-profile-overlay',
    target: '.js-profile-overlay__target',
    loading: '.js-profile-overlay__loading',
    notifications: '.js-profile-overlay__notifications'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createOverlay(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    },document);
}

export function createOverlay(base, options, selectors) {
    let url = _config.profileOverlay;

    on('click', function(){
        if(base.classList.contains('is-open')){
            asyncAppend({
                target: findAll(selectors.target),
                loading: findAll(selectors.loading),
                notifications: findAll(selectors.notifications)
            }, fetch(url)).then((result) => {
                if(result.newsItemCount){
                    setNotificationCount(result.newsItemCount);
                }
            });
        }
    }, base);
}

export function setNotificationCount(count) {
    let profileNotificationCount = find('.js-profile-overlay__count');
    if(profileNotificationCount) {
        profileNotificationCount.innerHTML = count;
    } else {
        console.warn("ERROR: .js-profile-overlay__count not found!");
    }
}