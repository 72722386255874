import {getSkiLockerBaseConfig, getSkiTicketBaseConfig} from "./config-helper";
import {addParamsToUrl, fetcher, throwAlertMessage} from "./utils";
import {assert, object, optional, integer,string, array, boolean, number, enums} from 'superstruct';
import {useEffect, useState} from "preact/hooks";
import useSWR from 'preact-swr';

export function useLocationConfigurations(params){
    const LocationConfigurationType = object({
        success: boolean(),
        availabilityByLocation: object()
    });

    let url = getSkiLockerBaseConfig().locations;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [locationConfigurations, setLocationConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, LocationConfigurationType);
                setLocationConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useLocationConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        locationConfigurations: locationConfigurations,
        isLoading: !locationConfigurations && !error,
        error: error
    }
}


export function useDateConfigurations(params){
    const DateConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        datepickerConfiguration:object({
            minSelectableDays:integer(),
            maxSelectableDays:integer()
        }),
        availabilityByDate: object(),
        seasonTicketConfiguration: object()
    });

    let url = getSkiLockerBaseConfig().datepicker;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [dateConfigurations, setDateConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            console.log(data);
            try {
                //validating data
                assert(data, DateConfigurationType);
                setDateConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useDateConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        dateConfigurations: dateConfigurations,
        isLoading: !dateConfigurations && !error,
        error: error
    }
}

export function usePricingConfigurations(params){
    const PricingConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        priceGroups: optional(array()),
        productChoices: optional(object())
    });

    let url = getSkiLockerBaseConfig().pricing;
    url = addParamsToUrl(url, new URLSearchParams(params));;
    let {data, error} = useSWR(url, fetcher);
    let [pricingConfigurations, setPricingConfigurations] = useState(null);


    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, PricingConfigurationType);
                setPricingConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>usePricingConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        pricingConfigurations: pricingConfigurations,
        isLoading: !pricingConfigurations && !error,
        error: error
    }
}