/** @jsx h */

import {h, Fragment} from "preact";
import {
    noop,
    getSumOfObjectKeys,
    throwAlertMessage,
    getPriceGroupTitle,
    getLocaleDateStringShort,
    formatPrice
} from "../utils/utils.js";
import {translate} from '@elements/translations';
import {dateToISOString, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";

export default function PricingSummary({
    serviceConfigurations={},
    selectedSectorTitle="",
    selectedDate= "",
    selectedToDate="",
    selectedRangeInDays=0,
    ticketCounter={},
    selectedProductChoice="",
    options = {},
    selectedOptions={},
    priceGroups = {}
}) {


    function getSelectedOptionsForPriceGroup(priceGroup, index) {
        let tempSelectedArray = [];
        if(Object.keys(selectedOptions).length > 0 && selectedOptions[priceGroup + "-" + index] !== undefined) {
            tempSelectedArray = [...selectedOptions[priceGroup + "-" + index]];
        }

        return tempSelectedArray;
    }

    function getPriceConfiguration(priceGroup, priceGroupIndex) {
        let configurations = serviceConfigurations.priceConfigurationsByPriceGroup[priceGroup];
        let optionsArray = getSelectedOptionsForPriceGroup(priceGroup, priceGroupIndex);
        let configuration;

        configurations.map(function(item) {
            if(item.options.every((e) => optionsArray.includes(e)) && optionsArray.every((e) => item.options.includes(e))) {
                configuration = item;
            }
        })
        if(!configuration){
            let errorText = `<h4>error getPriceConfiguration()</h4><p><strong>service-list - getPriceConfiguration()</strong>:</p><p>no matching configuration found for priceGroup <strong>${priceGroup}</strong> with selectedOptions <strong>${JSON.stringify(optionsArray)}</strong></p>`;
            throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
        }
        return configuration;
    }

    function getPriceOfItem(priceGroupId) {
        let price = 0;

        try {
            priceGroups.map(function(priceGroup) {
                if(priceGroup.id === priceGroupId) {
                    price += priceGroup.price;
                }
            });
        } catch (e) {
            throwAlertMessage('warning', 'PRICE Calculation failed - getPriceOfItem() pricing summary', e, true);
        }

        return +price.toFixed(2);
    }

    function getTotalBasePrice() {
        let total = 0;

        Object.keys(ticketCounter).map(function(priceGroup,key) {
            [...Array(ticketCounter[priceGroup])].map(function(x, i) {
                if(serviceConfigurations) {
                    if(getPriceConfiguration(priceGroup, i).basePrice) {
                        total += +getPriceConfiguration(priceGroup, i).basePrice;
                    } else {
                        total += +getPriceConfiguration(priceGroup, i).price;
                    }
                } else {
                    total += getPriceOfItem(priceGroup);
                }
            });
        })

        return total;
    }

    function getTotalPrice() {
        let total = 0;

        Object.keys(ticketCounter).map(function(priceGroup,key) {
            [...Array(ticketCounter[priceGroup])].map(function(x, i) {
                if(serviceConfigurations) {
                    total += +getPriceConfiguration(priceGroup, i).price;
                } else {
                    total += getPriceOfItem(priceGroup);
                }
            });
        })
        return total;
    }

    function getOptionName(optionId) {
        let optionName = "";
        options.map(function(option) {
            if(option.id === optionId) {
                optionName = option.label;
            }
        });

        return optionName;
    }

    return (
        <div className="cart-summary">
            <div className="cart-summary__title">
                {translate('ticket.our-best-offer')}
            </div>
            <div>
                {translate('ticket.total-price')}
            </div>
            <div>
                <span className="cart-summary__total--big microanimations--change-blur" key={formatPrice(getTotalPrice(), _config.lang, 'currency', 'CHF', 'code')}>
                    {formatPrice(getTotalPrice(), _config.lang, 'currency', 'CHF', 'code')}
                </span>
                {getTotalPrice() !== getTotalBasePrice() ? (
                    <span className="ticket-configuration__red-base-price ms-2 microanimations--change-blur" key={formatPrice(getTotalBasePrice(), _config.lang, 'currency', 'CHF', 'code')}>
                        {formatPrice(getTotalBasePrice(), _config.lang, 'currency', 'CHF', 'code')}
                    </span>
                ) : ""}
            </div>

            <div className="gy-gutter--6">
                <div className="text-uppercase strong gy-gutter__item">
                    {selectedSectorTitle}
                </div>

                { selectedDate ? (
                    <div className="gy-gutter__item">
                        {selectedRangeInDays > 1 ? translate('ticket.Days') : translate('ticket.Day')} -
                        {translate('ticket.from')} {getLocaleDateStringShort(UTCDateToLocalDate(new Date(selectedDate)))}
                        {selectedToDate ? (
                            <Fragment> {translate('ticket.to')} {getLocaleDateStringShort(UTCDateToLocalDate(new Date(selectedToDate)))}</Fragment>
                        ) : ""}
                    </div>
                ) : ""}

                <div className="text-uppercase strong gy-gutter__item">
                    {getSumOfObjectKeys(ticketCounter)} {translate('ticket.Persons')}
                </div>
            </div>


            <div className="mt-4">
                {Object.keys(ticketCounter).map((priceGroup,key) => (
                    <Fragment>
                        {[...Array(ticketCounter[priceGroup])].map((x, i) =>
                            <div className="cart-summary__item" key={priceGroup + '-' + i}>
                                <div className="row">
                                    <div className="col-md-7 gy-gutter--10">
                                        <div className="strong gy-gutter__item">
                                            <span className="icon icon-ticket fz-16 me-2" aria-hidden={true} />
                                            {getPriceGroupTitle(priceGroups, priceGroup)}
                                        </div>
                                        {Object.keys(selectedOptions).length > 0 ? (
                                            <Fragment>
                                                {selectedOptions[priceGroup + '-' + i].map((option, key) => (
                                                    <div className="gy-gutter__item" key={option}>
                                                        {translate('ticket.insurance')}: {getOptionName(option)}
                                                    </div>
                                                ))}
                                            </Fragment>
                                        ) : ""}
                                    </div>
                                    <div className="col-md-5 text-end">
                                        {translate('ticket.price')} <strong className="me-2 microanimations--change-blur" key={serviceConfigurations ? getPriceConfiguration(priceGroup, i).price : getPriceOfItem(priceGroup)}>
                                        {serviceConfigurations ? (
                                            <Fragment>
                                                {formatPrice(getPriceConfiguration(priceGroup, i).price, _config.lang, 'currency', 'CHF', 'code')}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                {formatPrice(getPriceOfItem(priceGroup), _config.lang, 'currency', 'CHF', 'code')}
                                            </Fragment>
                                        )}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                ))}
            </div>

        </div>
    )
}