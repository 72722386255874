"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, off, findAllIn, addClass} from "@elements/dom-utils";

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-sector-map',
    items: '.js-sector-map__item'
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init(options = defaultOptions, selectors = defaultSelectors) {
    
    if(isMobile()) {
        return;
    }

    onFind(selectors.base, function (baseElement) {
        createSectorMap(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    },document);
}

export function createSectorMap(base, options, selectors) {
    onFind(selectors.items, function (item) {
        const hoverHandler = evt => {
            findAllIn(selectors.items, base).map((item) => item.style.opacity = '0');
            item.style.opacity = '100';
        };

        item.style.opacity = '0';
        item.style.transition = 'opacity 200ms ease';

        on('mouseover', hoverHandler, item);

        return () => {
            off('mouseover', hoverHandler, item);
        }
    });
}