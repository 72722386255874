"use strict";

import {find, findAllIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-shariff', function(baseElement) {
        import('shariff').then(function () {
            new Shariff(baseElement, {});
        });
    });
}
