"use strict";

import {add} from '@elements/scroll-animations';
import {findAll} from "@elements/dom-utils";

export function init() {
    add(
        findAll('.js-animation-move-up'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = window.innerHeight / 2;

    return {
        transform: matchMedia('(max-width: 767px)').matches ?
            Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 30 + 'px'
            : Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),100),0) * 60 + 'px'
    };
}

function setAnimationProgress(element, progress) {
    switch (element.dataset.animationStart) {
        case 'top':
            element.style.transform = 'translate3d(0, -'+ progress.transform +', 0)';
            break;
        default:
            element.style.transform = 'translate3d(0, '+ progress.transform +', 0)';
    }
}