/** @jsx h */

import {h} from "preact";
import {noop} from "../utils/utils.js";
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';

export default function Checkbox({
  value = "",
  name = "",
  id = "",
  label= "",
  checked = false,
  onChangeValue = noop(),
  info = "",
  labelAffix = ""
}) {

    return (
        <div className="custom-checkbox form-check">
            <label>
                <input className="custom-checkbox__input form-check-input" checked={checked} type="checkbox" value={value} name={name} id={id}
                   onClick={(evt) => {
                       onChangeValue(evt.target.value);
                    }}/>
                <span className="custom-checkbox__box icon icon-open"/>
                <span className="custom-checkbox__text form-check-label">
                    {label}
                    {info ? (
                        <OverlayTrigger
                            trigger="click"
                            key={`overlay-${id}`}
                            rootClose={true}
                            description={info}
                            overlay={
                                <Tooltip id={`tooltip-${id}`}>
                                    <div className="wysiwyg"
                                         dangerouslySetInnerHTML={{__html: info}}/>
                                </Tooltip>
                            }>
                            <Button className="btn-no-styling btn-info mx-1 btn-tooltip  btn btn-primary "><span
                                className="icon icon-info ms-1"/></Button>
                        </OverlayTrigger>
                    ): ""}
                    {labelAffix}
                </span>
            </label>
        </div>
    );
}
