import {getActivityBaseConfig} from "./config-helper";
import {addParamsToUrl, fetcher, throwAlertMessage} from "./utils";
import {useEffect, useState} from "preact/hooks";
import {assert, object, optional, string, array, boolean, number, enums} from 'superstruct';
import useSWR from 'preact-swr';

export function useServiceConfigurations(params){
    const ServiceConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        options: array(object(
            {
                id: string(),
                label: string(),
                info: optional(string()),
                available: optional(number()),
                status: optional(enums(['success','warning','danger'])),
            }
        )),
        servicesByPriceGroup: object(),
        priceConfigurationsByPriceGroup: object()
    });

    let url = getActivityBaseConfig().services;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [serviceConfigurations, setServiceConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, ServiceConfigurationType);
                setServiceConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useServiceConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        serviceConfigurations: serviceConfigurations,
        isLoading: !serviceConfigurations && !error,
        error: error
    }
}

export function usePricingConfigurations(params){
    const PricingConfigurationType = object({
        comment: optional(string()),
        success:boolean(),
        available: optional(number()),
        max: optional(number()),
        isAgeGroup: boolean(),
        priceGroups: array(),
        status: optional(enums(['danger', 'success', 'warning']))
    });

    let url = getActivityBaseConfig().pricing;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [pricingConfigurations, setPricingConfigurations] = useState(null);


    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, PricingConfigurationType);
                setPricingConfigurations(data);

            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>usePricingConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        pricingConfigurations: pricingConfigurations,
        isLoading: !pricingConfigurations && !error,
        error: error
    }
}

export function useDateConfigurations(params){
    const DateConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        availabilityByDate: object()
    });

    let url = getActivityBaseConfig().dateList;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [dateConfigurations, setDateConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, DateConfigurationType);
                setDateConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useDateConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        dateConfigurations: dateConfigurations,
        isLoading: !dateConfigurations && !error,
        error: error
    }
}


export function useDateTimeConfigurations(params){
    const DateTimeConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        availabilityByDate: object()
    });

    let url = getActivityBaseConfig().dateTimeList;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [dateTimeConfigurations, setDateTimeConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, DateTimeConfigurationType);
                setDateTimeConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useDateTimeConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        dateTimeConfigurations: dateTimeConfigurations,
        isLoading: !dateTimeConfigurations && !error,
        error: error
    }
}

export function useTimeConfigurations(params){
    const TimeConfigurationType = object({
        comment: optional(string()),
        success: boolean(),
        availabilityByTime: object()
    });

    let url = getActivityBaseConfig().timeList;
    url = addParamsToUrl(url, new URLSearchParams(params));
    let {data, error} = useSWR(url, fetcher);
    let [timeConfigurations, setTimeConfigurations] = useState(null);

    useEffect(function() {
        if (data) {
            try {
                //validating data
                assert(data, TimeConfigurationType);
                setTimeConfigurations(data);
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>useTimeConfigurations()</strong>:<a class='small d-block' target='_blank' href='${url}'>${url}</a> </p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }, [data]);

    return {
        timeConfigurations: timeConfigurations,
        isLoading: !timeConfigurations && !error,
        error: error
    }
}