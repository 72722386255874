/** @jsx h */

import {h} from "preact";
import {getSumOfObjectKeys, noop} from "../../utils/utils";
import {translate} from '@elements/translations';

export default function SkiTicketFooterBar({
     isInEdit = false,
     currentStep = 0,
     onChangeStep = noop(),
     onSubmit = noop(),
     stepViews = [],
     maxSteps = 0,
     selectedDate = "",
     allVipPassesValid = false,
     ticketCounter = {},
     vipPasses = []
 }) {


    return (
        <div className="ticket-configuration__footer ab-mobile-configurator__footer">
            <div className="container-xx-large h-100">
                <div className="row h-100">
                    {currentStep > 0 ? (
                        <div className="col-4 d-flex">
                            <button className="btn btn-no-styling"
                                    onClick={() => {
                                        onChangeStep(currentStep - 1);
                                    }}>
                                <span className="icon icon-arrow-long me-1" aria-hidden={true}/>
                                {translate(`ski-ticket.back`)}
                            </button>
                        </div>
                    ) : ""}
                    <div className="col-8 ms-auto d-flex justify-content-end align-items-center">
                        {currentStep < maxSteps - 2 ? (
                            <button className="btn btn-primary ticket-configuration__footer-btn ab-mobile-conifgurator__footer-btn"
                                    key={currentStep}
                                    disabled={
                                        (stepViews[currentStep] === 'datepicker' && !selectedDate) ||
                                        (stepViews[currentStep] === 'vip-pass' && (!allVipPassesValid || vipPasses.length === 0)) ||
                                        (stepViews[currentStep] === 'pricing-list' && getSumOfObjectKeys(ticketCounter) === 0)
                                    }
                                    onClick={() => {
                                        onChangeStep(currentStep + 1);
                                    }}>
                                <span className={`ticket-configuration__footer-btn-text 
                                    ${(stepViews[currentStep] === 'datepicker' && !selectedDate) ||
                                    (stepViews[currentStep] === 'vip-pass' && (!allVipPassesValid || vipPasses.length === 0)) ||
                                    (stepViews[currentStep] === 'pricing-list' && getSumOfObjectKeys(ticketCounter) === 0)
                                    ? 'ticket-configuration__footer-btn-text--disabled' : '' }`}
                                >

                                    {translate(`ski-ticket.continue`)}
                                </span>
                            </button>
                        ) : (
                            <button className="btn btn-primary ticket-configuration__footer-btn"
                                    disabled={
                                        (stepViews[currentStep] === 'pricing-list' && getSumOfObjectKeys(ticketCounter) === 0)
                                    }
                                    onClick={() => {
                                        onSubmit()
                                    }}>
                                <span className={`ticket-configuration__footer-btn-text `}>
                                    {isInEdit ? translate(`ski-ticket.update-ticket`) : translate(`ski-ticket.add-to-cart`)}
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}