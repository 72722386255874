/** @jsx h */

import {h, render, Fragment} from "preact";
import {useState, useEffect} from 'preact/hooks';
import {Modal} from 'react-bootstrap';
import {translate} from '@elements/translations';
import {
    getLocaleDateString,
    getTimeString,
    noop,
    getSumOfObjectKeys,
    addParamsToUrl,
    fetcher, throwAlertMessage, getPriceGroupTitle
} from "../../utils/utils.js";
import StepNav from "../step-nav";
import ActivityTicketDateSelection from "./activity-ticket-date-selection";
import ActivityTicketDateTimeSelection from "./activity-ticket-date-time-selection";
import ActivityTicketTimeSelection from "./activity-ticket-time-selection";
import ActivityTicketHeader from "./activity-ticket-header";
import ActivityTicketPricingSelection from "./activity-ticket-pricing-selection";
import ActivityTicketServiceSelection from "./activity-ticket-service-selection";
import ActivityTicketSummary from "./activity-ticket-summary";
import ActivityTicketFooterBar from "./activity-ticket-footer-bar";
import LoadingSpinner from "../loading-spinner";
import DatePickerSelection from "./activity-ticket-datepicker-selection";
import {getActivityBaseConfig} from "../../utils/config-helper";
import 'whatwg-fetch';
import 'url-search-params-polyfill'; // Edge Polyfill
import {showNotification} from "@elements/alert-notification";
import {setCartCount} from "../../../load-cart";
import {dateToISOString, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";
import {resetMaskModal} from "../../../microanimations-morphingmodal";

export default function ActivityTicketModal({
      isLoading = false,
      isInEdit = false,
      editId,
      id,
      currentStepDefault = 0,
      isModalOpen = false,
      title = "",
      onModalHide = noop(),
      firstView = "",
      hasTimeView = false,
      hasServiceView = false,
      dateIdDefault = "",
      dateDefault = "",
      timeIdDefault = "",
      timeDefault = {start: "", end: ""},
      priceIdDefault = "",
      priceDefault = "",
      ticketCounterDefault = {},
      selectedOptionsDefault = {}
  }) {

    // States
    const [currentStep, setCurrentStep] = useState(currentStepDefault); // 0 is initial state
    const [selectedDateId, setSelectedDateId] = useState(dateIdDefault);
    const [selectedDate, setSelectedDate] = useState(dateDefault);
    const [selectedTimeId, setSelectedTimeId] = useState(timeIdDefault);
    const [selectedTime, setSelectedTime] = useState(timeDefault);
    const [selectedPrice, setSelectedPrice] = useState(priceDefault);
    const [selectedPriceId, setSelectedPriceId] = useState(priceIdDefault);
    const [ticketCounter, setTicketCounter] = useState(ticketCounterDefault);
    const [selectedOptions, setSelectedOptions] = useState(selectedOptionsDefault);
    const [configurationSuccess, setConfigurationSuccess] = useState(false);
    const [addToCartLoading, setAddToCartLoading] = useState(false);

    // Render
    if (isLoading) {
        return (
            <LoadingSpinner/>
        );
    }

    const resetValues = () => {
        setCurrentStep(currentStepDefault);
        setSelectedDateId(dateIdDefault);
        setSelectedDate(dateDefault)
        setSelectedTime(timeIdDefault);
        setSelectedTimeId(timeIdDefault);
        setSelectedPrice(priceIdDefault);
        setSelectedPriceId(priceIdDefault);
        setTicketCounter(ticketCounterDefault);
        setSelectedOptions(selectedOptionsDefault);
        setAddToCartLoading(false);
    }


    //calculate how many steps should be displayed
    function calculateSteps() {
        let steps = 3;
        if(firstView === 'pricing-list') {steps--;}
        if(hasTimeView) { steps++; }
        if(hasServiceView) { steps++; }
        return steps;
    }

    function getBreadcrumbButton(text, changeToStep) {
        return (
            <button
                onClick={() => {
                    setCurrentStep(+changeToStep);
                }}
                className="btn btn-no-styling ticket-configuration__edit">
                    {text}
                    <span aria-label="edit" className="icon icon-pen-outline ticket-configuration__edit-icon"/>
            </button>
        )
    }

    function getStepView() {
        let views = [];

        views.push(firstView);
        if(hasTimeView) {
            views.push('time-list');
        }
        if(firstView !== 'pricing-list') {
            views.push('pricing-list');
        }
        if(hasServiceView) {
            views.push('service-list');
        }
        return views;
    }

    function getBreadcrumb() {
        let views = getStepView();
        let breadcrumb = [];

        if(currentStep > 0) {
            for (let i = 0; i < currentStep; i++) {
                let view = views[i];

                //get title and subtitle of location
                if(view === 'time-list') {
                    breadcrumb.push(getBreadcrumbButton(getTimeString(selectedTime), i));
                } else if(view === 'date-list') {
                    breadcrumb.push(getBreadcrumbButton(getLocaleDateString(UTCDateToLocalDate(new Date(selectedDate))), i));
                } else if(view === 'datepicker') {
                    breadcrumb.push(getBreadcrumbButton(getLocaleDateString(UTCDateToLocalDate(new Date(selectedDate))), i));
                }else if(view === 'date-time-list') {
                    breadcrumb.push(getBreadcrumbButton((getLocaleDateString(UTCDateToLocalDate(new Date(selectedDate))) + " | " + getTimeString(selectedTime)), i));
                } else if(view === 'pricing-list') {
                    breadcrumb.push(getBreadcrumbButton(getSumOfObjectKeys(ticketCounter) + " " + translate('activity-ticket.tickets'), i));
                }
            }
        }

        return breadcrumb;
    }

    const submitConfiguration = () => {
        setAddToCartLoading(true);

        let configurationData = {
            activity_id: id,
            ...(editId && {edit_id: editId}),
            date: selectedDate,
            startTime: selectedTime.start,
            endTime: selectedTime.end,
            ticketCounter: ticketCounter,
            options: selectedOptions
        };

        let url = getActivityBaseConfig().addToCart;

        let request = fetch(url, {
            method: 'POST',
            body: JSON.stringify(configurationData),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        request.then(result => result.json()).then(result => {
            setCurrentStep(currentStep +1);
            if(result.success) {
                setAddToCartLoading(false);
                setConfigurationSuccess(true);
                if(result.itemsInCart) {
                    setCartCount(result.itemsInCart);
                }
                if (result['__trackingScript']) {
                    try {
                        eval(result['__trackingScript']);
                    } catch(e) {console.error(e)}
                }
            } else {
                setAddToCartLoading(false);
                showNotification(request);
            }
        }).catch(e => {
            setAddToCartLoading(false);
            let errorText = `<p><strong>activity-ticket-modal</strong>:add-to-cart failed!</p><p>${id}</p>`;
            throwAlertMessage('danger', 'add-to-cart', errorText, true);
        });


    }

    const handleModalHide = () => {
        onModalHide();
        resetMaskModal()

        const inLastStep = currentStep === calculateSteps() - 1
        if(inLastStep) {
            // animate the cart-icon on home
            const cartIcon = document.querySelector('.js-main-navbar-cart__icon')
            const cartAmount = document.querySelector('.js-load-cart__count')

            if(cartIcon && cartAmount) {
                cartIcon.classList.add('animate-cart-icon')
                cartAmount.classList.add('animate-cart-amount')
                cartIcon.addEventListener("animationend", () => {
                    cartIcon.classList.remove('animate-cart-icon')
                    cartAmount.classList.remove('animate-cart-amount')
                })
            }
        }
    }

    return (
        <Modal
            dialogClassName="modal-dialog modal-fullscreen modal-light-grey js-configurator-modal--morphing configurator-modal--morphing"
            show={isModalOpen} onHide={handleModalHide} backdrop={false}>

            <div className="ticket-configuration__header">
                <a href="/" className="pointer-events-auto ticket-configuration__brand">
                    <img width="238" height="100" alt="" src="/static/img/logos/verbier-4-vallees.svg"/>
                </a>

                <button type="button" className="close modal__close"
                        onClick={() => {
                            handleModalHide();
                            resetValues();
                        }}>
                    <span aria-hidden="true" className="icon icon-close"/>
                    <span className="modal__close-text">close</span>
                </button>

                <StepNav max={calculateSteps()} current={currentStep} onStepClick={setCurrentStep} stepViews={getStepView()}/>
            </div>


            <div className="modal-content__inner">

                <div className="ticket-configuration">

                    { getStepView()[currentStep] === 'date-list' ? (
                        <Fragment>
                            <ActivityTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('activity-ticket.date-list.choose-date')}
                               />
                            <ActivityTicketDateSelection
                                selectedDateId={selectedDateId}
                                onChangeDate={(dateId, dateString) => {
                                    setSelectedDateId(dateId);
                                    setSelectedDate(dateString);
                                }}
                                id={id}
                            />
                        </Fragment>
                    ) : ""}

                    { getStepView()[currentStep] === 'date-time-list' ? (
                        <Fragment>
                            <ActivityTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('activity-ticket.date-time-list.choose-date')}
                            />
                            <ActivityTicketDateTimeSelection
                                selectedDateId={selectedDateId}
                                onChangeDate={(dateId, dateString, timeObj) => {
                                    setSelectedDateId(dateId);
                                    setSelectedDate(dateString);
                                    setSelectedTime(timeObj);
                                }}
                                id={id}
                            />
                        </Fragment>
                    ) : ""}

                    { getStepView()[currentStep] === 'datepicker' ? (
                        <Fragment>
                            <ActivityTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('activity-ticket.datepicker.choose-date')}
                            />
                            <DatePickerSelection
                                id={id}
                                selectedDateId={selectedDateId}
                                selectedDateString={selectedDate}
                                onChangeDate={(dateId, dateString) => {
                                    setSelectedDateId(dateId);
                                    setSelectedDate(dateString);
                                }}
                            />
                        </Fragment>
                    ) : ""}

                    { getStepView()[currentStep] === 'time-list' ? (
                        <Fragment>
                            <ActivityTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('activity-ticket.date-time-list.choose-time')}
                                subtitle={getBreadcrumb()}
                            />
                            <ActivityTicketTimeSelection
                                id={id}
                                selectedDate={selectedDate}
                                selectedTimeId={selectedTimeId}
                                onChangeTime={(timeId, timeObj) => {
                                    setSelectedTimeId(timeId);
                                    setSelectedTime(timeObj);
                                }}
                            />
                        </Fragment>
                    ) : ""}

                    { getStepView()[currentStep] === 'pricing-list' ? (
                        <Fragment>
                            <ActivityTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('activity-ticket.date-time-list.choose-quantity')}
                                subtitle={getBreadcrumb()}
                            />

                            <ActivityTicketPricingSelection
                                title={title}
                                selectedDate={selectedDate}
                                selectedPrice={selectedPrice}
                                selectedTime={selectedTime}
                                ticketCounter={ticketCounter}
                                onChangeTicketCounter={(item) => {
                                    setTicketCounter(item)
                                }}
                                id={id}
                            />
                        </Fragment>
                    ) : ""}

                    { getStepView()[currentStep] === 'service-list' ? (
                        <Fragment>
                            <ActivityTicketHeader
                                currentStep={currentStep}
                                title={title}
                                isInEdit={isInEdit}
                                toptitle={translate('activity-ticket.service-options')}
                                subtitle={getBreadcrumb()}
                            />

                            <ActivityTicketServiceSelection
                                title={title}
                                selectedOptions={selectedOptions}
                                onChangeOptions={(item) => {
                                    setSelectedOptions(item);
                                }}
                                selectedDate={selectedDate}
                                selectedTime={selectedTime}
                                ticketCounter={ticketCounter}
                                id={id}
                            />
                        </Fragment>
                    ) : ""}

                    { currentStep === calculateSteps() -1 ? (

                        <Fragment>
                            { addToCartLoading ?  <LoadingSpinner/> : "" }

                            <ActivityTicketSummary
                                id={id}
                                isInEdit={isInEdit}
                                title={title}
                                selectedDate={selectedDate}
                                selectedTime={selectedTime}
                                selectedPrice={selectedPrice}
                                ticketCounter={ticketCounter}
                                selectedOptions={selectedOptions}
                                configurationSuccess={configurationSuccess}
                            />
                        </Fragment>

                    ) : ""}
                </div>
            </div>

            {calculateSteps() -1 !== currentStep ? (
                <ActivityTicketFooterBar
                    isInEdit={isInEdit}
                    currentStep={currentStep}
                    onChangeStep={setCurrentStep}
                    onSubmit={submitConfiguration}
                    ticketCounter={ticketCounter}
                    selectedOptions={selectedOptions}
                    selectedDateId={selectedDateId}
                    selectedTimeId={selectedTimeId}
                    stepViews={getStepView()}
                    maxSteps={calculateSteps()}
                />
            ) : ""}
        </Modal>
    )
}
