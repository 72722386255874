/** @jsx h */

import {h, Fragment} from "preact";
import {noop, fetcher, addParamsToUrl, throwAlertMessage} from "../../utils/utils.js";
import {assert, object, number, optional, string, date, map, define, array, enums, func, boolean, defaulted} from 'superstruct';
import LoadingSpinner from "../loading-spinner";
import DateSelectionList from "../date-selection-list";
import {useDateConfigurations} from "../../utils/activity-configuration-hooks";
import {translate} from '@elements/translations';

export default function ActivityTicketDateSelection({
    id = "",
    selectedDateId = "",
    onChangeDate = noop()
}) {
    const DateType = object({
        date: string(),
        available: optional(number()),
        max: optional(number()),
        status: enums(['success','warning','danger']),
    });

    let params = {
        activity_id: id
    }

    let {dateConfigurations, isLoading, error} = useDateConfigurations(params);

    return (
        <Fragment>
            { isLoading ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('activity-ticket.Loading-Error')}
                </div>
            ) : ""}
            {dateConfigurations ? (
                <DateSelectionList
                    availableItems={dateConfigurations.availabilityByDate}
                    onChangeSelected={onChangeDate}
                    selectedItemId={selectedDateId}
                    isTimeView={false}
                    DateValidationType={DateType}
                    />
            ) : ""}
        </Fragment>
    )
}

