/** @jsx h */

import {h, Fragment, Component} from "preact";
import MapSectors from '../../../../../static/build/svg-transform/map-sectors.svg';
import MapSectorsSummer from '../../../../../static/build/svg-transform/map-sectors-summer.svg';
import * as interactiveSectorMap from '../../sectorMap';

export default class SectorMap extends Component {
    componentDidMount() {
        interactiveSectorMap.init();
    }

    componentWillUnmount() {
        // needed?
    }

    render() {

        const getBgPath = () => {
           let suffix = _config.season && _config.season === 'summer' ? '-summer' : '';
           return `/static/build/img/sectors-map/map-sectors-bg${suffix}.jpg`;
        }


        return (
            <section className="sectors-map-wrapper sectors-map-wrapper--small">
                <img src={getBgPath()} className="img-fluid sectors-map__bg"/>

                <div className="sectors-map__svg-wrapper">
                    {_config.season === 'summer' ? (
                        <MapSectorsSummer/>
                    ) : (
                        <MapSectors/>
                    )}

                </div>
            </section>

        )
    }
}
