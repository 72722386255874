/** @jsx h */

import {h} from "preact";
import {noop} from "../utils/utils.js";

export default function Select({
     name = "",
     id = "",
     label= "",
     disabled = false,
     options = [],
     labelModifierClass = "",
     onChangeSelect = noop()
 }) {

    return (
        <div>
            <label className={`form-control-label ${labelModifierClass}`} htmlFor={id}>
                {label}
            </label>
            <div className="select form-group">
                <div>
                    <select
                        name={name}
                        id={id}
                        className="form-control pt-2"
                        onChange={(evt) => {
                            onChangeSelect(evt.target.value);
                        }}
                        disabled={disabled}>
                        {options.map((option) => (
                            <option
                                selected={option.selected}
                                disabled={option.disabled}
                                value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>

            </div>
        </div>

    );
}
