"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, findIn, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-center-active-tab', function (element) {
        let activeTab = findIn('.active', element);
        let tab = findIn('li', element);
        let tabs = findAllIn('li', element);

        center(tab, activeTab);

        tabs.forEach(function(tabItem) {
            on('click', function(e) {
                 center(tab, e.target)
            }, tabItem);
        });
    });

}
function center(navTab, tab) {
    if(tab) {
        let parentUl = closest('ul',navTab),
            childPos = tab.offsetLeft,
            parentScroll = tab.parentNode.scrollLeft,
            offset = childPos + parentScroll - parentUl.offsetWidth/2 + tab.offsetWidth/2;

        parentUl.scrollTo({
            left: offset,
            behavior: 'smooth'
        });
    }
}
