/** @jsx h */

import {h, Fragment} from "preact";
import { useState } from 'preact/hooks';
import {translate} from '@elements/translations';
import {UTCDateToLocalDate} from "@elements/date-utils";
import {noop,  getLocaleDateStringShort} from "../utils/utils";

export default function VipPassItem({
        id = "",
        onRemoveVipPass = noop(),
        isValid = true,
        counter = 1,
        startDate = "",
        endDate = "",
        infoText = "",
        inputValue = ""
    }) {

    return (
       <div className={`vip-pass__item ${isValid ? 'vip-pass--valid': 'vip-pass--invalid'}`}>
           <div className="d-flex mb-3">
               <div className="vip-pass__headline">{ translate('ski-ticket.vip-pass.vip pass nr.') + " " +counter}</div>
               {/*todo: TK & format dates*/}
               { isValid ? (
               <div className="vip-pass__subline ms-5">
                   <time dateTime={startDate}>{getLocaleDateStringShort(UTCDateToLocalDate(new Date(startDate)))}</time>
                   -
                   <time dateTime={endDate}>{getLocaleDateStringShort(UTCDateToLocalDate(new Date(endDate)))}</time>
               </div>
               ) : ""}
           </div>
           <div className="vip-pass__input-group">
               <div className="vip-pass__input form-group has-value">
                   <label htmlFor="vip-pass" className="form-control-label floating-label">{translate('ski-ticket.vip-pass.enter vip pass number')}</label>
                   <input id="vip-pass" className="form-control js-floating-label has-value" readOnly type="text" value={inputValue}/>
                   <button className="vip-pass__delete btn-no-styling" onClick={() => {onRemoveVipPass(inputValue)}}><span className="icon icon-delete-outline" title={translate('ski-ticket.vip-pass.delete')} aria-label={translate('ski-ticket.vip-pass.delete')}/></button>
               </div>
               <div className="vip-pass__help-text"><span className={`icon me-2 ${isValid ? 'icon-check': 'icon-close'}`}/>{isValid ? translate('ski-ticket.vip-pass.vip pass is valid') : translate('ski-ticket.vip-pass.vip pass is invalid')}</div>
           </div>
           <div className="vip-pass__additional-text">{infoText}</div>
       </div>
    )
}