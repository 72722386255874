import {throwAlertMessage} from "./utils";

export function getActivityBaseConfig() {
    const _config = window._config || {};

    let baseConfig = _config.activityTicketConfigUrls;

    if (!baseConfig) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.activityTicketConfigUrls". Value of "_config.activityTicketDataUrls" was not set in _config', true);
    }

    return baseConfig;
}

export function getSkiTicketBaseConfig() {
    const _config = window._config || {};

    let baseConfig = _config.skiTicketConfigUrls;

    if (!baseConfig) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.skiTicketConfigUrls". Value of "_config.skiTicketConfigUrls" was not set in _config', true);
    }

    return baseConfig;
}

export function getSkiLockerBaseConfig() {
    const _config = window._config || {};

    let baseConfig = _config.skiLockerConfigUrls;

    if (!baseConfig) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.skiLockerConfigUrls". Value of "_config.skiLockerConfigUrls" was not set in _config', true);
    }

    return baseConfig;
}

export function getB2BBaseConfig() {
    const _config = window._config || {};

    let baseConfig = _config.b2bTicketConfigUrls;

    if (!baseConfig) {
        throwAlertMessage('warning', 'MISSING CONFIG', 'Missing config "_config.b2bTicketConfigUrls". Value of "_config.b2bTicketConfigUrls" was not set in _config', true);
    }

    return baseConfig;
}