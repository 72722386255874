/** @jsx h */

import {h, Fragment} from "preact";
import {noop} from "../../utils/utils.js";
import {assert, object, number, optional, string, define, array, enums, func, boolean, defaulted} from 'superstruct';
import PricingList from "../pricing-list";
import {usePricingConfigurations} from "../../utils/activity-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import {translate} from '@elements/translations';

export default function ActivityTicketPricingSelection({
    title = "",
    id = "",
    selectedDate = "",
    selectedTime = "",
    selectedPrice = "",
    ticketCounter = {},
    onChangeTicketCounter = noop()
}) {

    const PricingItemType = object({
        id: string(),
        price: number(),
        info: optional(string()),
        title: optional(string()),
        subtitle:optional(string()),
        basePrice: optional(number()),
        discountText: optional(string()),
        availability: optional(number())
    });

    let params = {
        activity_id: id,
        date: selectedDate,
        ...(selectedTime.start && { startTime: selectedTime.start }),
        ...(selectedTime.end && { endTime: selectedTime.end })
    }

    let {pricingConfigurations, isLoading, error} = usePricingConfigurations(params);

    return (
        <Fragment>
            { isLoading ? (
                <LoadingSpinner/>
            ) : ""}

            { error ? (
                <div className="alert alert-danger">
                    {translate('activity-ticket.Loading-Error')}
                </div>
            ) : ""}

            { pricingConfigurations ? (
                <PricingList
                    data={pricingConfigurations}
                    ticketCounter={ticketCounter}
                    title={title}
                    priceGroups={pricingConfigurations.priceGroups}
                    onChangeTicketCounter={onChangeTicketCounter}
                    PricingItemType={PricingItemType}
                />
            ) : "" }
        </Fragment>
    )
}