"use strict";

import {findIn, findAllIn, addClass, on, removeClass, removeAttribute, setAttribute} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind('.js-scrolling-area', function (container) {
        let prevButton = findAllIn('.js-scrolling-area__prev', container),
            nextButton = findAllIn('.js-scrolling-area__next', container),
            counterTotal = findIn('.js-scrolling-area__counter-total', container),
            counterCurrent = findIn('.js-scrolling-area__counter-current', container),
            caption = findIn('.js-scrolling-area__caption', container),
            dots = findAllIn('.js-scrolling-area__dot', container),
            scrollArea = findIn('.js-scrolling-area__area', container),
            isUpdatingArrows = container.getAttribute('data-scrolling-area-update-arrows') || false,
            counterPrefix = "";

        let items = findAllIn('.js-scrolling-area__item', container);

        if (items.length > 0){
            const scrollWidth = getScrollWidth(items[0]);
            const offset = items[0].getBoundingClientRect().left;

            if(counterTotal){
                if(items.length < 10){
                    counterPrefix = "0";
                }
                counterTotal.innerHTML = counterPrefix + items.length;
            }

            if (counterCurrent){
                counterCurrent.innerHTML = "01";
            }

            if (caption){
                caption.innerHTML = findIn('.js-lightbox__item',items[0]).getAttribute('data-sub-html');
            }

            //initial scroll position 0
            scrollArea.scrollLeft = 0;

            nextButton.map((button) => {
                if (isUpdatingArrows && scrollArea.clientWidth >= scrollArea.scrollWidth) {
                    button.setAttribute('hidden', true);
                } else {
                    on('click', function () {
                        scrollTo(scrollArea, scrollArea.scrollLeft + getScrollWidth(items[0]))
                    }, button);
                }
            });

            prevButton.map((button) => {
                if (isUpdatingArrows && scrollArea.clientWidth >= scrollArea.scrollWidth) {
                    button.setAttribute('hidden', true);
                } else {
                    if(scrollArea.scrollLeft === 0) {
                        setAttribute('disabled', true, button);
                    } else {
                        removeAttribute('disabled', button);
                    }

                    on('click', function () {
                        scrollTo(scrollArea, scrollArea.scrollLeft - getScrollWidth(items[0]))
                    }, button);
                }
            });

            dots.map((button) => {
                on('click', function () {
                    let id = button.dataset.scrollingAreaId;
                    findIn('.js-scrolling-area__item[data-scrolling-area-id="'+ id +'"]', container).scrollIntoView();
                }, button);
            });

            let isScrolling;
            let direction = scrollArea.scrollLeft;

            scrollArea.addEventListener('scroll', function (evt) {
                window.clearTimeout(isScrolling);

                isScrolling = setTimeout(function() {
                    dots.map((item) => removeClass('is-active', item));

                    if (nextButton){
                        nextButton.map((button) => removeAttribute('disabled', button));
                    }

                    if (prevButton){
                        if(scrollArea.scrollLeft === 0) {
                            prevButton.map((button) => setAttribute('disabled', true, button));
                        } else {
                            prevButton.map((button) => removeAttribute('disabled', button));
                        }
                    }

                    items.map((item, index) => {
                        removeClass('is-active', item);
                        let itemLeft = Math.round(item.getBoundingClientRect().left + 2);

                        if (direction < scrollArea.scrollLeft) {

                            let newScrollLeft = scrollArea.scrollLeft,
                                scrollAreaWidth= scrollArea.getBoundingClientRect().width,
                                scrollAreaScrollWidth = scrollArea.scrollWidth;

                            if (scrollAreaScrollWidth-newScrollLeft === Math.round(scrollAreaWidth)) {
                                if (nextButton) {
                                    if (items.slice(-1)[0] === item) {
                                        setAttribute('disabled', true, nextButton[0])
                                    }
                                }
                            }


                            if (itemLeft < offset + scrollWidth && itemLeft >= offset) {
                                addClass('is-active', item);

                                let id = item.dataset.scrollingAreaId;
                                counterPrefix = "";

                                if (counterCurrent) {
                                    if (id < 10) {
                                        counterPrefix = "0";
                                    }
                                    counterCurrent.innerHTML = counterPrefix + id;
                                }

                                if (caption) {
                                    caption.innerHTML = findIn('.js-lightbox__item', item).getAttribute('data-sub-html');
                                }

                                if (findIn('.js-scrolling-area__dot', container)) {
                                    addClass('is-active', findIn('.js-scrolling-area__dot[data-scrolling-area-id="' + id + '"]', container));
                                }


                            } else if (items.slice(-1)[0].getBoundingClientRect().right <= document.documentElement.clientWidth) {
                                addClass('is-active', items.slice(-1)[0]);
                            }
                        } else {
                            if (itemLeft + scrollWidth > offset && itemLeft <= scrollWidth && itemLeft > 0) {
                                addClass('is-active', item);

                                if (findIn('.js-scrolling-area__dot', container)) {
                                    addClass('is-active', findIn('.js-scrolling-area__dot[data-scrolling-area-id="' + id + '"]', container));
                                }
                            }
                        }

                            if (item.getBoundingClientRect().left <= scrollWidth && item.getBoundingClientRect().left >= offset) {
                                let id = item.dataset.scrollingAreaId;
                                counterPrefix = "";

                                if (counterCurrent){
                                    if (id < 10){
                                        counterPrefix = "0";
                                    }
                                    counterCurrent.innerHTML = counterPrefix + id;
                                }

                                if (caption){
                                    caption.innerHTML = findIn('.js-lightbox__item',item).getAttribute('data-sub-html');
                                }

                                if (findIn('.js-scrolling-area__dot', container)){
                                    addClass('is-active', findIn('.js-scrolling-area__dot[data-scrolling-area-id="'+ id +'"]', container));
                                }

                                if (nextButton) {
                                    if (items.slice(-1)[0] === item) {
                                        setAttribute('disabled', true, nextButton[0])
                                    }
                                }
                            }

                    });

                    direction = scrollArea.scrollLeft;

                }, 66);

            }, false);

            updateScrollWidth(items[0]);
        }

    });
}

function scrollTo(scrollArea, scroll) {
    try {
        scrollArea.scroll({
            left: scroll+5,
            behavior: 'smooth'
        });
    } catch (e) {
        scrollArea.scrollLeft = scroll;
    }
}

function getScrollWidth(item) {
    return item.getBoundingClientRect().width;
}

function updateScrollWidth(item) {
    let delay = 250,
        throttled = false;

    window.addEventListener('resize', function() {
        if (!throttled) {
            getScrollWidth(item);
            throttled = true;
            setTimeout(function() {
                throttled = false;
            }, delay);
        }
    });
}

function isElementVisible(element) {
    let rect = element.getBoundingClientRect();

    return !(rect.left + rect.width >= document.body.clientWidth
        && rect.left < 0
    );
}
