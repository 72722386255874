/** @jsx h */

import {h, Fragment} from "preact";
import {noop} from "../../utils/utils";
import {useSectorConfigurations} from "../../utils/ski-ticket-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import SectorList from "../sector-list";
import {assert, object, optional, string, array, boolean, number, enums} from 'superstruct';
import {translate} from '@elements/translations';

export default function SkiTicketSectorSelection({
    id = "",
    selectedSector = "",
    onChangeSector = noop()
}) {
    const SectorType = object({
        id: string(),
        tooltipInfo: optional(object({
            title: optional(string()),
            description: optional(string()),
            validityDate: optional(object({
                start: string(),
                end: string()
            }))
        })),
        validityDate: optional(object({
            start: string(),
            end: string()
        })),
        isDisabled: optional(boolean()),
        isHighlighted: boolean(),
        title: string(),
        numberOfLifts: number(),
        numberOfSlopeKilometers: number(),
        fromPrice: number(),
        smallPrint: string(),
        shortDescription: string(),
        regionList: array()
    });

    let params = {
        ticket_id: id
    }

    let {sectorConfigurations, isLoading, error} = useSectorConfigurations(params);

    return (
        <Fragment>
            { isLoading ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('ski-ticket.Loading-Error')}
                </div>
            ) : ""}
            {sectorConfigurations ? (
                <SectorList
                    selectedSector={selectedSector}
                    onChangeSelected={onChangeSector}
                    sectors={sectorConfigurations.sectors}
                    SectorValidationType={SectorType}
                />
            ) : ""}
        </Fragment>
    )
}