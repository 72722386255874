import {onFind} from "@elements/init-modules-in-scope";

import * as activityTicketConfig from './tickets/activity-ticket-config';
activityTicketConfig.init();

import * as skiTicketConfig from './tickets/ski-ticket-config';
skiTicketConfig.init();

import * as skiLockerConfig from './tickets/ski-locker-config';
skiLockerConfig.init();

import Tooltip from 'bootstrap/js/dist/tooltip';
onFind('[data-bs-toggle="tooltip"]',function (element) {
    new Tooltip(element);
});

import * as ecommerceTracking from './ecommerce-tracking';
ecommerceTracking.init();

