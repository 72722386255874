/** @jsx h */

import {h, Fragment} from "preact";
import {useState, useEffect} from "preact/hooks";
import {
    noop,
    getLocaleMonthName,
    getLocaleNextMonthName,
    getLocaleDay,
    getLocaleWeekday,
    getLocaleYear,
    getRangeDays,
    addDays,
    getLocaleMonth, getLocalePrevMonthName, getLocalePrevMonth, formatPrice, removeDays
} from "../../utils/utils.js";
import {assert, object, number, optional, string, date, map, define, array, enums, func, boolean, defaulted} from 'superstruct';
import ReactDatepicker from "react-datepicker";
import {dateToISOString, localDateToUTCDate} from "@elements/date-utils";
import {translate} from '@elements/translations';
import {Tooltip, Button, OverlayTrigger} from 'react-bootstrap';
import {useDateConfigurations, usePricingSliderConfigurations} from "../../utils/ski-ticket-configuration-hooks";
import LoadingSpinner from "../loading-spinner";
import PricingSlider from "../pricing-slider";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
registerLocale('de', de);
registerLocale('fr', fr);
setDefaultLocale('en');


export default function SkiTicketDatepickerSelection({
      id = "",
      selectedDate = "",
      selectedDateString =  new Date(),
      selectedToDate = null,
      selectedSector = "",
      selectedToDateString = "",
      onChangeDates = noop(),
      showPricingSlider = false,
      onReceiveEmptyPricing =  noop(),
      onSelectPricingSliderDate = noop(),
      vipPasses = []
  }) {

    const isMobile = matchMedia('(max-width: 767px)').matches;

    const DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS = 62;
    let [preloadingStartDate, setPreloadingStartDate] = useState("");
    let [preloadingEndDate, setPreloadingEndDate] = useState("");
    let [includedDates, setIncludedDates] = useState([]);
    let [allAvailableDates, setAllAvailableDates] = useState({});

    const PricingSliderDateType = object({
        date: string(),
        defaultPrice: number(),
        isPriceOfTheWeek: boolean(),
        priceByPriceGroups: object()
    });
    

    /* FETCHING DATEPICKER DATA */
    let params = {
        ticket_id: id,
        ...(selectedSector && { sector: selectedSector }),
        ...(vipPasses && {vipPasses: vipPasses}),
        ...(preloadingStartDate && preloadingEndDate && { startDate: preloadingStartDate}),
        ...(preloadingEndDate && preloadingStartDate && { endDate: preloadingEndDate}),
    }

    let {dateConfigurations, isLoading, error} = useDateConfigurations(params);
    let maxSelectableDays = dateConfigurations ? dateConfigurations.datepickerConfiguration.maxSelectableDays : false;
    let minSelectableDays = dateConfigurations ? dateConfigurations.datepickerConfiguration.minSelectableDays : false;
    let vipPassInfo = dateConfigurations ? dateConfigurations.vipPassInfo : false;

    let seasons = dateConfigurations ? dateConfigurations.datepickerConfiguration.seasons : false;
    let specials = dateConfigurations ? dateConfigurations.datepickerConfiguration.specials : false;

    /* FETCHING PRICING SLIDER DATA */
    let paramsPricingSlider = {
        ticket_id: id,
        ...(selectedSector && { sector: selectedSector }),
        ...(selectedDate && { startDate: selectedDate }),
        ...(vipPasses && {vipPasses: vipPasses}),
        ...(selectedToDate && { endDate: selectedToDate })
    }

    let {pricingSliderConfigurations, isLoadingPricingSlider} = usePricingSliderConfigurations(paramsPricingSlider);
    if(pricingSliderConfigurations) {
        onReceiveEmptyPricing(!Object.keys(pricingSliderConfigurations.priceByDate).length > 0);  //
    }

    function prefillDefaultIncludedDates() {
        let tempIncludedDates = [...includedDates] || [];
        Object.keys(dateConfigurations.availabilityByDate).map(function(availableDate) {
            if(dateConfigurations.datepickerConfiguration.maxSelectableDays && dateConfigurations.datepickerConfiguration.maxSelectableDays !== 1 && selectedDate && !selectedToDate) {
                let maxDate = dateToISOString(addDays(selectedDateString, dateConfigurations.datepickerConfiguration.maxSelectableDays), false);
                let availableDateString = dateConfigurations.availabilityByDate[availableDate].date;

                if(availableDateString >= selectedDate && availableDateString <= maxDate) {
                    tempIncludedDates.push(new Date(dateConfigurations.availabilityByDate[availableDate].date));
                }
            } else {
                tempIncludedDates.push(new Date(dateConfigurations.availabilityByDate[availableDate].date));
            }
        });

        setIncludedDates(tempIncludedDates);
    }

    //preload dates if date is already selected
    useEffect(function() {
        if(selectedDate && !preloadingStartDate && !preloadingEndDate && includedDates.length === 0) {
            let newStartDate = removeDays(new Date(), new Date().getDay() -1);
            let newEndDate = addDays(selectedDateString, DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS);

            setPreloadingStartDate(dateToISOString(localDateToUTCDate(newStartDate), false));
            setPreloadingEndDate(dateToISOString(localDateToUTCDate(newEndDate), false));
        }
    }, [selectedDate])

    useEffect(function() {
        if(dateConfigurations) {

            prefillDefaultIncludedDates();
            setAllAvailableDates({...allAvailableDates, ...dateConfigurations.availabilityByDate});
        }
    }, [dateConfigurations])

    function loadNextDates() {
        let newStartDate = addDays(includedDates[includedDates.length - 1], 1);
        let newEndDate = addDays(newStartDate, DEFAULT_PRELOAD_DATE_RANGE_IN_DAYS);

        setPreloadingStartDate(dateToISOString(localDateToUTCDate(newStartDate), false));
        setPreloadingEndDate(dateToISOString(localDateToUTCDate(newEndDate), false));
    }

    //set arrow disabled if < current Month
    const setDisabled = (monthDate) => {
        let month = monthDate.toLocaleString(_config.localeDate, {
            month: "short",
            year: "numeric",
        })
        let currentMonth = new Date().toLocaleString(_config.localeDate, {
            month: "short",
            year: "numeric",
        })

        return month === currentMonth;
    }

    const renderDayContents = (day, date) => {
        let tooltipInfo = false;
        let season = false;
        let special = false;

        if(dateConfigurations) {
            Object.keys(allAvailableDates).map(function(availableDate) {
                if (allAvailableDates[availableDate].date === dateToISOString(localDateToUTCDate(date),false)) {
                    if(allAvailableDates[availableDate].priceTendency) {
                        tooltipInfo = <Fragment>{allAvailableDates[availableDate].priceTendency}</Fragment>;
                    }
                    if(allAvailableDates[availableDate].season && seasons) {
                        season = allAvailableDates[availableDate].season;
                    }
                    if(allAvailableDates[availableDate].special && specials) {
                        special = allAvailableDates[availableDate].special;
                    }

                }
            });
        }

        return (
            <Fragment>
                {tooltipInfo ? (
                    <OverlayTrigger
                        trigger={isMobile ? 'click' : ['hover', 'focus']}
                        key={"datepicker-" + date.getTime()}
                        rootClose={true}
                        description={"datepicker-" + date.getTime()}
                        overlay={
                            <Tooltip id={`tooltip-price-tendency-${"datepicker-" + date.getTime()}`}>
                                {tooltipInfo}
                            </Tooltip>
                        }>
                        <Button className="btn-no-styling react-datepicker__btn-tooltip btn-tooltip">
                            {day}

                            <div className="react-datepicker__status-wrapper">
                                {special ? (
                                    <span className={`react-datepicker__special-status status-special  status-special--${special}`}/>
                                ) : ""}

                                {season ? (
                                    <span className={`react-datepicker__season-status status  status-season--${season}`}/>
                                ) : ""}
                            </div>

                        </Button>
                    </OverlayTrigger>
                ) : (
                    <Fragment>
                        {day}

                        <div className="react-datepicker__status-wrapper">

                            {special ? (
                                <span className={`react-datepicker__special-status status-special status-special--${special}`}/>
                            ) : ""}

                            {season ? (
                                <span className={`react-datepicker__season-status status-season--${season}`}/>
                            ) : ""}

                        </div>

                    </Fragment>
                )}
            </Fragment>
        );
    };

    return (
        <Fragment>
            { isLoading || isLoadingPricingSlider ? (
                <LoadingSpinner/>
            ) : ""}
            { error ? (
                <div className="alert alert-danger">
                    {translate('ski-ticket.Loading-Error')}
                </div>
            ) : ""}
            { dateConfigurations ? (
                <div className="row microanimations--fade microanimations--first-fold" style="--microanimations-stagger-factor: 3">
                   <div className="col-md-8">

                       { vipPassInfo ? (
                           <div className="vip-pass__info">
                               {vipPassInfo}
                           </div>
                       ) : ""}

                       { seasons && isMobile ? (
                           <div className="row">
                               {Object.keys(seasons).map((season, key) => (
                                   <div className="col" key={key}>
                                       <span className={`me-1 status status--medium status-season--${season}`}/>
                                       {seasons[season]}
                                   </div>
                               ))}
                           </div>
                       ) : ""}

                       { specials && isMobile ? (
                           <div className="row">
                               {Object.keys(specials).map((special, key) => (
                                   <div className="col" key={key}>
                                       <span className={`me-1 status-special status-special--medium status-special--${special}`}/>
                                       {specials[special]}
                                   </div>
                               ))}
                           </div>
                       ) : ""}


                       <ReactDatepicker
                           calendarClassName="react-datepicker--small"
                           locale={_config.localeDate}
                           selected={selectedDateString ? new Date(selectedDateString) : includedDates[0]}
                           onChange={(dates) => {
                               onChangeDates(dates);
                           }}
                           renderCustomHeader={({
                                                    monthDate,
                                                    customHeaderCount,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                }) => (
                               <div className={customHeaderCount === 0 ? 'text-start' : 'text-end'}>
                                   { customHeaderCount === 0 ? (
                                       <button className={`btn-no-styling react-datepicker__custom-header-arrow `}
                                           disabled={setDisabled(monthDate)} onClick={() => {
                                                   decreaseMonth();
                                                   if(!isMobile) {
                                                       decreaseMonth();
                                                   }
                                               }} >
                                           <span className="icon icon-arrow-dropdown icon-rotate-90"/>
                                       </button>
                                   ) : ""}

                                   {monthDate.toLocaleString(_config.localeDate, {
                                       month: "long",
                                       year: "numeric",
                                   })}

                                   { customHeaderCount === 1 || isMobile ? (
                                       <button className="btn-no-styling react-datepicker__custom-header-arrow" onClick={() => {
                                           loadNextDates();
                                           increaseMonth();
                                           if(!isMobile) {
                                               increaseMonth();
                                           }
                                       }} >
                                           <span className="icon icon-arrow-dropdown icon-rotate-270"/>
                                       </button>
                                   ) : "" }
                              </div>
                           )}
                           startDate={selectedDateString ? new Date(selectedDateString) :selectedDateString}
                           endDate={selectedToDateString ? new Date(selectedToDateString) : selectedToDateString}
                           selectsRange={maxSelectableDays > 1}
                           inline
                           includeDates={includedDates}
                           monthsShown={isMobile ? 1 : 2}
                           className="configuration-datepicker"
                           renderDayContents={renderDayContents}
                           shouldCloseOnSelect={false}
                           minDate={selectedDateString && !selectedToDateString && minSelectableDays > 1 ? addDays(selectedDateString, minSelectableDays - 1) : includedDates[0] }
                           maxDate={selectedDateString && !selectedToDateString && maxSelectableDays > 1 ? addDays(selectedDateString, maxSelectableDays -1) : includedDates[includedDates.length] }
                       />

                       {showPricingSlider && pricingSliderConfigurations && selectedDateString && Object.keys(pricingSliderConfigurations.priceByDate).length > 0 ? (
                           <div className="mt-3">
                               <PricingSlider
                                   pricingSliderConfigurations={pricingSliderConfigurations.priceByDate}
                                   selectedDate={selectedDate}
                                   selectedToDate={selectedToDate}
                                   PricingSliderDateType={PricingSliderDateType}
                                   onSelectDate={onSelectPricingSliderDate}
                                   selectedRangeInDays={getRangeDays(selectedDate, selectedToDate)}
                               />
                           </div>
                       ) : ""}
                   </div>
                    <div className="col-xxl-3 col-md-4 ms-auto">
                        { seasons && !isMobile ? (
                            <div className="row">
                                {Object.keys(seasons).map((season, key) => (
                                    <div className="col">
                                        <span className={`me-1 status status--medium status-season--${season}`}/>
                                        {seasons[season]}
                                    </div>
                                ))}
                            </div>
                        ) : ""}

                        { specials && !isMobile ? (
                            <div className="row">
                                {Object.keys(specials).map((special, key) => (
                                    <div className="col" key={key}>
                                        <span className={`me-1 status-special status-special--medium status-special--${special}`}/>
                                        {specials[special]}
                                    </div>
                                ))}
                            </div>
                        ) : ""}

                        <div className="row mt-md-5 mt-3">
                            <div className="col-xl-6 col-md-12 col-6">
                                {translate('ski-ticket.From')}
                                <div className="datepicker-selection row gx-gutter--10 microanimations--change-blur" key={selectedDateString && getLocaleDay(selectedDateString) !== 'Invalid Date' && getLocaleDay(selectedDateString)}>
                                    <div className="col-auto datepicker-selection__day">
                                        {getLocaleDay(selectedDateString) !== 'Invalid Date' ? (
                                            getLocaleDay(selectedDateString)
                                        ) : "-"}
                                    </div>
                                    <div className="col">
                                        <span className="datepicker-selection__top">
                                             {getLocaleMonthName(selectedDateString) !== 'Invalid Date' ? (
                                                 getLocaleMonthName(selectedDateString)
                                             ) : "-"}
                                            <span className="ms-1">
                                                 {getLocaleYear(selectedDateString) !== 'Invalid Date' ? (
                                                     getLocaleYear(selectedDateString)
                                                 ) : ""}
                                            </span>
                                        </span>
                                            <span className="datepicker-selection__bottom">
                                                {getLocaleWeekday(selectedDateString) !== 'Invalid Date' ? (
                                                    getLocaleWeekday(selectedDateString)
                                                ) : "-"}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {maxSelectableDays && maxSelectableDays > 1 ? (
                                <div className="col-xl-6 col-md-12 col-6" key={selectedToDateString && getLocaleDay(selectedToDateString) !== 'Invalid Date' && getLocaleDay(selectedToDateString)}>
                                    {translate('ski-ticket.To')}
                                    <div className="datepicker-selection row gx-gutter--10">
                                        <div className="col-auto datepicker-selection__day">
                                            {selectedToDateString && getLocaleDay(selectedToDateString) !== 'Invalid Date' ? (
                                                getLocaleDay(selectedToDateString)
                                            ) : "-"}
                                        </div>
                                        <div className="col">
                                        <span className="datepicker-selection__top">
                                             {selectedToDateString && getLocaleMonthName(selectedToDateString) !== 'Invalid Date' ? (
                                                 getLocaleMonthName(selectedToDateString)
                                             ) : "-"}
                                            <span className="ms-1">
                                                 { selectedToDateString && getLocaleYear(selectedToDateString) !== 'Invalid Date' ? (
                                                     getLocaleYear(selectedToDateString)
                                                 ) : ""}
                                            </span>
                                        </span>
                                            <span className="datepicker-selection__bottom">
                                                {selectedToDateString && getLocaleWeekday(selectedToDateString) !== 'Invalid Date' ? (
                                                    getLocaleWeekday(selectedToDateString)
                                                ) : "-"}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            ) : ""}
                        </div>

                        {maxSelectableDays && maxSelectableDays > 1 ? (
                            <div className="mt-md-5 microanimations--change-blur" key={selectedDateString && getLocaleDay(selectedDateString) && selectedToDateString && getLocaleDay(selectedToDateString)}>
                                {translate('ski-ticket.Days')}
                                <div>
                                    <span className="datepicker-selection__day">
                                          {getRangeDays(selectedDateString, selectedToDateString) > 0 && !isNaN(getRangeDays(selectedDateString, selectedToDateString)) ? (
                                              getRangeDays(selectedDateString, selectedToDateString)
                                          ) : "-"}
                                    </span>

                                    <span className="datepicker-selection__top d-inline-block ms-2">
                                          { getRangeDays(selectedDateString, selectedToDateString) > 1 ? translate('ski-ticket.Days-selected') : translate('ski-ticket.Day-selected')}
                                    </span>
                                </div>
                            </div>
                        ) : "" }

                        {dateConfigurations?.fromPrice ? (
                            <div className="mt-md-5 mt-3">
                                {translate('ski-ticket.Adult price')}
                                <div>
                                    {translate('ski-ticket.from')}
                                    <span className="datepicker-selection__price px-2"
                                          dangerouslySetInnerHTML={{__html:formatPrice(dateConfigurations?.fromPrice, _config.lang, 'currency', 'CHF', 'code', "span class='fz-16'")}}>
                                    </span>

                                    {translate('ski-ticket.per ticket')}
                                </div>
                            </div>
                        ) : ""}

                    </div>
                </div>
            ) : "" }
        </Fragment>
    )
}


