"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAll, find, removeClass, addClass} from "@elements/dom-utils";
import asyncAppend from "@elements/async-append";
import {forceRecalculation} from "@elements/affix";

const defaultOptions = {};

const defaultSelectors = {
    base: '.js-load-cart',
    target: '.js-load-cart__target',
    loading: '.js-load-cart__loading',
    notifications: '.js-load-cart__notifications'
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createCart(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    },document);
}

export function createCart(base, options, selectors) {
    let url = _config.loadCart;
    
    on('click', function(){
        asyncAppend({
            target: findAll(selectors.target),
            loading: findAll(selectors.loading),
            notifications: findAll(selectors.notifications)
        }, fetch(url));
    }, base);
}

export function updateCart(selectors = defaultSelectors) {
    let url = _config.loadCart;

    asyncAppend({
        target: findAll(selectors.target),
        loading: findAll(selectors.loading),
        notifications: findAll(selectors.notifications)
    }, fetch(url));

    forceRecalculation();
    
    console.log("force"); 
}

export function setCartCount(count) {
    let cartCount = find('.js-load-cart__count');
    if(cartCount) {
        (count === 0) ? addClass('d-none', cartCount) : removeClass('d-none', cartCount);
        cartCount.innerHTML = count;
    } else {
        console.warn("ERROR: .js-load-cart__count not found!");
    }
}