/** @jsx h */

import {h, Fragment} from "preact";
import {formatPrice, getLocaleDay, getLocaleMonthName, getLocaleWeekday, noop, throwAlertMessage} from "../utils/utils";
import {assert} from 'superstruct';
import {translate} from '@elements/translations';
import {closest,findIn, findAll, findAllIn, setAttribute, removeAttribute} from "@elements/dom-utils";
import {useEffect} from 'preact/hooks';
import {dateToISOString, localDateToUTCDate, UTCDateToLocalDate} from "@elements/date-utils";

export default function PricingSlider({
   selectedDate = "",
   selectedToDate = "",
   PricingSliderDateType = {},
   pricingSliderConfigurations = {},
   onSelectDate = noop(),
   selectedRangeInDays = 0,
   ticketCounter = {}
}) {

    const isMobile = matchMedia('(max-width: 767px)').matches;

    useEffect(function() {
        if(selectedDate) {
            centerActiveItem();
        }
    }, [selectedDate])

    function validateItem(item) {
        if (item) {
            try {
                //validating data
                assert(item, PricingSliderDateType);
                return true;
            } catch (error) {
                console.log(error);
                for (const failure of error.failures()) {
                    let errorText = `<h4>error key: ${failure.key}</h4><p><strong>pricing-slider - validateItem()</strong>:</p><p>${failure.message}</p>`;
                    throwAlertMessage('danger', 'TYPE ERROR', errorText, true);
                }
            }
        }
    }


    function centerActiveItem() {
        let targets = findAll('.pricing-slider-item--active');
        if(targets.length > 0) {
            let target = targets[0];
            let wrapper = closest('.pricing-slider-wrapper', target);
            let slider = findIn('.pricing-slider', wrapper);
            let scrollValue;

            scrollValue = slider.scrollLeft + target.getBoundingClientRect().left - target.getBoundingClientRect().width - window.outerWidth/2 + wrapper.getBoundingClientRect().width/2;

            try {
                slider.scroll({
                    left: scrollValue,
                    behavior: 'smooth'
                });
                updateArrows();
            } catch (e) {
                slider.scrollLeft = scrollValue;
            }

            updateArrows(slider);
        }
    }

    function scrollTo(evt, isPrev) {
        let target = evt.target;
        let wrapper = closest('.pricing-slider-wrapper', target);
        let slider = findIn('.pricing-slider', wrapper);
        let sliderItem = findIn('.pricing-slider-item', slider);
        let scrollValue;

        if(isPrev) {
            scrollValue = slider.scrollLeft - sliderItem.getBoundingClientRect().width;
        } else {
            scrollValue = slider.scrollLeft +  sliderItem.getBoundingClientRect().width
        }

        try {
            slider.scroll({
                left: scrollValue,
                behavior: 'smooth'
            });
        } catch (e) {
            slider.scrollLeft = scrollValue;
        }

        updateArrows(slider);
    }

    function updateArrows(slider) {
        let sliderItem = findIn('.pricing-slider-item', slider);
        let wrapper = closest('.pricing-slider-wrapper', slider);
        let prevButton = findIn('.pricing-slider__button--prev', wrapper);
        let nextButton = findIn('.pricing-slider__button--next', wrapper);

        if(slider.scrollLeft < sliderItem.getBoundingClientRect().width) {
            setAttribute('disabled', true, prevButton);
        } else {
            removeAttribute('disabled', prevButton);
        }

        if(slider.scrollWidth - slider.scrollLeft <= slider.clientWidth) {
            setAttribute('disabled', true, nextButton);
        } else {
            removeAttribute('disabled', nextButton);
        }
    }

    function getPrice(item) {
        let total = 0;
        if(Object.keys(ticketCounter).length > 0) {
            try {
                Object.keys(ticketCounter).map(function(priceGroup,key) {
                    [...Array(ticketCounter[priceGroup])].map(function(x, i) {
                        total += +pricingSliderConfigurations[item]['priceByPriceGroups'][priceGroup].price;
                    });
                });
            } catch(e) {
                throwAlertMessage('warning', 'getPrice for pricing slider failed', e, true);
            }
        } else {
            total = pricingSliderConfigurations[item].defaultPrice;
        }

        return total;
    }


    //render
    return (
        <Fragment>
            <div className="mb-2">
                    <span className="pricing-slider__title me-2">
                         {translate('ski-ticket.Product choice for')} {selectedRangeInDays} {selectedRangeInDays > 1 ? translate('ticket.Days') : translate('ticket.Day')}
                    </span>
                {Object.keys(ticketCounter).length > 0 ? translate('ski-ticket.total-price-without-services'): translate('ski-ticket.anticipated-price-adult-ticket')}
            </div>
            <div className="pricing-slider-wrapper">
                <button className="pricing-slider__button pricing-slider__button--prev"
                        onClick={(evt) => {
                            scrollTo(evt, true)
                        }}>
                    <span className="icon icon-arrow-dropdown icon-rotate-90" aria-label="previous" title="previous"/>
                </button>
                <button className="pricing-slider__button pricing-slider__button--next"
                        onClick={(evt) => {
                            scrollTo(evt, false)
                        }}>
                    <span className="icon icon-arrow-dropdown icon-rotate-270" aria-label="next" title="next"/>

                </button>
                <ul className="pricing-slider list-unstyled">
                    {Object.keys(pricingSliderConfigurations).map((item, key) => (
                        <li key={item} className={pricingSliderConfigurations[item].date === selectedDate ? 'pricing-slider-item pricing-slider-item--active' : 'pricing-slider-item'}
                            onClick={(evt) => {
                                onSelectDate(pricingSliderConfigurations[item].date);
                            }}>
                            {validateItem(pricingSliderConfigurations[item]) ? (
                                <div>
                                    {pricingSliderConfigurations[item].isPriceOfTheWeek ? (
                                        <span className="pricing-slider-item__badge">
                                              {translate('ski-ticket.Price of the week!')}
                                        </span>
                                    ) : ""}

                                    <div>
                                        <span className="fz-9">
                                             {translate('ski-ticket.from')}
                                        </span>
                                        <span className="pricing-slider-item__date">
                                             {getLocaleDay(pricingSliderConfigurations[item].date) !== 'Invalid Date' ? (
                                                 getLocaleDay(UTCDateToLocalDate(new Date(pricingSliderConfigurations[item].date))) + ". "
                                             ) : ""}

                                            {getLocaleMonthName(pricingSliderConfigurations[item].date) !== 'Invalid Date' ? (
                                                getLocaleMonthName(UTCDateToLocalDate(new Date(pricingSliderConfigurations[item].date)))
                                            ) : ""}
                                        </span>
                                    </div>

                                    { !isMobile ? (
                                        <div className="pricing-slider-item__day-name">
                                            {getLocaleWeekday(pricingSliderConfigurations[item].date) !== 'Invalid Date' ? (
                                                getLocaleWeekday(UTCDateToLocalDate(new Date(pricingSliderConfigurations[item].date)))
                                            ) : ""}
                                        </div>
                                    ) : ""}


                                    <div className="pricing-slider-item__price">
                                        <span className={pricingSliderConfigurations[item].isPriceOfTheWeek ? 'pricing-slider-item__price-big me-1' : 'me-1'}>
                                            {formatPrice(getPrice(item), _config.lang, 'currency', 'CHF', 'code')}
                                        </span>
                                    </div>
                                </div>
                            ) : ""}
                        </li>
                    ))}
                </ul>
            </div>
        </Fragment>
    );
}