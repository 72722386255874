/** @jsx h */

import {h, Fragment} from "preact";
import {
    formatPrice,
    getLocaleDateStringShort,
    getSumOfObjectKeys,
    throwAlertMessage
} from "../../utils/utils";
import {translate} from '@elements/translations';
import {trackEvent} from '@elements/tracking/src/gtm';
import {usePricingConfigurations} from "../../utils/ski-locker-configuration-hooks";
import LoadingSpinner from "../loading-spinner";

export default function SkiLockerSummary({
     id = "",
     isInEdit = false,
     title = "",
     selectedDate = "",
     selectedToDate = "",
     selectedSeasonString = "",
     selectedLocationString = "",
     ticketCounter = {},
     configurationSuccess = false,
     selectedLocation={selectedLocation},
     selectedSeason={selectedSeason},
     selectedProductChoice = "",
 }) {


    let params = {
        locker_id: id,
        location: selectedLocation,
        ...(selectedDate && {startDate: selectedDate}),
        ...(selectedToDate && {endDate: selectedToDate}),
        ...(selectedSeason && { season: selectedSeason })
    }

    let {pricingConfigurations, isLoading, error} = usePricingConfigurations(params);
    function getPriceOfItem(priceGroupId) {
        let price = 0;

        try {
            pricingConfigurations.priceGroups.map(function(priceGroup) {
                if(priceGroup.id === priceGroupId) {
                    price += priceGroup.price;
                }
            });
        } catch (e) {
            throwAlertMessage('warning', 'PRICE Calculation failed - getPriceOfItem() pricing summary', e, true);
        }

        return +price.toFixed(2);
    }

    function getBasePriceOfItem(priceGroupId) {
        let price = 0;

        try {
            pricingConfigurations.priceGroups.map(function (priceGroup) {
                if (priceGroup.id === priceGroupId) {
                    price += priceGroup.basePrice;
                }
            });

        }catch (e) {
            throwAlertMessage('warning', 'PRICE Calculation failed - getPriceOfItem() pricing summary', e, true);
        }
        return isNaN(+price.toFixed(2)) ? "" : +price.toFixed(2);
    }

    function getTitleOfItem(priceGroupId) {
        let title = "";

        try {
            pricingConfigurations.priceGroups.map(function(priceGroup) {
                if(priceGroup.id === priceGroupId) {
                    title += priceGroup.title;
                }
            });
        } catch (e) {
            throwAlertMessage('warning', 'PRICE Calculation failed - getPriceOfItem() pricing summary', e, true);
        }
        return title;
    }




    return (

        <Fragment>
            {configurationSuccess ? (
                <Fragment>
                    <div className="row mb-md-5 mb-3">
                        <div className="col-auto">
                            <span className="icon icon-open ticket-configuration__check" />
                        </div>
                        <div className="col">
                            <div>
                                {isInEdit ? translate('ski-locker.product-successfully-updated') : translate('ski-locker.product-successfully-added-to-cart') }
                            </div>
                            <div className="h2 mt-2">
                                {translate('ski-locker.thank-you')}
                            </div>
                        </div>
                    </div>

                    {title ? (
                        <div className="ticket-configuration__summary-title">
                            {title}
                        </div>
                    ) : ""}

                    {selectedLocationString ? (
                        <div className="ticket-configuration__summary-title">
                            <span className="icon icon-map ticket-configuration__summary-icon" aria-hidden="true"></span>
                            {selectedLocationString.title} {selectedLocationString.subtitle}
                        </div>
                    ) : ""}

                    {selectedDate ? (
                        <div className="ticket-configuration__summary-title">
                            <span className="icon icon-events ticket-configuration__summary-icon" aria-hidden="true"></span>
                            {getLocaleDateStringShort(selectedDate)}
                            {selectedToDate ? ' - ' + getLocaleDateStringShort(selectedToDate) : ""}
                        </div>
                    ) : ""}

                    {selectedSeasonString ? (
                        <div className="ticket-configuration__summary-title">
                            <span className="icon icon-tickets-outline ticket-configuration__summary-icon" aria-hidden="true"></span>
                            {selectedSeasonString}
                        </div>
                    ) : ""}

                    <div className="ticket-configuration__summary-title">
                        <span className="icon icon-ski ticket-configuration__summary-icon" aria-hidden="true"></span>
                        {getSumOfObjectKeys(ticketCounter)} {translate('ski-locker.ski-locker')}
                    </div>

                    {isLoading ? (
                        <LoadingSpinner/>
                    ) : ""}
                    { error ? (
                        <div className="alert alert-danger">
                            {translate('ski-locker.Loading-Error')}
                        </div>
                    ) : ""}
                    {pricingConfigurations ? (
                    <div className="mt-4 col-md-6">
                        {Object.keys(ticketCounter).map((priceGroup, key) => (
                            <Fragment>
                                {[...Array(ticketCounter[priceGroup])].map((x, i) =>
                                    <div className="cart-summary__item" key={priceGroup + '-' + i}>
                                        <div className="row">
                                            <div className="col-md-7 gy-gutter--10">
                                                <div className="strong gy-gutter__item mb-2 mb-md-0">
                                                    <span className="" aria-hidden={true}/>
                                                    {getTitleOfItem(priceGroup)}
                                                </div>
                                            </div>
                                            <div className="col-md-5 text-end">
                                                 <strong className="me-2">

                                                {priceGroup ? (
                                                    <Fragment>
                                                        {formatPrice(getPriceOfItem(priceGroup), _config.lang, 'currency', 'CHF', 'code')}
                                                    </Fragment>
                                                ) : (""
                                                )}
                                                <span className="ms-2 ticket-configuration__red-base-price">
                                                {priceGroup ? (
                                                    <Fragment>
                                                        {formatPrice(getBasePriceOfItem(priceGroup), _config.lang, 'currency', 'CHF', 'code')}
                                                    </Fragment>
                                                ): ""}
                                               </span>
                                            </strong>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </div>
                    ) : ""}
                </Fragment>
            ) : (
                <div className="row mb-md-5 mb-3">
                    <div className="col-auto">
                        <span className="icon icon-close ticket-configuration__check text-primary" />
                    </div>
                    <div className="col">
                        <div className="h2 mt-2">
                            {translate('ski-locker.Add-to-cart-failed')}
                        </div>
                    </div>
                </div>
            )}


            <div className="row mt-md-5 mt-4 gy-gutter--10">
                <div className="col-md-3">
                    <a href={_config.continueShoppingLink} className="w-100 btn btn-outline-dark"
                       onClick={(evt) => {
                        trackEvent(
                            {
                                category:"Online Shop",
                                action:"Add-to-Cart Übersicht",
                                label: translate('ski-locker.continue-shopping')
                            }, {debug: true})
                    }}>
                        {translate('ski-locker.continue-shopping')}
                    </a>
                </div>
                <div className="col-md-3">
                    <a href={_config.cartLink} className="w-100 btn btn-outline-primary"
                       onClick={(evt) => {
                        trackEvent(
                            {
                                category:"Online Shop",
                                action:"Add-to-Cart Übersicht",
                                label: translate('ski-locker.view-cart')
                            }, {debug: true})
                         }}>
                        {translate('ski-locker.view-cart')}
                    </a>
                </div>
                <div className="col-md-3">
                    <a href={_config.checkoutLink} className="w-100 btn btn-primary"
                       onClick={(evt) => {
                           trackEvent(
                               {
                                   category:"Online Shop",
                                   action:"Add-to-Cart Übersicht",
                                   label: translate('ski-locker.go-to-checkout')
                               }, {debug: true})
                       }}>
                        {translate('ski-locker.go-to-checkout')}
                    </a>
                </div>
            </div>
        </Fragment>
    )
}