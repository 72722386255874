"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn,removeClass,on} from '@elements/dom-utils';


const defaultSelectors = {
    base: '.js-cookieinformation-placeholder'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        window.addEventListener('CookieInformationConsentGiven', function(event) {
            if(baseElement){
                if(!CookieInformation.getConsentGivenFor(baseElement.getAttribute('data-category'))){
                    baseElement.style.display = 'block';
                }else{
                    baseElement.style.display = 'none';
                }
            }
        }, false);
    },document);
}

