/** @jsx h */

import {h, render, Fragment} from "preact";
import {assert, object, number, string, array, optional, enums, func, boolean, defaulted} from 'superstruct';
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on} from '@elements/dom-utils';
import {getPrefixedDataSet} from '@elements/data-set-utils';
import SkiLockerModal from "./components/ski-locker-config/ski-locker-modal";
import {throwAlertMessage} from "./utils/utils";
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import {getSkiLockerBaseConfig} from "./utils/config-helper";

const selectors = {
    base: '.js-ski-locker-config',
    open: '.js-ski-locker-config__open',
    overlay: '.js-ski-locker-config__overlay'
};

export function init() {
    console.log("SKI LOCKER CONFIG");

    /*
        these options will be read out of data-attribute
        firstView: location-list datepicker or pricing-list
    */

    const LockerConfig = object({
        isLoading: boolean(),
        isInEdit: optional(boolean),
        id: string(),
        editId: optional(string()),
        isModalOpen: boolean(),
        title: string(),
        onModalHide: func(),
        firstView: enums(['location-list','datepicker','pricing-list']),
        locationDefault: optional(string()),
        currentStepDefault: optional(number())
    })

    const EditTicketConfig = object({
        isLoading: boolean(),
        isInEdit: optional(boolean()),
        id: string(),
        editId: optional(string()),
        isModalOpen: boolean(),
        title: string(),
        onModalHide: func(),
        firstView: enums(['location-list','datepicker','pricing-list']),
        locationDefault: optional(string()),
        currentStepDefault: optional(number()),
        selectedSeasonDefault: optional(string()),
        dateDefault: optional(string()),
        dateStringDefault: optional(string()),
        dateToDefault: optional(string()),
        dateToStringDefault: optional(string()),
        ticketCounterDefault: optional(object())
    })

    onFind(selectors.base, function (Configurator) {
        let ConfiguratorOpen = findIn(selectors.open, Configurator);
        let ConfiguratorOverlay = findIn(selectors.overlay, Configurator);

        const renderWithData = data => render(<SkiLockerModal {...data}/>, ConfiguratorOverlay);

        let data = {
            isModalOpen: true,
            onModalHide: (function () {
                data.isModalOpen = false;
                renderWithData(data);
            }),
            isLoading: true
        };

        on('click', function() {
            let dataset = getPrefixedDataSet('ski-locker-config', Configurator);
            dataset = transformDataset(dataset);
            let editData;

            if(dataset.isInEdit) {
                let params = {
                    locker_id: dataset.id
                };

                let url = getSkiLockerBaseConfig().edit;
                let request = fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                request.then(result => result.json()).then(result => {
                    editData = result;
                    data = {
                        ...data,
                        ...dataset,
                        ...editData,
                        isLoading: false,
                        isModalOpen:true
                    };

                    try {
                        //validating data
                        assert(data, EditTicketConfig);
                        renderWithData(data);
                    } catch(error) {
                        console.error(error);
                        for (const failure of error.failures()) {
                            let errorText = `<p><strong>ski-locker-config</strong>:edit</p><p>${failure.message}</p>`;
                            throwAlertMessage('danger', 'TYPE ERROR - ' + failure.key, errorText, true);
                        }
                    }
                }).catch(e => {
                    let errorText = `<p><strong>ski-locker-config</strong>:edit failed!</p><p>${dataset.id}</p>`;
                    throwAlertMessage('danger', 'edit', errorText, true);
                });

            } else {
                data = {
                    ...data,
                    ...dataset,
                    isLoading: false,
                    isModalOpen:true
                };

                try {
                    //validating data
                    assert(data, LockerConfig);
                    renderWithData(data);
                } catch(error) {
                    console.error(error);
                    for (const failure of error.failures()) {
                        let errorText = `<p><strong>ski-locker-config</strong>:Typo might be in dataset-values of div with class <strong>js-ski-locker-config</strong></p><p>${failure.message}</p>`;
                        throwAlertMessage('danger', 'TYPE ERROR - ' + failure.key, errorText, true);

                    }
                }
            }

        }, ConfiguratorOpen);
    });

}

//transform dataset 'cause every data-set-property will be read out as a string
export function transformDataset(dataset) {

    let transformedDataset = {...dataset};

    if(dataset.isInEdit) {
        transformedDataset.isInEdit = (transformedDataset.isInEdit === "true");
    }

    if(dataset.currentStepDefault) {
        transformedDataset.currentStepDefault = +transformedDataset.currentStepDefault;
    }

    return transformedDataset;
}