"use strict";

import {find, on} from '@elements/dom-utils';
import Modal from 'bootstrap/js/dist/modal';

export function init () {
    let darkpageModal = find('.js-darkpage-modal');

    if (darkpageModal){
        let modalInit = new Modal(darkpageModal);

        try{
            if(!window.sessionStorage.getItem('darkpage')) {
                modalInit.show();
            }

            on('hidden.bs.modal', function () {
                window.sessionStorage.setItem('darkpage', true);
            }, darkpageModal);
        }catch(e){
            console.log(e);
        }
    }

}
